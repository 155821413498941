import React, { useEffect } from "react";
import { Button, Grid, Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import LoanNameDetails from "./LoanNameDetails";
import { useForm } from "../Sharedfeatures/useForm";
import LoanCurrencyDetails from "./LoanCurrencyDetails";
import LoanTerms from "./LoanTerms.jsx";
import LoanSettings from "./LoanSettings.jsx";
import Preview from "./Preview.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { getFloatingInterestRate } from "../../slice/loan";

const partner_id = process.env.REACT_APP_PARTNER_ID

const initialValues = {
  partnerId: partner_id,
  name: "",
  shortName: "",
  // startDate: "",
  currencyCode: "INR",
  minPrincipal: "",
  maxPrincipal: "",
  locale: "en_GB",
  principal: "",
  charges: [{"id": 0}],
  // interestRateDifferential:"",
  isEqualAmortization:"false",              // floatingRatesId:"1",
  isLinkedToFloatingInterestRates: "false",                // defaultDifferentialLendingRate: "",
  // minDifferentialLendingRate: "",
  canDefineInstallmentAmount:"false",
  // maxDifferentialLendingRate: "",
  rescheduleStrategyMethod:"3",
  // isFloatingInterestRateCalculationAllowed: "false",
  // preClosureInterestCalculationStrategy:"1",
  // interestRecalculationCompoundingMethod: "0",
  // recalculationCompoundingFrequencyNthDayType:"",
  // recalculationCompoundingFrequencyDayOfWeekType:"",
  // recalculationRestFrequencyType:"",
  // recalculationRestFrequencyInterval:"",
  // recalculationCompoundingFrequencyType:"",
  // recalculationCompoundingFrequencyInterval:"",
  repaymentEvery: "",
  // graceOnInterestCharged:"",
  repaymentFrequencyType: "2",
  interestRateFrequencyType: "2",
  interestRatePerPeriod: "",
  // accountMovesOutOfNPAOnlyOnArrearsCompletion: "false",
  // isArrearsBasedOnOriginalSchedule: "true",
  interestType: "1",
  // overdueDaysForNPA:"",
  amortizationType: "1",
  interestCalculationPeriodType: "1",
  // allowPartialPeriodInterestCalcualtion:"false",
  numberOfRepayments: "1",
  digitsAfterDecimal: "2",
  inMultiplesOf: "0",
  installmentAmountInMultiplesOf: "",
  transactionProcessingStrategyId: "1",
  daysInMonthType: "1",
  daysInYearType: "1",
  isInterestRecalculationEnabled: "false",
  accountingRule: "1",
  multiDisburseLoan: "false",
  writeOffAccountId: "42",
  transfersInSuspenseAccountId: "1",
  // incomeFromRecoveryAccountId: "1",
  // fundSourceAccountId: 5,
  //         loanPortfolioAccountId: 10,
  //           receivableInterestAccountId : 10,
  //           receivableFeeAccountId: 12,
  //           receivablePenaltyAccountId: 14,
  //           interestOnLoanAccountId: 35,
  //           incomeFromFeeAccountId: 38,
  //           incomeFromPenaltyAccountId: 36,
  //           overpaymentLiabilityAccountId: 3,
  // maxTrancheCount: "",
  // outstandingLoanBalance: "",
  // inArrearsTolerance:""
};
const NewLoanStepper = () => {
  const dispatch = useDispatch();
  const { loanDetails, success } = useSelector((state) => state.loan);
  
  const { id } = useParams();
  function getSteps() {
    return ["Details", "Currency", "Terms", "Settings", "Preview"];
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("shortName" in fieldValues)
      temp.shortName =
        fieldValues.shortName.length === 0 ? "" : "This field is required";
    if ("shortName" in fieldValues)
      temp.shortName =
        fieldValues.shortName.length >= 4 ? "Cannot exceed 3 letters" : "";
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <LoanNameDetails
            value={values}
            handleNext={() => handleNext()}
            handleInputChange={handleInputChange}
          />
        );
      case 1:
        return (
          <LoanCurrencyDetails
            value={values}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            handleInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <LoanTerms
            value={values}
            setValues={setValues}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            handleInputChange={handleInputChange}
          />
        );
      case 3:
        return (
          <LoanSettings
            value={values}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            handleInputChange={handleInputChange}
            // getFloatingInterestRate={getFloatingInterestRate}
          />
        );
      case 4:
        return <Preview value={values} handleBack={() => handleBack()} />;
      default:
        return "unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (id) {
      setValues({
        ...values,
        id: id,
        locale: "en_GB",
        partnerId: partner_id,
        name: loanDetails && loanDetails.name,
        shortName: loanDetails && loanDetails.shortName,
        maxPrincipal: loanDetails && loanDetails.maxPrincipal,
        minPrincipal: loanDetails && loanDetails.minPrincipal,
        principal: loanDetails && loanDetails.principal,
        numberOfRepayments: loanDetails && loanDetails.numberOfRepayments,
        interestRatePerPeriod: loanDetails && loanDetails.interestRatePerPeriod,
        repaymentEvery: loanDetails && loanDetails.repaymentEvery,
        installmentAmountInMultiplesOf: loanDetails && loanDetails.installmentAmountInMultiplesOf,
        isLinkedToFloatingInterestRates: loanDetails && loanDetails.isLinkedToFloatingInterestRates && loanDetails.isLinkedToFloatingInterestRates.toString(),
        interestRateDifferential: loanDetails && loanDetails.interestRateDifferential,
        defaultDifferentialLendingRate:loanDetails && loanDetails.defaultDifferentialLendingRate,
        minDifferentialLendingRate: loanDetails && loanDetails.minDifferentialLendingRate,
        maxDifferentialLendingRate: loanDetails && loanDetails.maxDifferentialLendingRate,
        multiDisburseLoan: loanDetails && loanDetails.multiDisburseLoan && loanDetails.multiDisburseLoan.toString(),
        isEqualAmortization: loanDetails && loanDetails.isEqualAmortization && loanDetails.isEqualAmortization.toString(),
        canDefineInstallmentAmount: loanDetails && loanDetails.canDefineInstallmentAmount && loanDetails.canDefineInstallmentAmount.toString(),
        accountMovesOutOfNPAOnlyOnArrearsCompletion: loanDetails && loanDetails.accountMovesOutOfNPAOnlyOnArrearsCompletion && loanDetails.accountMovesOutOfNPAOnlyOnArrearsCompletion.toString(),
        isArrearsBasedOnOriginalSchedule: loanDetails && loanDetails.isArrearsBasedOnOriginalSchedule && loanDetails.isArrearsBasedOnOriginalSchedule.toString(),
        inArrearsTolerance: loanDetails && loanDetails.inArrearsTolerance,
        graceOnInterestCharged: loanDetails && loanDetails.graceOnInterestCharged,
        repaymentFrequencyType:
          loanDetails &&
          loanDetails.repaymentFrequencyType &&
          loanDetails.repaymentFrequencyType.id,
        interestRateFrequencyType:
          loanDetails &&
          loanDetails.interestRateFrequencyType &&
          loanDetails.interestRateFrequencyType.id,
        interestType:
          loanDetails &&
          loanDetails.interestType &&
          loanDetails.interestType.id,
        amortizationType:
          loanDetails &&
          loanDetails.amortizationType &&
          loanDetails.amortizationType.id,
        interestCalculationPeriodType:
          loanDetails &&
          loanDetails.interestCalculationPeriodType &&
          loanDetails.interestCalculationPeriodType.id,
        numberOfRepayments: loanDetails && loanDetails.numberOfRepayments,
        maxTrancheCount: loanDetails && loanDetails.maxTrancheCount,
        outstandingLoanBalance: loanDetails && loanDetails.outstandingLoanBalance
      });
    }
  }, [loanDetails]);
  // useEffect(() => {
  //   dispatch(getFloatingInterestRate())
  // }, [])

  return (
    <Box sx={{ width: "100%", paddingTop: "20px" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* get components */}
      <>
        <form onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={5.5} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              {getStepContent(activeStep)}
            </Grid>
          </Grid>
        </form>
      </>
    </Box>
  );
};

export default NewLoanStepper;
