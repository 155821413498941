const currency = [
  { id: 0, name: "INR" },
];
const amortization = [
    { id: 0, name: "Equal Principal Payments" },
    { id: 1, name: "Equal Installments" },
  ];
  const interestType = [
    { id: 0, name: "Declining Balance" },
    { id: 1, name: "Flat" },
  ];
  const calculationPeriodType = [
    { id: 0, name: "Daily" },
    { id: 1, name: "Same As Repayment Period" },
  ];
  const group = [
    { id: 1, name: "true" },
    { id: 2, name: "false" },
  ];
  const repaymentStrategy = [
    { id: 1, name: "Penalties,Fees,Interest,Principal Order" },
    { id: 4, name: "Overdue/Due Fee/Int Principal" },
    { id: 7, name: "Early Repayment Strategy" },
    { id: 2, name: "Havens Family Unique" },
  ];
  const preClosureInterestCalculationStrategyOptions = [
    { id: 1, name: "Calculate till pre closure date" },
    { id: 2, name: "Calculate till rest frequency date" },
  ];
  const interestRecalculationCompoundingTypeOptions = [
    { id: 0, name: "None" },
    { id: 1, name: "Interest" },
    { id: 2, name: "Fee" },
    { id: 3, name: "Fee and Interest" },
  ];
  const rescheduleStrategyMethodOptions = [
    { id: 1, name: "Reschedule next repayments" },
    { id: 2, name: "Reduce number of installments" },
    { id: 3, name: "Reduce EMI amount" },
  ];
  const interestRecalculationFrequencyTypeOptions = [
    { id: 1, name: "Same as repayment period" },
    { id: 2, name: "Daily" },
    { id: 3, name: "Weekly" },
    { id: 4, name: "Monthly" },
  ];
  const recalculationCompoundingFrequencyDayOfWeekTypeOptions = [
    { id: 1, name: "MONDAY" },
    { id: 2, name: "TUESDAY" },
    { id: 3, name: "WEDNESDAY" },
    { id: 4, name: "THURSDAY" },
    { id: 5, name: "FRIDAY" },
    { id: 6, name: "SATURDAY" },
    { id: 7, name: "SUNDAY" },
  ];
  const daysInYear = [
    { id: 1, name: "Actual" },
    { id: 360, name: "360 Days" },
    { id: 364, name: "364 Days" },
    { id: 365, name: "365 Days" },
  ];
  const daysInMonth = [
    { id: 1, name: "Actual" },
    { id: 30, name: "30 Days" },
  ];

  const LoanSettingsOptions = {
    amortization,
    interestType,
    calculationPeriodType,
    group,
    repaymentStrategy,
    preClosureInterestCalculationStrategyOptions,
    interestRecalculationCompoundingTypeOptions,
    rescheduleStrategyMethodOptions,
    recalculationCompoundingFrequencyDayOfWeekTypeOptions,
    interestRecalculationFrequencyTypeOptions,
    daysInYear,
    daysInMonth,
    currency,
  }

  export default LoanSettingsOptions;