import React, { useState } from 'react'
import { Alert } from '@mui/material'
import useStyles from './PasswordStyleSheet'
import { useForm } from '../Sharedfeatures/useForm'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../slice/forgotpassword';
import TextBox from '../controls/TextBox';
import Button from '../controls/Button'
import { logout } from '../../slice/auth'

const initialValues = {
    password: '',
    confirm_password: ''
}

const ResetPassword = () => {

    const styles = useStyles()
    const dispatch = useDispatch()
    const [alert, setAlert] = useState(false)
    const { message, success } = useSelector((store) => store.forgotPassword)
    const { id, token } = useParams()

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("password" in fieldValues)
            temp.password = fieldValues.password ? "" : "This field is required";
        if ("confirm_password" in fieldValues)
            temp.confirm_password = fieldValues.confirm_password ? "" : "This field is required";

        setErrors({
            ...temp,
        });

        if (fieldValues == values)
            return Object.values(temp).every((x) => x == "");
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const params_data = {
            id,
            token,
            ...values
        }
        if (validate())
            dispatch(resetPassword(params_data))
                .then((res) => {
                    setAlert(true)
                    dispatch(logout())
                })
                .catch((err) => {

                })
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialValues, true, validate);

    return (
        <section className={styles.parentContainer}>
            {
                alert ? (<div className={styles.overlayContainer}>
                    <h3>{message}</h3>
                    <br />
                    <Link to='/auth/login '>Go back to Login Page</Link>
                </div>) : (
                    <div className={styles.passwordContainer}>
                        <div className={styles.innerPasswordContainer}>
                            <div className={styles.headerParent}>
                                <h2 className={styles.header}>Reset Password</h2>
                            </div>

                            <div className={styles.formContainer}>
                                {/* {
                                    alert && (
                                        <Alert severity="info" sx={{ mt: 1 }}>
                                            {message}
                                        </Alert>
                                    )
                                } */}
                                <form onSubmit={handleSubmit}>
                                    <TextBox
                                        name="password"
                                        type="password"
                                        label="New Password"
                                        onChange={handleInputChange}
                                        className={styles.textField}
                                        value={values.password}
                                        error={errors.password}
                                    />
                                    <TextBox
                                        name="confirm_password"
                                        type="password"
                                        label="Confirm New Password"
                                        onChange={handleInputChange}
                                        className={styles.textField}
                                        value={values.confirm_password}
                                        error={errors.confirm_password}
                                    />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        className={styles.submitButton}
                                        type="submit"
                                        text="Reset Password"
                                    />
                                </form>

                            </div>



                        </div>
                    </div>
                )
            }

        </section>
    )
}

export default ResetPassword