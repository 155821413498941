import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getInterestChart = createAsyncThunk(
    'getInterestChart',
    async (data) => {
        const url = `/dashboard/lastoneyear`;
        const res = await DataService.post(url, data);
        return res.data
    }
)

export const getLoanSanctionedData = createAsyncThunk(
    'getLoanSanctionedData',
    async (year) => {
        const url = `/dashboard/loan-sanctioned-monthwise?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getDisburseChart = createAsyncThunk(
    'getDisburseChart',
    async (year) => {
        const url = `/dashboard/loan-disburesed-monthwise?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getLoanDefaultedData = createAsyncThunk(
    'getLoanDefaultedData',
    async (year) => {
        const url = `/dashboard/total-loans-defaulted?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)
export const getPreApprovedData = createAsyncThunk(
    'getPreApprovedData',
    async (year) => {
        const url = `/dashboard/loanoffer-generated-monthwise?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getRepaymentsData = createAsyncThunk(
    'getRepaymentsData',
    async (year) => {
        const url = `/dashboard/repayments-monthwise?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getTrancheChartData = createAsyncThunk(
    'getTrancheChartData',
    async () => {
        const url = `/dashboard/today-Tranche-Requests`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getLoanDisbursedRegionData = createAsyncThunk(
    'getLoanDisbursedRegionData',
    async ({ region, year }) => {
        const url = `/dashboard/loan-disbursed-region-wise?region=${region}&year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getRegionsList = createAsyncThunk(
    'getRegionsList',
    async () => {
        const url = `/dashboard/regions`
        const res = await DataService.get(url);
        return res.data
    }
)

const initialState = {
    setLoading: true,
    defaultLoader: true,
    disburseLoader: true,
    sanctionLoader: true,
    preLoader: true,
    repaymentsLoader: true,

    interestCollected: [],
    totalInterestCollected: '0',

    preApprovedLoanOffers: [],
    preApprovedLoanOffers_totalVolume: '0',

    repayments: [],
    repayments_totalVolume: '0',

    loanDisbursed: [],
    loanDisbursed_totalVolume: '0',

    loanSanctioned: [],
    loanSanctioned_totalVolume: '0',

    tranche_request_count: '0',
    tranche_request_volume: '0',
    tranche_req_loader: true,

    loan_applications: [],
    loan_applications_volume: '0',

    amount_due: [],

    loandisbursed_graph_geographical: [],

    loan_defaulted: [],
    loan_defaulted_totalVolume: '',

    loan_disbursed_monthly: '',
    loan_disbursed_yearly: '',
    loan_disbursed_today: '',
    disb_region_loader: true,

    regionsList: [],
    initial_region_id: ''

};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: {
        [getInterestChart.pending]: (state) => {
            state.setLoading = true;
        },
        [getInterestChart.fulfilled]: (state, action) => {
            state.setLoading = false;
            state.interestCollected = action.payload.data;
            state.totalInterestCollected = action.payload.totalvolume;
        },
        [getLoanSanctionedData.pending]: (state, action) => {
            state.sanctionLoader = true;
        },
        [getLoanSanctionedData.fulfilled]: (state, action) => {
            state.sanctionLoader = false;
            state.loanSanctioned = action.payload.data;
            state.loanSanctioned_totalVolume = action.payload.totalVolume;
        },
        [getDisburseChart.pending]: (state, action) => {
            state.disburseLoader = true;
        },
        [getDisburseChart.fulfilled]: (state, action) => {
            state.disburseLoader = false;
            state.loanDisbursed = action.payload.data;
            state.loanDisbursed_totalVolume = action.payload.totalVolume;
        },
        [getPreApprovedData.pending]: (state, action) => {
            state.preLoader = true;
        },
        [getPreApprovedData.fulfilled]: (state, action) => {
            state.preLoader = false;
            state.preApprovedLoanOffers = action.payload.data;
            state.preApprovedLoanOffers_totalVolume = action.payload.totalVolume;
        },
        [getRepaymentsData.pending]: (state, action) => {
            state.repaymentsLoader = true;
        },
        [getRepaymentsData.fulfilled]: (state, action) => {
            state.repaymentsLoader = false;
            state.repayments = action.payload.data;
            state.repayments_totalVolume = action.payload.totalvolume
        },
        [getTrancheChartData.pending]: (state) => {
            state.tranche_req_loader = true;
        },
        [getTrancheChartData.fulfilled]: (state, action) => {
            state.tranche_req_loader = false;
            state.tranche_request_count = action.payload.today_tranche_requests?.count;
            state.tranche_request_volume = action.payload.today_tranche_requests?.volume;
        },
        [getLoanDefaultedData.pending]: (state, action) => {
            state.defaultLoader = true;
        },
        [getLoanDefaultedData.fulfilled]: (state, action) => {
            state.defaultLoader = false;
            state.loan_defaulted = action.payload.tolal_loans_defaulted;
            state.loan_defaulted_totalVolume = action.payload.totalVolume;
        },
        [getLoanDisbursedRegionData.pending]: (state) => {
            state.disb_region_loader = true;
        },
        [getLoanDisbursedRegionData.fulfilled]: (state, action) => {
            state.disb_region_loader = false;
            state.loan_disbursed_monthly = action.payload.data.monthly;
            state.loan_disbursed_yearly = action.payload.data.yearbased;
            state.loan_disbursed_today = action.payload.data.today;
        },
        [getLoanDisbursedRegionData.rejected]: (state) => {
            // state.disb_region_loader = false;
        },
        [getRegionsList.fulfilled]: (state, action) => {
            state.regionsList = action.payload.data
        }
    }
})

export default dashboardSlice.reducer