import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Operational from './Operational';
import Kyc from './Kyc';
import CompanyDetails from './CompanyDetails';
import Borrowers from './Borrowers';
import Property from './Property';

const Documents = () => {

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="fullWidth">
              <Tab label="Operational" value="1" />
              <Tab label="KYC" value="2" />
              <Tab label="Company Details" value="3" />
              <Tab label="Borrowers" value="4" />
              <Tab label="Property" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Operational />
          </TabPanel>
          <TabPanel value="2">
            <Kyc />
          </TabPanel>
          <TabPanel value="3">
            <CompanyDetails />
          </TabPanel>
          <TabPanel value="4">
            <Borrowers />
          </TabPanel>
          <TabPanel value="5">
            <Property />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default Documents