import React, { useContext } from 'react'
import {
    Grid,
} from '@mui/material';
import './stepper.css';
import { CustomerData } from '../LoanApplication'

function Personal() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={2} style={{ padding: '20px 100px' }}>
                <Grid item xs={4}>
                    <span className='stepper-label'>Name:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.name}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Email:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.email}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Mobile:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.mobile}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Date Of Birth:</span>
                </Grid>
                <Grid item xs={8}>
                <span className='stepper-label'>{customerData && customerData.dateOfBirth}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Address:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.address}</span>
                </Grid>
            </Grid>
        </>
    )
}

export default Personal