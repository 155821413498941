import React from 'react'
import { Card } from "@material-ui/core";

const useCustomTooltip = () => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length == 2) {
      return (
        <>
          <div>
            <Card style={{ padding: '7px 12px' }}>
              <p>{`${label}`}</p>
              <p>{`${payload[0].name} : ₹ ${payload[0].value}`}</p>
              <p>{`${payload[1].name} : ₹ ${payload[1].value}`}</p>
            </Card>
          </div>
        </>
      );
    }
    else if (active && payload && payload.length < 2) {
      return (
        <>
          <div>
            <Card style={{ padding: '7px 12px' }}>
              <p>{`${label} : ₹ ${payload[0].value}`}</p>
            </Card>
          </div>
        </>
      )
    }
    else {
      return null;
    }
  };
  return { CustomTooltip }
}

export default useCustomTooltip