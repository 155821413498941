import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const borderColor = "#3778C2";

const fonts = {
  courier: "Courier",
  courierBold: "Courier-Bold",
  courierOblique: "Courier-Oblique",
  helvetica: "Helvetica",
  helveticaBold: "Helvetica-Bold",
  helveticaOblique: "Helvetica-Oblique",
  times: "Times-Roman",
  timesBold: "Times-Bold",
  timesItalic: "Times-Italic",
};
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    fontSize: 12,
    flexDirection: "row",
    marginBottom: 10,
    color: "grey",
    justifyContent: "space-between",
  },
  mainLogo: {
    flex: 1,
    margin: 10,
    marginBottom: 0,
    width: "20%",
    height: "auto",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 15,
  },
  companyInfo: {
    fontSize: 12,
    margin: 10,
    flexGrow: 1,
  },
  customerInfo: {
    fontSize: 12,
    width: "100%",
  },
  poc: {
    marginBottom: 5,
    paddingTop: 10,
    fontFamily: fonts.Poppins,
    fontSize: 16,
    fontWeight: "bold",
    color: "#b15c82",
  },
  tableBorder: {
    border: "1px solid black",
  },
  headings: {
    paddingTop: 10,
    fontFamily: fonts.Poppins,
    fontSize: 10,
  },
  underlined: {
    textDecoration: "underline",
  },
  scoreContainer: {
    flexDirection: "coloumn",
    flexWrap: "wrap",
    marginTop: 24,
  },
  enquiryContainer: {
    marginTop: "20",
  },
  score: {
    width: "50%",
    textAlign: "left",
    alignItems: "center",
    paddingLeft: 8,
    color: "#ffff",
  },
  identiChild: {
    flexDirection: "row",
    color: "black",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    fontSize: 10,
    flexGrow: 1,
    backgroundColor: "#b15c82",
  },
  arrayData: {
    height: "auto",
  },
  identification: {
    width: "50%",
    textAlign: "left",
    alignItems: "center",
    paddingLeft: 8,
  },
  scoreData: {
    width: "50%",
    textAlign: "left",
    alignItems: "center",
    paddingLeft: 8,
  },
  retailAccData: {
    width: "50%",
    textAlign: "left",
    alignItems: "center",
    // paddingLeft: '8px',
  },
  scoreDataValue: {
    width: "50%",
    textAlign: "left",
    alignItems: "center",
    paddingLeft: 8,
    fontStyle: "bold",
    fontSize: 15,
  },
  tableContainer: {
    flexDirection: "coloumn",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#3778C2",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  scoreChild: {
    flexDirection: "row",
    color: "#ffff",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    fontSize: 10,
    flexGrow: 1,
    backgroundColor: "#b15c82",
  },
  scoreDataChild: {
    flexDirection: "row",
    color: "black",
    alignItems: "center",
    height: 28,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    backgroundColor: "#e1d6db",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    backgroundColor: "#3778C2",
    color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  headerContainer: {
    display: "inline-block",
    width: "100%",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
  },
  firstChildContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  label: {
    marginTop: 10,
    paddingBottom: 2,
    fontFamily: "Helvetica-Oblique",
    width: "55%",
  },
  childLabel: {
    marginTop: 10,
    paddingBottom: 2,
    fontFamily: "Helvetica-Oblique",
    // width: "40%",
  },
  parentLineWrapper: {
    paddingLeft: 8,
    borderLeftWidth: 2,
    borderLeftStyle: "solid",
    borderLeftColor: "lightgray",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: "black",
    borderBottomWidth: 6,
    borderBottomStyle: "solid",
    borderBottomColor: "lightgray",
    marginBottom: 24,
  },
  childLineWrapper: {
    paddingLeft: 8,
    borderLeftWidth: 2,
    borderLeftStyle: "solid",
    borderLeftColor: "lightgray",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: "black",
  },
  itemTopRow: {
    marginTop: 5,
    flexDirection: "row",
  },
  optionWrapper: {
    marginVertical: 5,
    fontSize: 11,
  },
  optionList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "60%",
  },
  option: {
    fontSize: 9,
    paddingVertical: 4,
    width: "25%",
    maxHeight: 27,
    paddingHorizontal: 4,
  },
  grandTotalLine: {
    width: "100%",
    flexDirection: "row",
  },
  grandTotal: {
    width: "40%",
    textAlign: "right",
  },
  specialRequirements: {
    width: "60%",
    marginBottom: 50,
  },
  terms: {
    paddingTop: 16,
    fontSize: 10,
    color: "darkgrey",
    position: "absolute",
    bottom: 108,
    left: 24,
  },
  footerImage: {
    width: "100%",
    height: "auto",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    paddingHorizontal: 16,
    color: "grey",
  },
  text: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: fonts.helvetica,
  },
  custDetails: {
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  head: {
    marginLeft: "1%",
    textTransform: "uppercase"
  },
  documents: {
    width: "25%",
    textAlign: "left",
    paddingLeft: 8,
  },
  documentNo: {
    width: "25%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  description: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  accno: {
    width: "30%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },

  retailContainer: {
    width: "100%",
    height: 20,
    backgroundColor: "red",
  },
  subRetContainer: {
    width: "20%",
    backgroundColor: "blue",
  },
});

// @NOTE!!
// if a component has wrap={false}, and the component gets so large that it cannot fit on it's own page, then react-pdf will crash and freeze your entire web page!
// also note, that with wrap = true, you cannot currently use flex and flexBasis css properties, as they break when wrapping pages.

export const CreditReport = (props) => {

  
  // const personal_info = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo.personalInfo;


  const personal_info = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.iDAndContactInfo?.personalInfo;
 
  // const IdAndContactInfo = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo.identityInfo;

  const IdAndContactInfo = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.iDAndContactInfo?.identityInfo;
 
  // const retailAcInfo = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.retailAccountDetails;

  const retailAcInfo = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.retailAccountDetails;
 
  // const scoreInfo = props && props?.data && props?.data?.data && props?.data?.data?.cCRResponse && props?.data?.data?.cCRResponse?.cIRReportDataLst[0] && props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData && props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.scoreDetails[0];

  const scoreInfo = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.scoreDetails[0];

  // const phInfo = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.iDAndContactInfo.phoneInfo;

  const phInfo = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.iDAndContactInfo?.phoneInfo;

  // const enqInfo = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.enquiries;

  const enqInfo = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.enquiries;

  // const enquirySum = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData?.enquirySummary;

  const enquirySum = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.enquirySummary;

  // const retailAccDetails = props && props.data && props.data.data && props.data.data.cCRResponse && props.data.data.cCRResponse.cIRReportDataLst[0] && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData && props.data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.retailAccountDetails;

  const retailAccDetails = props?.data?.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.retailAccountDetails;

  const date = new Date().toLocaleDateString();
  const productIds = [1];

  const parentLineItem = () => {

    const itemHeader = (
      <View style={styles.scoreContainer}>
        <View style={styles.poc}>
          <Text style={styles.head}>Cibil Score:</Text>
        </View>
        <View style={styles.tableBorder}>
          <View style={styles.scoreChild}>
            <Text style={styles.score}>SCORE NAME</Text>
            <Text style={styles.score}>VERSION</Text>
            <Text style={styles.score}>SCORE</Text>
          </View>
          <View style={styles.scoreDataChild}>
            <Text style={styles.scoreData}>{scoreInfo?.name}</Text>
            <Text style={styles.scoreData}>{scoreInfo?.version}</Text>
            <Text style={styles.scoreDataValue}>{scoreInfo?.value}</Text>
          </View>
        </View>
      </View>
    );

    const identification = (
      <View style={styles.scoreContainer}>
        <View style={styles.poc}>
          <Text style={styles.head}>Identification(s):</Text>
        </View>
        <View style={styles.tableBorder}>
          <View style={styles.identiChild}>
            <Text style={styles.identification}>IDENTIFICATION TYPE</Text>
            <Text style={styles.identification}>NUMBER:</Text>
          </View>
          <View style={styles.scoreDataChild}>
            <Text style={styles.scoreData}>VOTER'S ID</Text>
            <Text style={styles.scoreData}>
              {" "}
              {IdAndContactInfo && IdAndContactInfo?.voterID ? IdAndContactInfo && IdAndContactInfo?.voterID[0] && IdAndContactInfo?.voterID[0]?.idNumber : ""}
            </Text>
          </View>
          <View style={styles.scoreDataChild}>
            <Text style={styles.scoreData}>PAN CARD</Text>
            <Text style={styles.scoreData}>
              {" "}
              {IdAndContactInfo && IdAndContactInfo?.pANId ? IdAndContactInfo && IdAndContactInfo?.pANId[0] && IdAndContactInfo?.pANId[0]?.idNumber : ""}
            </Text>
          </View>
        </View>
      </View>
    );

    const phoneInfo = (
      <View style={styles.scoreContainer}>
        <View style={styles.poc}>
          <Text style={styles.head}>TELEPHONE(S):</Text>
        </View>
        <View style={styles.tableBorder}>
          <View style={{ ...styles.arrayData, ...styles.identiChild }}>
            <Text style={styles.identification}>TELEPHONE TYPE</Text>
            <Text style={styles.identification}>NUMBER:</Text>
          </View>
          {phInfo &&
            phInfo.map((item) => {
              return (
                <>
                  <View style={styles.scoreDataChild}>
                    <Text style={styles.scoreData}>
                      {item.typeCode === "M" ? "Mobile" : "Others"}
                    </Text>
                    <Text style={styles.scoreData}> {item.number}</Text>
                  </View>
                </>
              );
            })}
        </View>
      </View>
    );

    const enquiries = (
      <View style={styles.scoreContainer} break>
        <View style={styles.poc}>
          <Text style={styles.head}>ENQUIRIES:</Text>
        </View>

        <View style={styles.identiChild}>
          <Text style={styles.identification}>INSTITUTION</Text>
          <Text style={styles.identification}>AMOUNT</Text>
          <Text style={styles.identification}>DATE</Text>
          <Text style={styles.identification}>TIME</Text>
        </View>
        {enqInfo &&
          enqInfo.map((item) => {
            return (
              <>
                <View style={styles.scoreDataChild}>
                  <Text style={styles.scoreData}>{item.institution}</Text>
                  <Text style={styles.scoreData}> {item.amount}</Text>
                  <Text style={styles.scoreData}> {item.date}</Text>
                  <Text style={styles.scoreData}> {item.time}</Text>
                </View>
              </>
            );
          })}
      </View>
    );

    const enquirySummary = (
      <View style={styles.scoreContainer} break>
        <View style={styles.poc}>
          <Text style={styles.head}>ENQUIRY SUMMARY:</Text>
        </View>

        <View style={styles.identiChild}>
          <Text style={styles.identification}>PURPOSE</Text>
          <Text style={styles.identification}>TOTAL</Text>
          <Text style={styles.identification}>PAST 12 MONTHS</Text>
          <Text style={styles.identification}>PAST 24 MONTHS</Text>
          <Text style={styles.identification}>PAST 30 DAYS</Text>
        </View>
        return (
        <>
          <View style={styles.scoreDataChild}>
            <Text style={styles.scoreData}>{enquirySum?.purpose}</Text>
            <Text style={styles.scoreData}> {enquirySum?.total}</Text>
            <Text style={styles.scoreData}> {enquirySum?.past12Months}</Text>
            <Text style={styles.scoreData}> {enquirySum?.past24Months}</Text>
            <Text style={styles.scoreData}> {enquirySum?.past30Days}</Text>
          </View>
        </>
        );
      </View>
    );

    const retailAccountSummary = (
      <View style={styles.scoreContainer} break>
        <View style={styles.poc}>
          <Text style={styles.head}>RETAIL ACCOUNT DETAILS:</Text>
        </View>

        <View style={styles.identiChild}>
          <Text style={styles.identification}>A/C NO</Text>
          <Text style={styles.identification}>BALANCE</Text>
          <Text style={styles.identification}>BANK</Text>
          <Text style={styles.identification}>D/T OPENED</Text>
        </View>
        {retailAccDetails && retailAccDetails.map((item) => {
          return (
            <>
              <View style={styles.scoreDataChild}>
                <Text style={styles.scoreData}>{item.accountNumber}</Text>
                <Text style={styles.scoreData}>{item.balance}</Text>
                <Text style={styles.retailAccData}>{item.institution}</Text>
                <Text style={styles.scoreData}>{item.dateOpened}</Text>
              </View>
            </>
          );
        })}
      </View>
    );

    return (
      <View wrap={true}>
        {itemHeader}
        {identification}
        {phoneInfo}
        {enquiries}
        {enquirySummary}
        {retailAccountSummary}
      </View>
    );
  };

  const header = (
    <View style={styles.header}>
      <View>
        <Text>Ref No: 12546118</Text>
        <Text>Date Of Request: {date}</Text>
      </View>
    </View>
  );

  const titleSection = (
    <View style={styles.title}>
      <Text>Customer Credit Report</Text>
    </View>
  );

  const customerInfo = (
    <View style={styles.customerInfo}>
      <View style={styles.poc}>
        <Text style={styles.head}>Consumer CIR:</Text>
      </View>

      <View style={styles.headerContainer}>
        <View style={styles.firstChildContainer}>
          <Text style={styles.label}>Consumer:</Text>
          <Text style={styles.childLabel}>{personal_info && personal_info?.name && personal_info?.name?.fullName}</Text>
        </View>
        <View style={styles.firstChildContainer}>
          <Text style={styles.label}>Gender:</Text>
          <Text style={styles.childLabel}>{personal_info?.gender}</Text>
        </View>
        <View style={styles.firstChildContainer}>
          <Text style={styles.label}>Date Of Birth:</Text>
          <Text style={styles.childLabel}>{personal_info?.dateOfBirth}</Text>
        </View>
        <View style={styles.firstChildContainer}>
          <Text style={styles.label}>Age:</Text>
          <Text style={styles.childLabel}>{personal_info && personal_info?.age && personal_info?.age?.age}</Text>
        </View>
      </View>

      <View style={styles.tableContainer}></View>
    </View>
  );

  return (
    <Document
      file="REACtPDF.pdf"
      author="Power Innovations Sales Person"
      title="Credit Report"
    >
      <Page size="A4" style={styles.page} wrap>
        {header}
        {titleSection}
        {customerInfo}
        {productIds.map((option) => parentLineItem())}
      </Page>
    </Document>
  );
};

export default CreditReport;
