import React from "react";
import { Paper, Card } from "@material-ui/core";
import GridView from "../GridView";
import makeStyles from "../LoanStatementStyle";
import { useSelector } from "react-redux";

const LoanBasicDetails = () => {
  const moment = require("moment");
  const classes = makeStyles();
  const { loanBasicDetails } = useSelector((state) => state.loanSanction);
 
  return (
    <>
      <Card style={{padding: "20px", boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)' }}>
        <GridView
          firstColName={"Name:"}
          firstData={
            loanBasicDetails &&
            loanBasicDetails.customer.name
          }
          secColName={"Loan Acc No:"}
          secData={
            loanBasicDetails &&
            loanBasicDetails.loanaccountNumber
          }
        />
        <GridView
          firstColName={"Email:"}
          firstData={
            loanBasicDetails &&
            loanBasicDetails.customer.email
          }
          secColName={"Agreement Start Date:"}
          secData={
            moment(
              loanBasicDetails &&
              loanBasicDetails.agreement_start
            ).format("DD MMMM YYYY")
          }

        />
        <GridView
          firstColName={"Company Name"}
          firstData={
            loanBasicDetails &&
            loanBasicDetails.companyName
          }
          secColName={"Agreement End Date:"}
          secData={moment(
            loanBasicDetails &&
            loanBasicDetails.agreement_end
          ).format("DD MMMM YYYY")}
        />
      </Card>
      {/* <Paper className={classes.boxShadow}> */}
        {/* <div className={classes.basicdetails}> */}

        {/* {/* <div className={classes.basicleft}>
          <Typography className={classes.elements}>Name:</Typography>
          <Typography className={classes.elements}>Email:</Typography>
          <Typography className={classes.elements}>Company Name:</Typography>
        </div>
        <div className={classes.basicRight}>
          <Typography className={classes.elements}>
            {loanBasicDetails &&
              loanAccountDetails.basic &&
              loanAccountDetails.basic.customer &&
              loanAccountDetails.basic.customer.name}
          </Typography>
          <Typography className={classes.elements}>
            {loanAccountDetails &&
              loanAccountDetails.basic &&
              loanAccountDetails.basic.customer &&
              loanAccountDetails.basic.customer.email}
          </Typography>
          <Typography className={classes.elements}>
            {loanAccountDetails &&
              loanAccountDetails.basic &&
              loanAccountDetails.basic.companyName}
          </Typography>
        </div>
        <div className={classes.basicleft}>
          <Typography className={classes.elements}>Loan Account No:</Typography>
          <Typography className={classes.elements}>
            Agreement Start Date:
          </Typography>
          <Typography className={classes.elements}>
            Agreement End Date:
          </Typography>
        </div>
        <div className={classes.basicRight}>
          <Typography className={classes.elements}>
            {loanAccountDetails &&
              loanAccountDetails.basic &&
              loanAccountDetails.basic.loanaccountNumber}
          </Typography>
          <Typography className={classes.elements}>
            {moment(
              loanAccountDetails &&
                loanAccountDetails.basic &&
                loanAccountDetails.basic.agreement_start
            ).format("DD MMMM YYYY")}
          </Typography>
          <Typography className={classes.elements}>
            {moment(
              loanAccountDetails &&
                loanAccountDetails.basic &&
                loanAccountDetails.basic.agreement_end
            ).format("DD MMMM YYYY")}
          </Typography>
        </div> */}
        {/* </div> */}
      {/* </Paper> */}
    </>
  );
};

export default LoanBasicDetails;
