import React from 'react'
import './FinalDashboard.css'
import { BiRupee } from "react-icons/bi";

const GraphHeader = ({header, subheader}) => {
  return (
    <div>
        <header className='g-two-head'>
            <h3 className='g-two-header'>{header}</h3>
            <h4 className='g-two-subheader'> <BiRupee />{subheader}</h4>
        </header>
    </div>
  )
}

export default GraphHeader