import React, { useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { Card, Grid, IconButton, Typography, ImageList } from "@mui/material";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModalImage from "react-modal-image";
import DialogueBox from "../../common/DialogueBox";
import click_here from "./click_here_SVG.svg"
import RejectionModal from "../Stepper/RejectionModal";

const BorrowersInfo = ({ onClick }) => {
  const { applicDetails } = useSelector((state) => state.loanApplication);
  //   const [documents, setDocuments] = useState(
  //     applicDetails && applicDetails.documets
  //   );
  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [currentItem, setCurrentItem] = useState()
  const status = 5;
  const URL = process.env.REACT_APP_PARTNER_URL;

  //Rejection Modal
  const [openRejModal, setOpenRejModal] = useState(false)

  const rejModalOpen = (item) => {
    // setDialogText("reject");
    setCurrentItem(item);
    setOpenRejModal(true)
  }

  const rejModalClose = () => {
    setOpenRejModal(false)
  }

  const onBtnClick = () => {
    setOpenDialogue(!openDialogue);
  }

  return (
    <>
      <TabPanel value="4">
        <Card style={{ height: "250px", overflowY: "scroll" }}>
          {applicDetails && applicDetails.documets &&
            applicDetails.documets[5]?.map((item) => {
              return (
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={4} alignItem="center" dispaly="flex">
                    <ImageList sx={{ mt: "13px", ml: "31px" }}>
                      <ModalImage
                        hideZoom="true"
                        hideDownload="true"
                        smallSrcSet={click_here}
                        medium={item.fileName}
                      // alt="Click Here"
                      />
                    </ImageList>
                  </Grid>
                  <Grid item xs={4} alignItem="center">
                    <Typography>
                      <strong>
                        {item.status}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} alignItem="center">

                    {
                      item.status === "verified" || item.status === "rejected" ? ('') : (
                        <IconButton onClick={() => {
                          setOpenDialogue(true)
                          setDialogText('accept')
                          setCurrentItem(item)
                        }}>
                          <DoneIcon
                            color={
                              item.status === "uploaded" ? "primary" : "disabled"
                            }
                          // onClick={() => onClick(3, item._id, "verified")}  
                          />
                        </IconButton>
                      )}

                    {
                      item.status === "verified" ||
                        item.status === "rejected" ? (
                        ""
                      ) : (
                        <IconButton onClick={
                          item.status != "rejected"
                            ? () => rejModalOpen(item)// () => onClick(2, item._id, "rejected")
                            // () => {
                            //   setOpenDialogue(true);
                            //   setDialogText("reject");
                            //   setCurrentItem(item);
                            // }
                            : () => { }
                        }>
                          <CloseRoundedIcon
                            color={
                              item.status === "uploaded"
                                ? "error"
                                : "disabled"
                            }
                          // onClick={() => onClick(3, item._id, "rejected")}
                          />
                        </IconButton>
                      )}

                  </Grid>
                </Grid>
              );
            })}
        </Card>
      </TabPanel>
      {openDialogue ? (
        <DialogueBox
          open={openDialogue}
          onClose={onBtnClick}
          text={dialogText}
          handlefnc={onClick}
          item={currentItem}
          status={status}
        />) : null}

      {
        openRejModal ? <RejectionModal open={openRejModal} handleClose={rejModalClose} handlefnc={onClick} item={currentItem} status={status} /> : ''
      }
    </>
  );
};

export default BorrowersInfo;
