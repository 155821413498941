import { Select } from 'antd';
import './businesssummary.css'
import React, { useState } from 'react'
import CreditLine from './CreditLine/CreditLine';
import PreapprovedLoan from './PreapprovedLoan/PreapprovedLoan';
import WeeklyLoans from './WeeklyLoans/WeeklyLoans';
import LoanClosures from './LoanClosures/LoanClosures';
import { useDispatch, useSelector } from 'react-redux';
import { getallstates, getcommonapidata, updatecommonFilterStates } from '../../../slice/commonslice';
import { useEffect } from 'react';

const BusinessSummary = () => {
    const [value, setValue] = useState([]);
    const [options, setOptions] = useState([]);
    const {allStates} = useSelector(getcommonapidata)
    const dispatch = useDispatch();

    const selectProps = {
        mode: 'multiple',
        value,
        options,
        onChange: (newValue) => {
          setValue(newValue);
        },
        showSearch:true,
        placeholder: 'All India',
        maxTagCount: 'responsive',
    };
    useEffect(()=>{
      if(!allStates){
          dispatch(getallstates())
      }else{
        const newoptions = [];
        allStates.map((item)=>{
          return newoptions.push({
            label:item.stateName,
            value:item._id
          })
        })
        setOptions(newoptions)
      }
    },[allStates])
    useEffect(()=>{
      dispatch(updatecommonFilterStates(value))
    },[value])
    return (
        <div className="business-summary-seaction">
            <div className="displayflex">
                <h2 className="section-title">Business Summary</h2>
                <Select {...selectProps} allowClear showArrow className='select-state'
                  filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}/>
            </div>
            <div className="card-container">
                <CreditLine/>
                <PreapprovedLoan/>
                <WeeklyLoans/>
                <LoanClosures/>
            </div>
        </div>
    )
}

export default BusinessSummary