import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ProgressSpinner from '../common/ProgressSpinner';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

const LoadingModal = ({open, handleClose}) => {
    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <ProgressSpinner />
                    <Typography variant="subtitle2" gutterBottom>Please Wait...</Typography>
                </Box>
            </Modal>
        </>
    )
}

export default LoadingModal