import React from "react";
import { Typography, Grid, ListItemText } from "@material-ui/core";
import styles from "./loanviewstyles";
import { useSelector } from "react-redux";

const CurrencyView = () => {
  const myStyles = styles();
  const { loanDetails } = useSelector((state) => state.loan);
  return (
    <>
     <Grid className={myStyles.viewHeading}>
        <h1>Settings</h1>
        <hr />
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Amortization Type:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails &&
                      loanDetails.amortizationType &&
                      loanDetails.amortizationType.value}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
            <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
              <Grid item md={12}>
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listHeading}>
                      Interest Method
                    </Typography>
                  }
                />
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listValues}>
                      {loanDetails &&
                        loanDetails.interestType &&
                        loanDetails.interestType.value}
                         </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Equal Amortization:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails.isEqualAmortization === false ? "False" : "True"}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Interest Calculation Period:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails &&
                      loanDetails.interestCalculationPeriodType &&
                      loanDetails.interestCalculationPeriodType.value}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Interest Rate Frequency Type:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails &&
                      loanDetails.interestRateFrequencyType &&
                      loanDetails.interestRateFrequencyType.value}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Interest Rate Per Period:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails && loanDetails.interestRatePerPeriod}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CurrencyView;
