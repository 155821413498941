import React from "react";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      position: "absolute",
    },
    "& .Mui-error": {
      position: "absolute",
    },
  },
});

const TextArea = ({
  value,
  name,
  className,
  placeholder,
  error = null,
  onChange,
  onKeyPress,
  maxLength,
  disabled,
  ...others
}) => {
  // console.log('errors',error);
  const classes = useStyles();
  return (
    <>
      <TextareaAutosize
        name={name}
        aria-label="empty textarea"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        className={className}
        onChange={onChange}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        error={error}
        variant="outlined"
        {...others}
        // style={{ height: "150px" }}
        {...(error && { error: true, helperText: error })}

        // helperText={text === "" ? 'Empty field!' : ' '}
      />
    </>
  );
};

export default TextArea;
