import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './Layout/Auth';
import './App.css'
import AdminLayout from './Layout/Admin';
import Login from './components/Login/Login';
import Privacypolicy from './components/Privacypolicy/Privacypolicy';
import PrivacyPolicyUpdated from './components/Privacypolicy/PrivacyPolicyUpdated';
import ProtectedRoutes from './components/Sharedfeatures/ProtectedRoutes';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import PageLayout from './Layout/PageLayout';



const App = () => {
  let navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  // useEffect(() => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (accessToken === null || accessToken === 'undefined') {
  //     navigate("/auth/login");
  //   }
  // }, []);

  // const checkToken = () => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (accessToken === null || accessToken === ' undefined') {
  //     navigate("/auth/login");
  //   }
  // }

  // window.onbeforeunload = () => {
  //   localStorage.clear();
  // }
  return (
    <>
      <React.StrictMode>
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          {/* <Route path="/admin/*" element={<checkToken><AdminLayout /></checkToken>} /> */}
          {/* <Route path="/admin" element={<checkToken><FinalDashboard /></checkToken>} /> */}
          <Route path='/admin/*' element={<ProtectedRoutes accessToken={accessToken}><PageLayout /></ProtectedRoutes>} />
          <Route path="/" element={<Login />} />
          <Route path="/privacy_policy" element={<Privacypolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyUpdated />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:id/:token" element={<ResetPassword />} />
        </Routes>
      </React.StrictMode>
    </>
  )
}

export default App

