import React, {useState} from 'react'
import './stepper.css'
import { motion } from 'framer-motion'
import ReviewLoanAppli from './ReviewLoanAppli'
import { Modal, Box } from '@material-ui/core'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    bgcolor: 'background.paper',
    padding: '20px 0 50px 0',
    borderRadius: '20px'
  };

const Popups = (props) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Modal
                open={open}
                // onClose={handleClose}
            >
            <Box sx={style}>
                {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} className="modal-backdrop" />
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { duration: 0.3 } }} className="modal-content-wrapper">
                <motion.div initial={{ x: 0 }} animate={{ x: 0, transition: { delay: 0.3 } }} className="modal-content"> */}
                <ReviewLoanAppli data={props} />
                {/* </motion.div>
            </motion.div> */}
            </Box>
            </Modal>
        </>
    )
}

export default Popups
