import React from 'react'
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children, accessToken }) => {
    if (!accessToken) {
        return <Navigate to='/auth/login' />;
    }
    return children;
}

export default ProtectedRoutes