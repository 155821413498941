import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import useSharedClasses from "../../Sharedfeatures/SharedClasses";
import { PAGE_SIZE } from "../../../utils/variable";
import { useNavigate, useParams } from "react-router-dom";
import useTable from "../../Sharedfeatures/UseTable";
import HeadCells from "../../common/HeadCells";
import {
  getLoanAccountDetails,
  getLoanSanctionList,
  generateExcel,
  reset_message,
} from "../../../slice/loanSanctioned";
import { useDispatch, useSelector } from "react-redux";
import LoanBasicDetails from "../LoanAccountDetails/LoanBasicDetails";
import makeStyles from "../LoanStatementStyle";
import ProgressSpinner from "../../common/ProgressSpinner";
import {
  TXT_LOAN_ACCOUNTDET,
  TXT_LOAN_SANCTIONED,
  TXT_HOME,
  BACK_BUTTON,
} from "../../../utils/header";
import PageHeader from "../../Sharedfeatures/PageHeader";
import useExcel from "../../common/useExcel";
import { LOAN_SANCTIONED } from "../../../utils/url";

const LoanAccountDetails = () => {
  const { clientId } = useParams();
  // const [open, setOpen] = useState(false);
  const sharedClasses = useSharedClasses();
  const [showPageNtn, setShowPageNtn] = useState(false);
  // const [search, setSearch] = useState("");
  const classes = makeStyles();
  const { loanAccountDetails, setLoading } = useSelector(
    (state) => state.loanSanction
  );
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const excelData = useExcel();
  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(
    loanAccountDetails,
    HeadCells.loanAccountHeads
  );
  useEffect(() => {
    // dispatch(generateExcel());
    dispatch(getLoanSanctionList());
    dispatch(reset_message());
  }, []);
  useEffect(() => {
    dispatch(getLoanAccountDetails(clientId));
  }, [dispatch, clientId]);

  let serialNo = PAGE_SIZE * slNo;

  const btnClick = (clientId , id) => {
    navigate(
      `/admin/loansanctioned/loanaccountdetails/loanaccountstatement/${id}/${clientId}`
    );
  };

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_LOAN_ACCOUNTDET}
            subheader={`${TXT_HOME}/${TXT_LOAN_SANCTIONED}/${TXT_LOAN_ACCOUNTDET}`}
            icon={BACK_BUTTON}
            link={LOAN_SANCTIONED}
          />

          <LoanBasicDetails />

          <section className={sharedClasses.mainSection}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.typography}
            >
              Tranche Details
            </Typography>

            <Paper className={classes.boxShadow}>
              <TblContainer>
                <TblHead />
                {setLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <ProgressSpinner />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {loanAccountDetails && loanAccountDetails.length !== 0 ? (
                      loanAccountDetails &&
                      loanAccountDetails.map((item, index) => {
                        serialNo++;
                        return (
                          <>
                            <TableRow key={item.id.toString()}>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {serialNo}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item.accountNo}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item.externalId}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item.productName}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item.originalLoan}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item.amountPaid || '--'}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                {item && item.status && item.status.value}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={sharedClasses.tableCell}
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => btnClick(clientId , item.id)}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={8}
                            align="center"
                            className={sharedClasses.tableCell}
                          >
                            No Data to Show
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                )}
              </TblContainer>
            </Paper>
            {showPageNtn ? <TblPagination /> : ""}
          </section>
        </div>
      </div>
    </>
  );
};

export default LoanAccountDetails;
