import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getupcomingRepayments = createAsyncThunk(
    "getupcomingRepayments",
    async (data, thunkAPI) => {
        const { fromDate, toDate, pageNo } = data;
        try {
            const url = `/loans/repayments/list?start=${fromDate}&end=${toDate}&page=${pageNo}&limit=10`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getUpRepaymentsDataExcel = createAsyncThunk(
    'getUpRepaymentsDataExcel',
    async (data, thunkAPI) => {
        const { fromDate, toDate } = data;
        try {
            const url =`/loans/repayments/list?page=1&limit=0&start=${fromDate}&end=${toDate}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

const initialState = {
    upcRepList: [],
    isLoading: true,
    totalCount: '',
    uprepaymentDataExcel: []
}

const getUpcomingRepaymentsList = createSlice({
    name: 'upcomingRepayments',
    initialState,
    extraReducers: {
        [getupcomingRepayments.pending]: (state) => {
            state.isLoading = true;
        },
        [getupcomingRepayments.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.upcRepList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
        },
        [getupcomingRepayments.rejected]: (state) => {
            state.isLoading = false;
        },
        [getUpRepaymentsDataExcel.fulfilled]: (state, action) => {
            state.uprepaymentDataExcel = action.payload.data;
        },
    }
})

export default getUpcomingRepaymentsList.reducer