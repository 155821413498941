import React, { useEffect, useState } from 'react'
import './login.css'
import logo from '../../assets/images/login-page-logo.svg'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { styled } from "@mui/system";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../slice/auth';
import { DASHBOARD } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import { Card, Link, Button, Grid, Avatar, Alert, IconButton, Typography, Container } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

const TextField = styled(TextValidator)(() => ({
    width:'100%',
    margin: '10px 0'
  }));
const Login = () => {
    const [state, setState] = useState({ username: "" });
    const [alert, setAlert] = useState();
    const [passType, setPassType] = useState('password');
    const [lockOpen, setLockOpen] = useState(false);
    const { message, flag } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const lockopenfn = (val)=>{
        setLockOpen(val)
        val === true ? setPassType('text') : setPassType('password')
    }
    const handleSubmit = (e) => {
      setLoading(true);
        e.preventDefault();
        dispatch(login(state))
          .unwrap()
          .then(() => {
            setLoading(false);
            navigate(`${DASHBOARD}`);
          })
          .catch((err) => {
            setState({});
          });
    };
    useEffect(() => {
        if (message !== undefined) {
          setLoading(true);
          setAlert(message);
          const timer = setTimeout(() => {
            setLoading(false);
            setAlert(null);
            return () => clearTimeout(timer);
          }, 2000);
        }
      }, [message, flag]);
    return (
        <div className="login-page-wrap">
            <div className="login-wrap">
                <div className="img-wrap">
                    <img src={logo} alt="Atlas Logo" />
                </div>
                <div className="login-form-wrap">
                    <div className="title-wrap">
                        <span>Admin - Log in</span>
                    </div>
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                        {alert ? <Alert severity="error">{message}</Alert> : <></>}
                    </Typography>
                    <ValidatorForm className='login-form' onSubmit={handleSubmit} onError={() => null}>
                        <TextField
                        type="text"
                        id="standard-basic"
                        label="Enter Username"
                        name="username"
                        InputLabelProps={{
                            style: {'color': 'rgba(47, 38, 97, 0.75)',
                                'fontFamily': 'Poppins',
                                'fontSize': '14px',
                                'fontStyle': 'normal',
                                'fontWeight': 500,
                                'lineHeight': 'normal',
                                'width': '100%',
                                'marginTop':'10px'
                            },
                        }}
                        className='username'
                        InputProps={{
                            endAdornment: (
                            <IconButton>
                                <PersonOutlineOutlinedIcon id="userIcon" />
                            </IconButton>
                            ),
                        }}
                        value={state.username || ""}
                        autoComplete="off"
                        variant="standard"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        />
                        <TextField
                        // style={{ borderBottom: "1px solid white" }}
                        id="standard-basic"
                        label="Enter Password"
                        className="password"
                        type={passType}
                        name="password"
                        InputLabelProps={{
                            style: {'color': 'rgba(47, 38, 97, 0.75)',
                                'fontFamily': 'Poppins',
                                'fontSize': '14px',
                                'fontStyle': 'normal',
                                'fontWeight': 500,
                                'lineHeight': 'normal',
                                'width': '100%',
                                'marginTop':'10px'
                            },
                        }}

                        InputProps={{
                            endAdornment: (
                            <IconButton>
                                {
                                    lockOpen ?
                                    <LockOpenOutlinedIcon onClick={()=> lockopenfn(false)}/>
                                    :
                                    <LockOutlinedIcon onClick={()=> lockopenfn(true)} id="lockIcon" />
                                }
                            </IconButton>
                            ),
                        }}
                        value={state.password || ""}
                        autoComplete="off"
                        variant="standard"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="login-button"
                        >
                        Log In
                        </Button>
                        <div className="forgot-link">
                            <Link href="/forgotpassword" className='forgotlink'>
                                <span >Forgot password? Click Here</span>
                            </Link>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    )
}

export default Login