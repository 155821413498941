import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "../Routes";
import Navbar from "../components/Sharedfeatures/Navbar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./Admin.css";
import Header from "../components/Header/Header";

const AdminLayout = () => {
  
  let navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (accessToken) {
        if (route.layout === "/admin") {
          return (
            <Route path={route.path} key={key} element={<route.component />} />
          );
        }
      } else {
        navigate("/auth/login");
      }
    });
  };
  return (
    <>
      <Navbar />
      <Routes>{getRoutes(routes)}</Routes>
    </>
  );
};
const Container = styled.div`
  display: flex;
  height: 100%;
  //background: linear-gradient(to bottom right, white 0%, #f2f2f2 70%);
  border-radius: 1rem;
  width: 100%;
`;
export default AdminLayout;
