import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slice/auth";
import messageReducer from "./slice/message";
import loanReducer from "./slice/loan";
import preApprovedReducer from './slice/preApproved'
import partnerReducer from './slice/partners';
import loanApplicationReducer from './slice/loanapplication'
import trancheRequestReducer from './slice/trancheRequest'
import loanSanctionedReducer from './slice/loanSanctioned';
import loanDisbursedReducer from './slice/loanDisbursed';
import repaymentReducer from './slice/repayments'
import finalDashboardReducer from './slice/finaldashboard'
import borrowersReducer from './slice/borrowerslist'
import forgotpasswordReducer from './slice/forgotpassword'
import loanDefaultedReducer from './slice/loanDefaulted'
import loanClosedReducer from './slice/loanClosed'
import chargesOverdueReducer from './slice/overduecharges'
import upcomingRepaymentsReducer from './slice/upcomingRepayments';
import notificationsReducer from './slice/header';
import commonslice from './slice/commonslice';
import welcomeSlice from './slice/DashboardSlice/welcomeSlice';
import loanStatusSlice from './slice/DashboardSlice/loanStatusSlice';
import totalRevenueSlice from './slice/DashboardSlice/totalRevenueSlice';
import feesSlice from './slice/DashboardSlice/feesSlice';
import applicationStatusSlice from './slice/DashboardSlice/applicationStatusSlice';
import repaymentsSlice from './slice/DashboardSlice/repaymentsSlice';
import weeklyLoansSlice from './slice/DashboardSlice/weeklyLoansSlice';
import loanOffersSlice from './slice/DashboardSlice/loanOffersSlice';
import loanClosuresSlice from './slice/DashboardSlice/loanClosuresSlice';
import activeCreditLineSlice from './slice/DashboardSlice/activeCreditLineSlice';
import dpdSlice from './slice/DashboardSlice/dpdSlice';
import geographicalDistributionSlice from './slice/DashboardSlice/geographicalDistributionSlice';
import tranchesListSlice from './slice/tranchesListSlice';
import virtualAccountSlice from './slice/virtualAccountSlice';
import inProgressRepaymentsSlice from './slice/inProgressRepaymentsSlice';

const reducer = {
  auth: authReducer,
  message: messageReducer,
  partner: partnerReducer,
  loan: loanReducer,
  preApproved: preApprovedReducer,
  loanApplication: loanApplicationReducer,
  trancheRequest: trancheRequestReducer,
  loanSanction: loanSanctionedReducer,
  loanDisburse: loanDisbursedReducer,
  repayments: repaymentReducer,
  finaldashboard: finalDashboardReducer,
  borrowers: borrowersReducer,
  forgotPassword: forgotpasswordReducer,
  loanDefaulted: loanDefaultedReducer,
  loanClosed: loanClosedReducer,
  chargesOverdue: chargesOverdueReducer,
  upcomingRepayments: upcomingRepaymentsReducer,
  trancheslistapi: tranchesListSlice,
  notifications: notificationsReducer,
  commonapi: commonslice,
  dashboardwelcomdata : welcomeSlice,
  loanstatusapi : loanStatusSlice,
  totalrevenueapi : totalRevenueSlice,
  feesapi : feesSlice,
  applicationstatusapi : applicationStatusSlice,
  repaymentsapi : repaymentsSlice,
  weeklloansapi : weeklyLoansSlice,
  loanoffersapi: loanOffersSlice,
  loanclosuresapi: loanClosuresSlice,
  activecreditlineapi : activeCreditLineSlice,
  dpdapi: dpdSlice,
  geographicalapi : geographicalDistributionSlice,
  virtualaccountapi : virtualAccountSlice,
  inprogressrepaymentsapi : inProgressRepaymentsSlice,
  
}

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: true,
})

export default store;