import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatRupees } from '../../../../utils/formatRupees';
import MonthSelectBox from '../../Common/MonthSelectBox';

const WeeklyLoansDataCard = ({cardData,value,setValue}) => {

    return (
        <div className="data-card-wrap">
            <MonthSelectBox value={value} setValue={setValue}/>
            <div className="card loan-amnt">
                <div className="displayflex">
                    <h4 className="card-title">{cardData?.mainTitle} Weekly Loan Amount</h4>
                    <h3 className="card-title"><CurrencyRupeeIcon className='rupee'/>{formatRupees(cardData?.amount)}</h3>
                </div>
            </div>
            <div className="card loan-count">
                <div className="displayflex">
                    <h4 className="card-title">{cardData?.mainTitle} Weekly Loans Count</h4>
                    <h3 className="card-title loancount">{cardData?.count}</h3>
                </div>
            </div>
        </div>
    )
}

export default React.memo(WeeklyLoansDataCard)