import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    loanAppDetailParent: {
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    stepperParent: {
        width: "100%",
        height: '100%'
    },
    stepperComponent: {
        //height: '75%'
    },
    stepperButtonsParent: {
        display: "flex",
        flexDirection: "row"
    },
    documentsTabsHead: {
        height: '250px',
        padding: '20px',
        overflowY: 'auto'
    },
    customModalImage: {
        border: '2px solid #ccc',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        marginBottom: '10px'
        // backgroundColor: 'green'
    }
})

export default useStyles