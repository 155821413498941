import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const retrievePartnersList = createAsyncThunk(
  "partnerList",
  async (thunkAPI) => {
    const url = '/companies'
    const res = await DataService.get(url);
    return res.data.data
  }
);

export const addPartner = createAsyncThunk(
  "partnerList/add",
  async (state, thunkAPI) => {
    try {
      const url = '/companies'
      const { ...data } = state
      const res = await DataService.post(url, data);
      return res.data
    } catch (error) {

    }
  }
);

const initialState = {
  partnerList: [],
  message: ''
};

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  extraReducers: {
    [retrievePartnersList.fulfilled]: (state, action) => {
      state.message = ''
      state.success = ''
      state.partnerList = action.payload
    },
    [addPartner.fulfilled]: (state, action) => {
      state.message = action.payload.message
      state.success = action.payload.success
    }
  },
});

const { reducer } = partnerSlice;

export default reducer;