import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanDisbursedList = createAsyncThunk(
  "disburseList",
  async ({ pageNum, searchQry, fromDate, toDate }) => {
    const url = `loans/disbursed-list?page=${pageNum}&limit=10&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

// Download Loan disbursed Excel
export const getLoanDisbursedExcel = createAsyncThunk(
  "disburseExcel",
  async ({ searchQry, fromDate, toDate, totalCount }) => {
    const url = `loans/disbursed-list?page=1&limit=${totalCount}&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`
    const res = await DataService.get(url);
    return res.data;
  }
);

// export const generateExcel = createAsyncThunk(
//   "generateExcel",
//   async ({fromDate, toDate}) => {
//     const url = `excel/disbursed?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data.data;
//   }
// );

export const getRepaymentDetails = createAsyncThunk(
  "repaymentdetails",
  async (clientId) => {
    const url = `loans/repayments-by-borrower/${clientId}`
    const res = await DataService.get(url);
    return res.data;
  }
)

const initialState = {
  disburseList: [],
  disburseExcel:[],
  totalCount: "",
  excelData: '',
  excelDataSheet: [],
  isLoading: true,
  repLoading: true,
  repDetails: []
};

const disburseRequestSlice = createSlice({
  name: "disburseList",
  initialState,
  extraReducers: {
    [getLoanDisbursedList.pending]: (state) => {
      state.isLoading = true
    },
    [getLoanDisbursedList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.totalItmes;
      state.disburseList = action.payload.data;
      state.isLoading = false
    },
    [getLoanDisbursedList.rejected]: (state) => {
      state.isLoading = false
    },
    [getLoanDisbursedExcel.fulfilled]: (state, action) => {
      state.disburseExcel = action.payload.data;
    },
    // [generateExcel.fulfilled]: (state, action) => {
    //   state.excelDataSheet = action.payload.data
    // },
    [getRepaymentDetails.pending]: (state) => {
      state.repLoading = true
    },
    [getRepaymentDetails.fulfilled]: (state, action) => {
      state.repLoading = false
      state.repDetails = action.payload.data
    },
    [getRepaymentDetails.rejected]: (state) => {
      state.repLoading = false
    },
  },
});

const { reducer } = disburseRequestSlice;

export default reducer;
