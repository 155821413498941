import React from 'react'
import { Typography as MuiTypography } from '@material-ui/core'

export default function Typography(props) {
    const { text, className } = props
    return (
        <MuiTypography
            className={className}>
            {text}
        </MuiTypography>
    )
}

