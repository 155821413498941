import React, { useEffect } from 'react'
import CreditLineDataCard from './CreditLineDataCard'
import CreditLineChart from './CreditLineChart'
import './creditline.css'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveCreditLine, getActiveCreditLineData } from '../../../../slice/DashboardSlice/activeCreditLineSlice'
import { getcommonapidata } from '../../../../slice/commonslice'
import { Skeleton } from 'antd'
import { useState } from 'react'
const month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
const date = new Date()

const CreditLine = () => {
    const [value, setValue] = useState(month[date.getMonth()]);

    const {isLoading, activeCreditLineData} = useSelector(getActiveCreditLineData)
    const {commonFilterStates} = useSelector(getcommonapidata)
    const dispatch = useDispatch();
    const postValue = {
        state:commonFilterStates,
        month: `${value === 'Select Month' ? '' : value}`
    }
    useEffect(()=>{
        if(!activeCreditLineData){
            dispatch(getActiveCreditLine(postValue))
        }
    },[activeCreditLineData])
    useEffect(()=>{
        dispatch(getActiveCreditLine(postValue))
    },[commonFilterStates,value])
    return (
        <div className="card-wrap displayflexcol creditline-card">
            <h3 className="chart-card-title">Active Credit Line vs Credit Line in use</h3>
            {
                isLoading || !activeCreditLineData ?
                <Skeleton active/>
                :
                <CreditLineDataCard cardData={activeCreditLineData?.card_data} value={value} setValue={setValue}/>
            }
            {
                isLoading || !activeCreditLineData ?
                <Skeleton active/>
                :
                <CreditLineChart chartData={activeCreditLineData?.chart_data}/>
            }
        </div>
    )
}

export default CreditLine