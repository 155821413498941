const interestRatePerPeriodOptions = [
    { id: 2, name: "Per Month" },
    { id: 3, name: "Per Year" },
    { id: 4, name: "Whole Term" },
  ];
  const repaymentFrequencyType = [
    { id: 0, name: "Days" },
    { id: 1, name: "Weeks" },
    { id: 2, name: "Months" },
  ];
  const group = [
    { id: 1, name: "true" },
    { id: 2, name: "false" },
  ];
  const islinkedToFloating = [
    { id: 1, name: "true" },
    { id: 2, name: "false" },
  ];

  const LoanTermsOptions = {
    interestRatePerPeriodOptions,
    repaymentFrequencyType,
    group,
    islinkedToFloating
  }

  export default LoanTermsOptions;