import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import { Typography, Grid } from '@material-ui/core'
import { TiTick } from 'react-icons/ti'
import { ImCross } from 'react-icons/im'

const DistributorStatus = () => {
    const classes = makeStyles()
    return (
        <>
            <Typography className={classes.ebrBodyTabHeader}>Active Status Of Distributor</Typography>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Active for recent 2 Months</p>
                            <p className={classes.ebrBodyCellContent}>10 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Active for recent quarter</p>
                            <p className={classes.ebrBodyCellContent}>8 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Active for recent 6 Months</p>
                            <p className={classes.ebrBodyCellContent}>5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Active for recent 1 year</p>
                            <p className={classes.ebrBodyCellContent}>2 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.redCircle}>
                                <ImCross color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Not active in recent year</p>
                            <p className={classes.ebrBodyCellContent}>-1 Points</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default DistributorStatus