import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "105px",
    },
    boxShadow: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)"
   },
    typography: {
      color: "#963460",
      fontWeight: "bold",
      marginTop: "50px",
      marginLeft: "20px",
    },
    tableLeft: {
      maxWidth: "30%",
    },
    tableRight: {
      maxWidth: "30%",
      border: "2px solid #1A0045",
    },
    table: {
      border: "2px solid #1A0045",
    },
    loanDetails: {
      maxWidth: "100%",
      border: "2px solid #1A0045",
    },
    basicdetails: {
      width: "75vw",
      marginLeft: "90px",
      marginTop: "50px",
      display: "flex",
    },
    basicleft: {
      marginLeft: "50px",
      padding: "10px 10px",
      
    },
    basicRight: {
      marginLeft: "120px",
      padding: "10px 10px",
    },
    elements:{
      padding: "5px",
    },
    summaryheading: {
      marginTop: "20px",
      border: "2px solid ",
      backgroundColor: "#963460",
      color: "white",
      padding: "10px",
    },
    principalHeading:{
      display: "flex",
      flexDirection: "coloumn",
      border: "2px solid aliceblue",
      boxShadow: "0px 0px 32px 3px rgba(105, 108, 180, 0.16)",
    },
    princLeft: {
      border: "2px solid aliceblue",
      boxShadow: "0px 0px 32px 3px rgba(105, 108, 180, 0.16)",
      width: "50%",
    },
    princRight: {
      width: "50%",
      border: "2px solid aliceblue",
      boxShadow: "0px 0px 32px 3px rgba(105, 108, 180, 0.16)",
    },
  })
);
