import React from 'react'

const SelectYear = ({ year, setYear, years }) => {

    return (
        <div className='select-year-parent'>
            <p style={{ paddingTop: '15px' }}>Select Year: </p>
            <select
                id="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
            >
                {
                    years.map(item => <option key={item} value={item}>{item}</option>)
                }
            </select>
        </div>
    )
}

export default SelectYear