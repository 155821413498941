import React, { useState, useEffect } from "react";
import { TableBody, TableRow, TableCell, TextField, Typography, Tooltip } from "@material-ui/core";
import useTable from "../Sharedfeatures/UseTable";
import { useDispatch, useSelector } from "react-redux";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import PageHeader from "../Sharedfeatures/PageHeader";
import { TXT_CHARGES, TXT_HOME } from "../../utils/header";
import { SearchOutlined } from "@material-ui/icons";
import ProgressSpinner from '../common/ProgressSpinner';
import HeadCells from "../common/HeadCells";
import { CREATE_CHARGE } from "../../utils/url";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, ListItemText } from "@mui/material";
import Button from "../controls/Button";
import { PAGE_SIZE } from "../../utils/variable";
import { getChargesOverdue } from "../../slice/overduecharges";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import debounce from "lodash/debounce";
import { useMemo } from "react";

const OverdueCharges = () => {

    const sharedClasses = useSharedClasses();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { chargesList, totalCount, setLoading } = useSelector((store) => store.chargesOverdue);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(chargesList, HeadCells.chargesHead, totalCount);

    useEffect(() => {
        const data = {
            searchQry: search,
            pageNum: pageNo,
            totalCount
        };
        dispatch(getChargesOverdue(data));
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, totalCount]);

    const viewChargeDetails = (id) => {
        navigate(`/admin/chargedetails/${id}`)
    }

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_CHARGES}
                        subheader={`${TXT_HOME}/${TXT_CHARGES}`}
                    />
                    <article className={sharedClasses.searchAddParent}>
                        <div className={sharedClasses.lpsearchBar}>
                            <TextField
                                size="small"
                                className={sharedClasses.lpsearchTextBar}
                                //value={search}
                                onChange={debouncedResults}
                                id="standard-bare"
                                variant="outlined"
                                placeholder="Search Charge"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <SearchOutlined
                                                className={sharedClasses.searchIconColour}
                                            />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>

                        <div>
                            <ListItemText
                                primary={
                                    <Typography fontSize="20px">
                                        <Link to={`${CREATE_CHARGE}`}>
                                            <Button
                                                startIcon={<AddBoxIcon />}
                                                text="Create Charge"
                                                className={sharedClasses.addNewLoanButton}
                                            >
                                            </Button>
                                        </Link>
                                    </Typography>
                                }
                            />
                        </div>
                    </article>

                    <section className={sharedClasses.mainSection}>
                        <TblContainer>
                            <TblHead />
                            {setLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} align='center'>
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (chargesList.length !== 0) ?
                                            (chargesList &&
                                                chargesList.map((item, index) => {
                                                    serialNo++;
                                                    return (
                                                        <>
                                                            <TableRow key={item.id.toString()}>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {serialNo}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item && item.chargeAppliesTo && item.chargeAppliesTo.value}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.penalty == true ? 'True' : 'False'}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.active == true ? 'Active' : 'Inactive'}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>

                                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                        <IconButton onClick={() => viewChargeDetails(item.id)}>
                                                                            <RemoveRedEyeOutlinedIcon
                                                                                className={sharedClasses.actionIcon} />
                                                                        </IconButton>

                                                                        <Link to={`/admin/editcharge/${item.id}`}>
                                                                            <Tooltip title='Edit Charges'>
                                                                                <IconButton>
                                                                                    <EditIcon className={sharedClasses.actionIcon} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Link>
                                                                    </div>

                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={6} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                                                </TableRow>
                                            )
                                    }
                                </TableBody>
                            )}
                        </TblContainer>
                        {showPageNtn ? <TblPagination /> : ""}
                    </section>

                </div>
            </div>
        </>
    )
}

export default OverdueCharges