import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanSanctionList = createAsyncThunk(
  "loanSanctionedList",
  async ({ pageNum, searchQry, fromDate, toDate }) => {
    const url = `/loans/sanctioned-list?page=${pageNum}&limit=10&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

//download loan sanctioned excel
export const getLoanSanctionedExcel = createAsyncThunk(
  "getLoanSanctionedexcel",
  async (data) => {
    const { searchQry,fromDate, toDate, totalCount } = data
    const url = `/loans/sanctioned-list?page=1&limit=${totalCount}&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`
    const res = await DataService.get(url);
    return res.data;
  }
);

//View of SanctionList
export const getLoanAppStatement = createAsyncThunk(
  "loanAppstatement",
  async (data, thunkAPI) => {
      try {
          const url = `/loans/loan-applications/${data}`;
          const res = await DataService.get(url);
          return res.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
      }
  }
)

export const getLoanTrancheList = createAsyncThunk(
  "loanTrancheList",
  async (data) => {
    const { loanapproval_id, query } = data;
    const url = loanapproval_id && query ? `/loans/tranches/${loanapproval_id}${query}` : `/loans/tranches/${loanapproval_id}`
    const res = await DataService.get(url, loanapproval_id);
    return res.data;
  }
);

// export const generateExcel = createAsyncThunk(
//   "generateExcel",
//   async ({ fromDate, toDate }) => {
//     const url = `excel/sanctioned?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data.data;
//   }
// );
export const getLoanStatement = createAsyncThunk(
  "loanStatement",
  async (clientLoanId) => {
    // const url = `/loans/statement/${clientLoanId}`;
    const url = `/loans/single-statement/${clientLoanId}`;
    const res = await DataService.get(url);
    return res.data;
  }
);
export const getLoanAccountDetails = createAsyncThunk(
  "loanAccountDetails",
  async (clientLoanId) => {
    const url = `/loans/statement/${clientLoanId}`;
    const res = await DataService.get(url);
    return res;
  }
);

export const reset_message = createAsyncThunk(
  "reset_message",
  async () => {
    return;
  }
)

const initialState = {
  loanSanctionList: [],
  loanSanctionedExcel: [],
  loanSanctionedView:[],
  loanTrancheList: [],
  loanStatement: [],
  totalCount: "",
  excelDataSheet: '',
  loanAccountDetails: '',
  loanBasicDetails: '',
  setLoading: true,
};

const loanSanctionSlice = createSlice({
  name: "loanSanction",
  initialState,
  extraReducers: {
    [getLoanSanctionList.pending]: (state) => {
      state.setLoading = true;
    },
    [getLoanSanctionList.fulfilled]: (state, action) => {
      // console.log("act", action)
      state.totalCount = action.payload.totalItmes;
      state.loanSanctionList = action.payload.data;
      state.setLoading = false;
    },
    [getLoanSanctionList.rejected]: (state) => {
      state.setLoading = false;
    },
    [getLoanSanctionedExcel.fulfilled]: (state, action) => {
      state.loanSanctionedExcel = action.payload.data;
    },
    [getLoanTrancheList.pending]: (state, action) => {
      state.setLoading = true;
    },
    [getLoanTrancheList.fulfilled]: (state, action) => {
      state.loanTrancheList = action.payload.data;
      state.setLoading = false;
    },
    [getLoanStatement.fulfilled]: (state, action) => {
      state.loanStatement = action.payload.data;
    },
    [getLoanAccountDetails.pending]: (state, action) => {
      state.setLoading = true;
    },
    [getLoanAccountDetails.fulfilled]: (state, action) => {
      state.loanAccountDetails = action.payload.data.data;
      state.loanBasicDetails = action.payload.data.basic;
      state.setLoading = false;
    },
    [getLoanAccountDetails.rejected]: (state, action) => {
      state.setLoading = false;
    },
    [reset_message.fulfilled]: (state, action) => {
      state.loanTrancheList = [];
      state.loanStatement = [];
    },
    [getLoanAppStatement.fulfilled]: (state, action) => {
      // console.log("SanctionACT", action)
      state.loanSanctionedView = action.payload.data;
    },
    reducers: {
      resetStateValues: (state) => {
          state.loanSanctionedView = {};
      },
  }
    // [generateExcel.fulfilled]: (state, action) => {
    //   state.excelDataSheet = action.payload.data
    // }
  },
});

export const { resetStateValues } = loanSanctionSlice.actions
export default loanSanctionSlice.reducer
