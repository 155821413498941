import React from 'react'
import styles from "./loanviewstyles";
import { useSelector } from "react-redux";
import { Typography, Grid, ListItemText } from "@material-ui/core";

const ChargesView = () => {

    const myStyles = styles();
    const { loanDetails } = useSelector(store => store.loan);

    return (
        <>
            <Grid className={myStyles.viewHeading}>
                <h1>Charges</h1>
                <hr />
            </Grid>
            <Grid container style={{ marginTop: "25px" }}>
                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={6}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Name:
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].name}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={12}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Status:
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].active === true ? "Active" : "Inactive"}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={12}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Penalty:
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].penalty === true ? "True" : "False"}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "25px" }}>
                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={12}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Amount:
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].amount}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={12}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Charge Time Type
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].chargeTimeType && loanDetails.charges[0].chargeTimeType.value}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                        <Grid item md={12}>
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listHeading}>
                                        Charge Applies To:
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={styles.employeeItems}
                                primary={
                                    <Typography className={myStyles.listValues}>
                                        {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].chargeAppliesTo && loanDetails.charges[0].chargeAppliesTo.value}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{ marginTop: "25px" }}>
                    <Grid item xs={4}>
                        <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                            <Grid item md={12}>
                                <ListItemText
                                    className={styles.employeeItems}
                                    primary={
                                        <Typography className={myStyles.listHeading}>
                                            Charge Calculation Type:
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    className={styles.employeeItems}
                                    primary={
                                        <Typography className={myStyles.listValues}>
                                            {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].chargeCalculationType && loanDetails.charges[0].chargeCalculationType.value}
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                            <Grid item md={12}>
                                <ListItemText
                                    className={styles.employeeItems}
                                    primary={
                                        <Typography className={myStyles.listHeading}>
                                            Charge Payment Mode
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    className={styles.employeeItems}
                                    primary={
                                        <Typography className={myStyles.listValues}>
                                            {loanDetails && loanDetails.charges && loanDetails.charges[0] && loanDetails.charges[0].chargePaymentMode && loanDetails.charges[0].chargePaymentMode.value}
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: "25px" }}></Grid>
            </Grid>
        </>
    )
}

export default ChargesView