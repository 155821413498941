import React from 'react'
import { BACK_BUTTON, TXT_ADD_CHARGES, TXT_EDIT_CHARGES, TXT_CHARGE, TXT_HOME } from '../../utils/header';
import { CHARGE_LIST } from '../../utils/url';
import PageHeader from '../Sharedfeatures/PageHeader';
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { FormControl, InputLabel, Paper, Select, Stack, FormHelperText, Snackbar } from "@mui/material";
import { Grid, TextField, Checkbox, MenuItem } from '@material-ui/core';
import Button from '../controls/Button';
// import { chargeApplies, chargeCalculation, chargeCalculation_savings, chargeCalculation_client, chargeCalculation_shares, chargePaymentBy, chargeTimeTypes,chargeTimeTypes_savings,chargeTimeTypes_client,chargeTimeTypes_shares,currencyTypes } from './DropDownOptions';
import { useForm } from '../Sharedfeatures/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { createCharge, createChargeDropdowns, resetSuccessState, getChargeDetails, editCharge } from '../../slice/overduecharges';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../common/useSnackbar';
import { star } from "../../utils/index";
import LoadingButton from '@mui/lab/LoadingButton';

const initialFValues = {
    active: false,
    amount: '',
    chargeAppliesTo: '1',
    chargeCalculationType: '',
    chargePaymentMode: '',
    chargeTimeType: '',
    currencyCode: '',
    feeFrequency: '0',
    feeInterval: '1',
    locale: 'en',
    monthDayFormat: 'dd MMM',
    name: '',
}


// const savings_data_format = {
//     active: true,
//     amount: "12345",
//     chargeAppliesTo: '2',
//     chargeCalculationType: '1',
//     chargeTimeType: '2',
//     currencyCode: "INR",
//     locale: "en",
//     monthDayFormat: "dd MMM",
//     name: "sam test 1",
//     penalty: true,
// }

// const client_data_format = {
//     active: true,
//     amount: "78523",
//     chargeAppliesTo: 3,
//     chargeCalculationType: 1,
//     chargeTimeType: 2,
//     currencyCode: "INR",
//     locale: "en",
//     monthDayFormat: "dd MMM",
//     name: "client test 1",
//     penalty: true
// }

// const shares_data_format = {
//     active: true,
//     amount: "12234",
//     chargeAppliesTo: 4,
//     chargeCalculationType: 1,
//     chargeTimeType: 13,
//     currencyCode: "INR",
//     locale: "en",
//     monthDayFormat: "dd MMM",
//     name: "shares test 1"
// }

const fieldsWidth = "350px";

const CreateCharge = () => {

    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const snackBar = useSnackbar();
    const { id } = useParams();

    const header = id ? TXT_EDIT_CHARGES : TXT_ADD_CHARGES;

    const {
        message,
        success,
        errorMessage,
        loading,
        setLoading,
        chargesAppliesTo,
        currency,
        chargeTimeTypes_loan,
        chargeTimeTypes_savings,
        chargeTimeTypes_client,
        chargeTimeTypes_shares,
        chargeCalculation_loan,
        chargeCalculation_savings,
        chargeCalculation_client,
        chargeCalculation_shares,
        chargePaymentBy,
        chargeDetails
    } = useSelector(store => store.chargesOverdue)

    useEffect(() => {
        dispatch(createChargeDropdowns())
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getChargeDetails(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (id) {
            setValues({
                ...values,
                active: chargeDetails.active,
                amount: chargeDetails.amount,
                chargeAppliesTo: chargeDetails?.chargeAppliesTo?.id,
                chargeCalculationType: chargeDetails?.chargeCalculationType?.id,
                chargePaymentMode: chargeDetails?.chargePaymentMode?.id,
                chargeTimeType: chargeDetails?.chargeTimeType?.id,
                currencyCode: chargeDetails?.currency?.code,
                name: chargeDetails.name,
                id
            })
        }
    }, [chargeDetails, id])


    //   useEffect(() => {
    //     if (email) setErrors({ ...errors, email: email });
    //     if (mobile) setErrors({ ...errors, mobile: mobile });
    //     if (employeeDetails) {
    //       setValues({
    //         ...values,
    //         name: employeeDetails && employeeDetails.name,
    //         email: employeeDetails && employeeDetails.email,
    //         mobile: employeeDetails && employeeDetails.mobile,
    //         region:
    //           employeeDetails &&
    //           employeeDetails.region &&
    //           employeeDetails.region.id,
    //         userid: employeeDetails && employeeDetails.userid,
    //       });
    //       dispatch(resetstate());
    //     }
    //   }, [email, mobile, employeeDetails]);

    useEffect(() => {
        if (message && success) {
            //EDIT CHARGE
            snackBar.openSnackbar(message);
            setTimeout(() => {
                dispatch(resetSuccessState())
                resetForm();
                navigate(`${CHARGE_LIST}`)
            }, 2000);
        }

        if (message && success) {
            //CREATE CHARGE
            snackBar.openSnackbar(message);
            setTimeout(() => {
                dispatch(resetSuccessState())
                resetForm();
                navigate(`${CHARGE_LIST}`)
            }, 2000);
        }
    }, [message, success, dispatch])


    useEffect(() => {
        if (!success && errorMessage) {
            setTimeout(() => {
                dispatch(resetSuccessState())
            }, 4000);
        }
    }, [errorMessage, id, success, dispatch])


    const validate = (fieldValues = values) => {

        let temp = { ...errors };

        if ("chargeAppliesTo" in fieldValues)
            temp.chargeAppliesTo = fieldValues.chargeAppliesTo.length !== 0 ? "" : "This field is required.";
        if ("name" in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required.";
        if ("currencyCode" in fieldValues)
            temp.currencyCode = fieldValues.currencyCode.length !== 0 ? "" : "This field is required.";
        if ("chargeTimeType" in fieldValues)
            temp.chargeTimeType = fieldValues.chargeTimeType.length !== 0 ? "" : "This field is required.";
        if ("chargeCalculationType" in fieldValues)
            temp.chargeCalculationType = fieldValues.chargeCalculationType.length !== 0 ? "" : "This field is required.";
        if ("chargePaymentMode" in fieldValues)
            temp.chargePaymentMode = (values.chargeAppliesTo !== 1) ? "" : (fieldValues.chargePaymentMode.length !== 0) ? "" : "This field is required.";
        if ("amount" in fieldValues)
            temp.amount = (fieldValues.amount > 0) ? "" : "Amount must be greater than zero";

        setErrors({ ...temp });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleCheckBox,
        resetForm
    } = useForm(initialFValues, true, validate)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            if (!id) {
                if (values.chargeAppliesTo === 1) {
                    dispatch(createCharge(values))
                }
                else if (values.chargeAppliesTo === 2 || values.chargeAppliesTo === 3) {
                    const data = { ...values }
                    delete data.chargePaymentMode;
                    delete data.feeFrequency;
                    delete data.feeInterval;
                    dispatch(createCharge(data))
                }
                else {
                    const data = { ...values }
                    delete data.chargePaymentMode;
                    delete data.feeFrequency;
                    delete data.feeInterval;
                    delete data.penalty;
                    dispatch(createCharge(data))
                }
            } else {
                if (values.chargeAppliesTo === 1) {
                    dispatch(editCharge(values))
                }
                else if (values.chargeAppliesTo === 2 || values.chargeAppliesTo === 3) {
                    const data = { ...values }
                    delete data.chargePaymentMode;
                    delete data.feeFrequency;
                    delete data.feeInterval;
                    dispatch(editCharge(data))
                }
                else {
                    const data = { ...values }
                    delete data.chargePaymentMode;
                    delete data.feeFrequency;
                    delete data.feeInterval;
                    delete data.penalty;
                    dispatch(editCharge(data))
                }
            }
        }
    }
    const NUMERIC_DASH_REGEX = /^[e+-.]+$/;
    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={header}
                        subheader={`${TXT_HOME}/${TXT_CHARGE}`}
                        icon={BACK_BUTTON}
                        link={CHARGE_LIST}
                    />

                    <section className={sharedClasses.createChargeMainSection}>
                        <Paper className={sharedClasses.createChargePaper}>

                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Grid container spacing={4} alignItems='center'>
                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Charges applies to <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl
                                            style={{ width: '250px' }}
                                            {...(errors.chargeAppliesTo && { error: true })}>
                                            <InputLabel>Type</InputLabel>
                                            <Select
                                                name="chargeAppliesTo"
                                                label="Type"
                                                value={values.chargeAppliesTo}
                                                onChange={handleInputChange}
                                            >
                                                {
                                                    chargesAppliesTo.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                errors.chargeAppliesTo && <FormHelperText>{errors.chargeAppliesTo}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Name <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField
                                            name="name"
                                            label="Name"
                                            value={values.name}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            style={{ width: '250px' }}
                                            // style={{ width: `${fieldsWidth}` }}

                                            {...(errors.name && { error: true, helperText: errors.name })}
                                        />
                                    </Grid>

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Currency <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl style={{ width: '250px' }} {...(errors.currencyCode && { error: true })}>
                                            <InputLabel>Currency</InputLabel>
                                            <Select
                                                name="currencyCode"
                                                label="Currency"
                                                value={values.currencyCode}
                                                onChange={handleInputChange}
                                            >
                                                {
                                                    currency.map(item => <MenuItem key={item.code} value={item.code}>{item.displayLabel}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                errors.currencyCode && <FormHelperText>{errors.currencyCode}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Charge time type <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl style={{ width: '250px' }} {...(errors.chargeTimeType && { error: true })}>
                                            <InputLabel>Charge Type</InputLabel>
                                            <Select
                                                name="chargeTimeType"
                                                label="Charge Type"
                                                value={values.chargeTimeType}
                                                onChange={handleInputChange}
                                            >
                                                {
                                                    (values.chargeAppliesTo === 1) ? chargeTimeTypes_loan.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                        (values.chargeAppliesTo === 2) ? chargeTimeTypes_savings.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                            (values.chargeAppliesTo === 3) ? chargeTimeTypes_client.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                                chargeTimeTypes_shares.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                errors.chargeTimeType && <FormHelperText>{errors.chargeTimeType}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Charge calculation <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl style={{ width: '250px' }} {...(errors.chargeCalculationType && { error: true })}>
                                            <InputLabel>Charge Calculation</InputLabel>
                                            <Select
                                                name="chargeCalculationType"
                                                label="Charge Calculation"
                                                value={values.chargeCalculationType}
                                                onChange={handleInputChange}
                                            >
                                                {
                                                    (values.chargeAppliesTo === 1) ? chargeCalculation_loan.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                        (values.chargeAppliesTo === 2) ? chargeCalculation_savings.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                            (values.chargeAppliesTo === 3) ? chargeCalculation_client.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>) :
                                                                chargeCalculation_shares.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                errors.chargeCalculationType && <FormHelperText>{errors.chargeCalculationType}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>

                                    {
                                        (values.chargeAppliesTo === 1) &&
                                        <>
                                            <Grid container justifyContent="flex-end" item md={3}>
                                                <h3>
                                                    Charge payment by <span style={{ color: `${star}` }}>*</span>
                                                </h3>
                                            </Grid>
                                            <Grid item md={9}>
                                                <FormControl style={{ width: '250px' }} {...(errors.chargePaymentMode && { error: true })}>
                                                    <InputLabel>Charge Payment</InputLabel>
                                                    <Select
                                                        name="chargePaymentMode"
                                                        label="Charge Payment"
                                                        value={values.chargePaymentMode}
                                                        onChange={handleInputChange}
                                                    >
                                                        {
                                                            chargePaymentBy.map(item => <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                                                        }
                                                    </Select>
                                                    {
                                                        errors.chargePaymentMode && <FormHelperText>{errors.chargePaymentMode}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </>
                                    }

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Amount <span style={{ color: `${star}` }}>*</span>
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField
                                            name="amount"
                                            label="Amount"
                                            value={values.amount}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            type="number"
                                            style={{ width: '250px' }}
                                            {...(errors.amount && { error: true, helperText: errors.amount })}
                                            onKeyDown={(event) => {
                                                if (NUMERIC_DASH_REGEX.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}

                                        />
                                    </Grid>

                                    <Grid container justifyContent="flex-end" item md={3}>
                                        <h3>
                                            Active
                                        </h3>
                                    </Grid>
                                    <Grid item md={9}>
                                        <Checkbox
                                            color='primary'
                                            name="active"
                                            checked={values.active}
                                            onChange={handleCheckBox}
                                        />
                                    </Grid>

                                    {
                                        (values.chargeTimeType === 9) &&
                                        <>
                                            <Grid container justifyContent="flex-end" item md={3}>
                                                <h3>
                                                    Is penalty
                                                </h3>
                                            </Grid>
                                            <Grid item md={9}>
                                                <Checkbox
                                                    color='primary'
                                                    name="penalty"
                                                    checked={true}
                                                />
                                            </Grid>
                                        </>
                                    }

                                    {
                                        errorMessage && <><Grid item md={3} /><Grid item md={9}><h5 style={{ color: '#ff0000', fontSize: '16px' }}>{errorMessage}</h5></Grid></>
                                    }

                                    <Grid item md={3} />
                                    {/* For spacing purpose. Dont remove this block. */}

                                    <Grid item md={9}>
                                        <Stack spacing={2} direction="row">
                                            <LoadingButton
                                                loading={setLoading}
                                                loadingIndicator="Creating…"
                                                type="submit"
                                                variant="contained"
                                                style={{ minWidth: '100px' }}
                                            >
                                                {
                                                    !setLoading ? 'Submit' : ''
                                                }
                                            </LoadingButton>
                                            <Button
                                                variant="outlined"
                                                text="Reset"
                                                onClick={resetForm}
                                            />
                                        </Stack>
                                    </Grid>

                                </Grid>
                            </form>

                            <div>
                                <Snackbar {...snackBar} />
                            </div>
                        </Paper>
                    </section>
                </div>
            </div>
        </>
    )
}

export default CreateCharge