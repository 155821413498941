import React from "react";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GridView = ({firstColName, firstData, secColName, secData , thirdColName, thirdData }) => {

  const { setLoading } = useSelector((store) => store.loanSanction)

  return (
    <>
      <Grid container>
          <Grid item xs={2}>
            <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{firstColName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">{setLoading ? <Skeleton /> : firstData}</Typography>
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={3}>
            <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{secColName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{setLoading ? <Skeleton /> : secData}</Typography>
          </Grid>
        </Grid>
    </>
  );
};

export default GridView;
