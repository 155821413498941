import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getTotalRevenue = createAsyncThunk('getTotalRevenue', async ()=>{
    try{
        const url = `/console/total-revenue`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const getTotalRevenueByMonth = createAsyncThunk('getTotalRevenueByMonth', async (monthVal)=>{
    try{
        const url = `/console/total-revenue-month?month=${monthVal}`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const getInterest = createAsyncThunk('getInterest', async ()=>{
    try{
        const url = `/console/total-interest`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const getInterestByMonth = createAsyncThunk('getInterestByMonth', async (monthVal)=>{
    try{
        const url = `/console/total-interest-month?month=${monthVal}`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    isLoading : true,
    isLoadingRevenue : true,
    totalRevenueData : [],
    totalRevenueDataByMonth : '',
    interestData:'',
    interestDataByMonth:''
}

const totalRevenueSlice = createSlice({
    name : 'totalrevenueslice',
    initialState,
    extraReducers:{
        [getTotalRevenue.pending]: (state) => {
            return {...state, isLoadingRevenue:true}
        },
        [getTotalRevenue.fulfilled]:(state,{payload})=>{
            return {...state, totalRevenueData:payload?.data?.data, isLoadingRevenue:false}
        },
        [getTotalRevenueByMonth.pending]: (state) => {
            return {...state, isLoadingRevenue:true}
        },
        [getTotalRevenueByMonth.fulfilled]:(state,{payload})=>{
            return {...state, totalRevenueDataByMonth:payload?.data, isLoadingRevenue:false}
        },
        [getInterest.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getInterest.fulfilled]:(state,{payload})=>{
            return {...state, interestData:payload?.data, isLoading:false}
        },
        [getInterestByMonth.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getInterestByMonth.fulfilled]:(state,{payload})=>{
            return {...state, interestDataByMonth:payload?.data, isLoading:false}
        },
    }
})
export const getTotalRevenueData = (state) => state.totalrevenueapi;
export default totalRevenueSlice.reducer