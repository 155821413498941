import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const DialogueBox = ({ open, onClose, text, handlefnc, item, status }) => {

  return (
    <>
      <Dialog
        open={open}
        // onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {text} the verification?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Disagree</Button>
          {/* {
            text === 'accept' ? (
              <Button onClick={() => {
                handlefnc(1, item._id, "verified")
                onClose()
              }}>Agree</Button>
            ) : (
              <Button onClick={() => {
                handlefnc(1, item._id, "rejected")
                onClose()
              }}>Agree</Button>
            )
          } */}
          <Button onClick={text === 'accept' ?
            (() => {
              handlefnc(status, item._id, "verified", "", "")
              onClose()
            }) :
            (() => {
              handlefnc(status, item._id, "rejected")
              onClose()
            })
          }>Agree</Button>
          {/* <Button  autoFocus>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogueBox;

