import React, { useEffect, useState } from "react";
import { IconButton, Icon, ListItemText } from "@mui/material";
import {
  Typography,
  TextField,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Button as MuiButton } from '@material-ui/core'
import { TXT_LOAN_PRODUCTS, TXT_HOME } from "../../utils/header";
import { SearchOutlined } from "@material-ui/icons";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { Link } from "react-router-dom";
import { retrieveLoanlist, getLoanProductsExcel, reset_message } from "../../slice/loan";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import { ADD_LOAN } from "../../utils/url";
import { useMediaQuery, useTheme } from "@material-ui/core";
import PageHeader from "../Sharedfeatures/PageHeader";
import Button from "../controls/Button";
import useExcel from "../common/useExcel";
import { PAGE_SIZE } from "../../utils/variable";
import DownloadIcon from "@mui/icons-material/Download";
import debounce from "lodash/debounce";
import { useMemo } from "react";


const ProductList = () => {

    const dispatch = useDispatch();
    const { loanList, totalCount, setLoading } = useSelector((state) => state.loan);
    const excelData = useExcel();

    const [search, setSearch] = useState("");
    const [showPageNtn, setShowPageNtn] = useState(false);
    const theme = useTheme();
    const showText = useMediaQuery(theme.breakpoints.up("sm"));
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(
        loanList,
        HeadCells.loanHead,
        totalCount
    );

    useEffect(() => {
        const data = {
            searchQry: search,
            pageNum: pageNo,        
        };
        dispatch(retrieveLoanlist(data));
        dispatch(reset_message());
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, ]);

    const sharedClasses = useSharedClasses();

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQry: search,
            pageNum: pageNo,
            totalCount,
        };
        dispatch(getLoanProductsExcel(data))
        .then(res=>{
            if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "Loan Product" : item.loanName,
                    "Short Name" : item.shortName,
                    "Max Principal" : item.maxPrincipal,
                    "Status" : item.status
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'Loan Product Sheet', 'loanproductlist.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    return (
        <>
        <div className={sharedClasses.mainDiv}>
            {showText && (
            <>
                <div className={sharedClasses.pageContent}>
                <PageHeader
                    header={TXT_LOAN_PRODUCTS}
                    subheader={`${TXT_HOME}/${TXT_LOAN_PRODUCTS}`}
                />
                <article className={sharedClasses.searchAddParent}>
                    <div className={sharedClasses.lpsearchBar}>
                    <TextField
                        size="small"
                        className={sharedClasses.lpsearchTextBar}
                        //value={search}
                        onChange={debouncedResults}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                        endAdornment: (
                            <IconButton>
                            <SearchOutlined
                                className={sharedClasses.searchIconColour}
                            />
                            </IconButton>
                        ),
                        }}
                    />
                    </div>

                    <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                    <MuiButton
                        variant="outlined"
                        color="primary"
                        className={sharedClasses.exceldownload}
                        onClick={downloadExcelsheet}
                        disabled = {totalCount === 0 ? true : false}
                        endIcon={<DownloadIcon />}
                    >
                        Download Report
                    </MuiButton>
                    
                    <ListItemText
                        primary={
                        <Typography fontSize="20px">
                            <Link to={`${ADD_LOAN}`}>

                            <Button
                                startIcon={<AddBoxIcon />}
                                text="Add New Loan"
                                className={sharedClasses.addNewLoanButton}
                            />
                            </Link>
                        </Typography>
                        }
                    />
                    </div>
                </article>

                <section className={sharedClasses.mainSection}>
                    <TblContainer>
                    <TblHead />
                    {setLoading ? (
                        <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                            <ProgressSpinner />
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                        {
                            (loanList.length !== 0) ? (
                            loanList &&
                            loanList.map((item, key) => {
                                serialNo++;
                                return (
                                <TableRow
                                    key={key.toString()}
                                    sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell className={sharedClasses.tableCell}>
                                    {serialNo}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.loanName}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {" "}
                                    {item.shortName}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.maxPrincipal}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.status === "active"
                                        ? "Active"
                                        : "In-Active"}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    <IconButton>
                                        <Icon>
                                        <Link
                                            to={`/admin/viewdetails/${item.resourceId}`}
                                        >
                                            <RemoveRedEyeOutlinedIcon />
                                        </Link>                                     
                                        </Icon>
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                                );
                            })
                            ) : (
                            <TableRow>
                                <TableCell colSpan={6} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                            </TableRow>
                            )
                        }
                        </TableBody>
                    )}
                    </TblContainer>
                    {showPageNtn ? <TblPagination /> : ""}
                </section>
                </div>
            </>
            )}
        </div>
        </>
    );
};

export default ProductList;
