import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./FinalDashboard.css";
import GraphHeader from "./GraphHeader";
import { PREAPPROVED_HEADER } from "../../utils/graphHeadings";
import useYAxisFormatter from "../Sharedfeatures/useYAxisFormatter";
import { useSelector, useDispatch } from "react-redux";
import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import { getPreApprovedData } from "../../slice/finaldashboard";
import ProgressSpinner from "../common/ProgressSpinner";

const PreApprovedLoanChart = ({year}) => {
  const { dataFormater } = useYAxisFormatter();
  const { CustomTooltip } = useCustomTooltip();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPreApprovedData(year))
  }, [year, dispatch])


  const { preApprovedLoanOffers, preApprovedLoanOffers_totalVolume, preLoader } = useSelector((store) => store.finaldashboard);

  const total_Volume = preApprovedLoanOffers_totalVolume

  let data = [];
  preApprovedLoanOffers.map((obj) => {
    let cdata = {
      name: obj.month.substring(0, 3),
      value: Number(obj.amount),
      month: obj.month + "" + obj.value,
    };
    data.push(cdata);
  });


  return (
    // <div className="loan-offers-two-by-two-card">
    //   <GraphHeader
    //     header={PREAPPROVED_HEADER}
    //     subheader={Number(
    //       Number(total_Volume).toFixed(2)
    //     ).toLocaleString()}
    //   />
    //   {preLoader ? (
    //     <div className="dash_spinner_parent">
    //       <ProgressSpinner />
    //     </div>
    //   ) : (
    //   <ResponsiveContainer width="90%" height="65%">
    //     <BarChart
    //       width={550}
    //       height={180}
    //       data={data}
    //       margin={{
    //         left: 20,
    //       }}
    //     >
    //       <XAxis dataKey="name" axisLine={false} />
    //       <YAxis axisLine={false} tickFormatter={dataFormater} />
    //       <Tooltip cursor={false} content={<CustomTooltip />} />
    //       <CartesianGrid
    //         strokeDasharray="3 3"
    //         horizontal={true}
    //         vertical={false}
    //       />
    //       <Bar
    //         dataKey="value"
    //         fill="#FF6978"
    //         radius={8}
    //         barSize={10}
    //         barCategoryGap={10}
    //         background={{ fill: "#eee" }}
    //       />
    //     </BarChart>
    //   </ResponsiveContainer>)}
    // </div>

    <div className="second-row-card">
      <GraphHeader header={PREAPPROVED_HEADER} subheader={Number(Number(total_Volume).toFixed(2)).toLocaleString()} />
      {
        preLoader && (
          <div className="dash_spinner_parent">
            <ProgressSpinner />
          </div>
        )
      }
      <ResponsiveContainer width="90%" height="65%">
        <BarChart
          width={550}
          height={180}
          data={data}
          margin={{
            left: 20,
          }}
        >
          <XAxis dataKey="name" axisLine={false} />
          <YAxis axisLine={false} tickFormatter={dataFormater} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          {
            !preLoader && <Bar dataKey="value" fill="#FF6978" radius={8} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
          }
        </BarChart>
      </ResponsiveContainer>
    </div>

  );
};

export default PreApprovedLoanChart;
