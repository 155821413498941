import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatRupees } from '../../../../utils/formatRupees';
import MonthSelectBox from '../../Common/MonthSelectBox';

const LoanClosuresDataCard = ({cardData,value,setValue}) => {
    return (
        <div className="data-card-wrap">
            <MonthSelectBox value={value} setValue={setValue}/>
            <div className="card loan-amnt">
                <div className="displayflex">
                    <h4 className="card-title">{cardData?.mainTitle} Loan Closure Amount</h4>
                    <h3 className="card-title"><CurrencyRupeeIcon className='rupee'/>{formatRupees(cardData?.amount)}</h3>
                </div>
            </div>
            <div className="card closure-count">
                <div className="displayflex">
                    <h4 className="card-title">{cardData?.mainTitle} Loan Closure Count</h4>
                    <h3 className="card-title closurecount">{cardData?.count}</h3>
                </div>
            </div>
        </div>
    )
}

export default LoanClosuresDataCard