import React, { useState } from 'react'
import { makeStyles, Paper } from '@material-ui/core'

export function useForm(initialValues, validateOnChange = false, validate) {

    const moment = require('moment')
    const [values, setValues] = useState(initialValues);
    const [startDate, setStartDate] = useState(moment(new Date()).format("DD MMMM YYYY"));
    const [errors, setErrors] = useState({});
    const [anchor, setAnchor] = useState();

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if(name === 'chargeTimeType' && value === 9 ){
            setValues({...values,[name]: value, penalty : true})
        }else if(name === 'chargeTimeType' && value !== 9 ){
            setValues({...values,[name]: value, penalty : false})
        }
        if (validateOnChange)
            validate({ [name]: value })
    }

    const handleRegionChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleClick = (event) => {
        setAnchor(event.current.target);
    }

    const handleCheckBox = (e) => {
        const { name, checked } = e.target
        setValues({
            ...values,
            [name]: checked
        })
    }

    const resetForm = () => {
        setValues(initialValues);
        setErrors({})
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        anchor,
        setAnchor,
        handleInputChange,
        handleRegionChange,
        handleClick,
        handleCheckBox,
        resetForm,
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginTop: "100px",
        borderRadius: "20px",
    },
}))

export function Form(props) {
    const classes = useStyles()
    const { children, ...other } = props;
    return (
        <Paper className={classes.pageContent}>
            <form className={classes.root} autoComplete="off" {...other}>
                {props.children}
            </form>
        </Paper>
    )
}

