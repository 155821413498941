import React, {useState, useEffect} from 'react'
import './FinalDashboard.css'
import VectorImage from './VectorImage'
import { createTheme, responsiveFontSizes, MuiThemeProvider, Typography, Box} from "@material-ui/core"

let theme = createTheme();
theme = responsiveFontSizes(theme);

const DashboardGreeting = () => {
    const [greetings, setGreetings] = useState('')

    useEffect(() => {
        var today = new Date()
        var curHr = today.getHours()
        if (curHr < 12) {
            setGreetings('Good Morning')
        }
        else if ( curHr >= 12 && curHr <= 17) {
            setGreetings('Good Afternoon')
        }
        else {
            setGreetings('Good Evening')
        }
    }, [])

    return (
        <MuiThemeProvider theme={theme}>
        <Box>
            <Box className='dash-greeting'>
                <Box className='dash-greeting-inline'>
                    <h3 className='greet-header'>{greetings}! Have a great Day!</h3>
                    <h5 className='greet-body'>Here is what's happening in your business today.</h5>
                </Box>
                <Box>
                    <VectorImage />
                </Box>
            </Box>
        </Box>
        </ MuiThemeProvider>
    )
}

export default DashboardGreeting