import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getTrancheList = createAsyncThunk(
    "trancheList",
    async ({ pageNum, searchQry }) => {
        const url = `loans/tranches-list?page=${pageNum}&limit=10&searchqry=${searchQry}`
        const res = await DataService.get(url);
        return res.data;
    }
);

export const disburseLoan = createAsyncThunk(
    "disburseLoan",
    async (state, thunkAPI) => {
        try {
            const url = 'loans/disburse'
            const { ...data } = state
            const res = await DataService.post(url, data);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const reset_message = createAsyncThunk(
    "reset_message",
    async () => {
        return;
    }
)


const initialState = {
    trancheList: [],
    message: '',
    success: '',
    isLoading: true
};

const trancheRequestSlice = createSlice({
    name: "trancheList",
    initialState,
    extraReducers: {
        [getTrancheList.pending]: (state) => {
            state.isLoading = true;
        },
        [getTrancheList.fulfilled]: (state, action) => {
            state.trancheList = action.payload.data
            state.totalCount = action.payload.totalItmes
            state.isLoading = false;
        },
        [getTrancheList.rejected]: (state) => {
            state.isLoading = false;
        },
        [disburseLoan.fulfilled]: (state, action) => {
            state.message = "Disbursed successfully"
            state.success = true
            state.trancheList = state.trancheList.filter(item =>
                item.id !== action.payload.trancheid)
        },
        [disburseLoan.rejected]: (state, action) => {
            state.message = action.payload.message
            state.success = action.payload.success
        },
        [reset_message.fulfilled]: (state, action) => {
            state.message = '';
            state.success = '';
        }
    },
});

const { reducer } = trancheRequestSlice;

export default reducer;
