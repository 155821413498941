import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import usergroup from '../../../../assets/images/user-group.svg'
import { formatRupees } from '../../../../utils/formatRupees';
import MonthSelectBox from '../../Common/MonthSelectBox';

const PreapprovedDataCard = ({cardData,value,setValue}) => {
    return (
        <div className="data-card-wrap">
            <MonthSelectBox value={value} setValue={setValue}/>
            <div className="card loan-amnt">
                <div className="displayflex">
                    <h4 className="card-title">{cardData?.mainTitle} Pre-approved Loan Amount</h4>
                    <h3 className="card-title"><CurrencyRupeeIcon className='rupee'/>{formatRupees(cardData?.amount)}</h3>
                </div>
            </div>
            <div className="card users">
                <div className="displayflex">
                    <img src={usergroup} alt="User group" />
                    <h3 className="card-title">For <span>{cardData?.count}</span> Users</h3>
                </div>
            </div>
        </div>
    )
}

export default React.memo(PreapprovedDataCard)