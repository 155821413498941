import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanDefaultedList = createAsyncThunk(
    "loanDefaultedList",
    async ({ pageNum, searchQry, fromDate, toDate }) => {
        const url = `/loans/defaulted-list?page=${pageNum}&limit=10&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await DataService.get(url);
        return res.data;
    }
);

// Download Loan defaulted Excel
export const getLoanDefaultedExcel = createAsyncThunk(
  "defaultedExcel",
  async ({ searchQry, fromDate, toDate, totalCount }) => {
    const url = `loans/defaulted-list?page=1&limit=${totalCount}&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

// export const generateExcel = createAsyncThunk(
//   "generateExcel",
//   async ({fromDate, toDate}) => {
//     const url = `excel/defaultedlist?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data.data;
//   }
// );

const initialState = {
    loanDefaultedList: [],
    loanDefaultedExcel:[],
    setLoading: true,
    totalCount: 0,
    excelDataSheet: [],
};

const loanDefaultedSlice = createSlice({
    name: "loanDefaulted",
    initialState,
    extraReducers: {
      [getLoanDefaultedList.pending]: (state) => {
        state.setLoading = true;
      },
      [getLoanDefaultedList.fulfilled]: (state, action) => {
        state.totalCount = action.payload.totalItmes;
        state.loanDefaultedList = action.payload.data;
        state.setLoading = false;
      },
      [getLoanDefaultedList.rejected]: (state) => {
        state.setLoading = false;
      },
      [getLoanDefaultedExcel.fulfilled]: (state, action) => {
        state.loanDefaultedExcel = action.payload.data;
      },
      // [generateExcel.fulfilled]: (state, action) => {
      //   state.excelDataSheet = action.payload.data
      // },
    },
  });
  
  const { reducer } = loanDefaultedSlice;
  export default reducer;