/* eslint-disable array-callback-return */
import React, { useContext, useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CustomerData } from "../LoanApplication";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Ops from '../Documents/Ops'
import Kyc from "../Documents/Kyc";
import Companyoficials from "../Documents/Companyoficials";
import { DocumentVerification } from '../../../slice/loanapplication'
import PropertyInfo from "../Documents/PropertyInfo";
import BorrowersInfo from "../Documents/BorrowersInfo";
import { Modal } from "antd";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  },
}));

const Documents = ({verificationPendingDoc}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const customerData = useContext(CustomerData);
  const [expanded, setExpanded] = React.useState(false);

  const handleVerify = (index, docId, status, reason, remark) => {
    const data = {
      index: index,
      documentId: docId,
      status: status,
      reason_type: reason,
      remarks: remark
    };
    dispatch(DocumentVerification(data))
  }
  // useEffect(()=>{
  //   if(verificationPendingDoc){
  //     Modal.error({
  //       title: 'Use Hook!',
  //     });
  //   }
  // },[verificationPendingDoc])
  return (
    <>
      <Grid container spacing={2} sx={{ p: "15px 100px", overflow: 'auto' }}>
        <Grid item xs={12}>
          <TabContext value={value} variant="fullWidth">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="OPERATIONAL"
                  value="1"
                  style={{ minWidth: "15%" }}
                />
                <Tab
                  label="KYC"
                  value="2"
                  style={{ minWidth: "20%" }}
                />
                <Tab
                  label="Company Financials"
                  value="3"
                  style={{ minWidth: "20%" }}
                />
                <Tab
                  label="Borrowers"
                  value="4"
                  style={{ minWidth: "20%" }}
                />
                <Tab
                  label="Property"
                  value="5"
                  style={{ minWidth: "20%" }}
                />
              </TabList>
            </Box>
            <Ops onClick={handleVerify} />
            <Kyc onClick={handleVerify} />
            <Companyoficials onClick={handleVerify} />
            <PropertyInfo onClick={handleVerify} />
            <BorrowersInfo onClick={handleVerify} />
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

export default Documents;
