import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getweeklyloans = createAsyncThunk('getweeklyloans', async ()=>{
    try{
        const url = `/console/weekly_loans`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    isLoading : true,
    weeklyLoansData : '' 
}

const loanStatusSlice = createSlice({
    name : 'loanstatusslice',
    initialState,
    extraReducers:{
        [getweeklyloans.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getweeklyloans.fulfilled]:(state,{payload})=>{
            return {...state, weeklyLoansData:payload?.data?.weeklyloans, isLoading:false}
        },
    }
})
export const getWeeklyLoansData = (state) => state.loanstatusapi;
export default loanStatusSlice.reducer