import React from 'react'
import './headernew.css'
import { BellOutlined, PoweroffOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getNotifications } from '../../slice/header'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import NotificationModal from './NotificationModal'
import { getupdateuserheadertitle } from '../../slice/commonslice'
import { ExclamationCircleFilled } from '@ant-design/icons';
const HeaderNew = () => {
    const dispatch = useDispatch()
    const [notifyOpen, setNotifyOpen] = useState(false)
    const pageTtile = useSelector(getupdateuserheadertitle)
    const navigate = useNavigate()

    const handleNotifyOpen = () => {
        setNotifyOpen(true)
    // console.log('Open')
    }

    const handleNotifyClose = () => {
        setNotifyOpen(false)
    }
    const showConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want to logout?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            onOk() {
                navigate('/auth/logout')
            },
            cancelButtonProps:{
                style:{
                    color: '#292660',
                    borderColor:'#292660'
                }
            },
            okButtonProps:{
                style:{
                    color:'#ffffff',
                    backgroundColor:'#292660',
                    borderColor:'#292660'
                }
            }
        });
    };
    useEffect(() => {
        dispatch(getNotifications())
    }, [])
    return (
        <header className='header'>
            <div className="title-wrap">
                <div className="title">
                    <span>{pageTtile === 'Dashboard' ? 'ATLAS Loan Dashboard' : pageTtile}</span>
                </div>
                {/* <div className="search-wrap">
                    <Button icon={<SearchOutlined className='search-icon'/>}>Search</Button>
                </div> */}
            </div>
            <div className="right-buttons-wrap">
                <BellOutlined onClick={handleNotifyOpen}className='right-icons'/>
                <PoweroffOutlined className='right-icons' onClick={showConfirm}/>

            </div>
            {
                notifyOpen ? <NotificationModal open={notifyOpen} handleClose={handleNotifyClose} /> : ''
            }
        </header>
    )
}

export default HeaderNew