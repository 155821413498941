import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import { Typography, Grid } from '@material-ui/core'
import { TiTick } from 'react-icons/ti'

const BusinessAmount = () => {
    const classes = makeStyles()
    return (
        <>
            <Typography className={classes.ebrBodyTabHeader}>Monthly Average Business Amount</Typography>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 1 Crores</p>
                            <p className={classes.ebrBodyCellContent}>10 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 75 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>7.5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 50 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 25 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>2.5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 10 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>2 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#62; 1 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>1 Point</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>&#60; 1 lakhs</p>
                            <p className={classes.ebrBodyCellContent}>0.5 Points</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default BusinessAmount