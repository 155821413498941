import React, { useEffect } from 'react'
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import PageHeader from '../Sharedfeatures/PageHeader';
import { TXT_HOME, TXT_VIRTUAL_ACCOUNT } from '../../utils/header';
import { TableBody, TableRow, TableCell, IconButton, TextField } from "@material-ui/core";
import { useState } from 'react';
import { SearchOutlined } from "@material-ui/icons";
import useTable from '../Sharedfeatures/UseTable';
import { useDispatch, useSelector } from 'react-redux';
import HeadCells from '../common/HeadCells';
import ProgressSpinner from '../common/ProgressSpinner';
import { PAGE_SIZE } from '../../utils/variable';
import moment from 'moment';
import { getVirtualAccountList } from '../../slice/virtualAccountSlice';
import { Button, Modal } from 'antd';
import ReverseModal from './ReverseModal';

const VirtualAccount = () => {
    const [search, setSearch] = useState("")
    const [reverseAmtId, setReverseAmtId] = useState("")
    const [reverseAmt, setReverseAmt] = useState("")
    const [showPageNtn, setShowPageNtn] = useState(false);
    const dispatch = useDispatch()

    const sharedClasses = useSharedClasses();
    const { isLoading, virtualAccountList, totalCount } = useSelector((store) => store.virtualaccountapi)

    const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(virtualAccountList, HeadCells.virtualAccounttHead, totalCount);
    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        //handleChange(null, 1)
    }
    useEffect(() => {
        const data = {
          searchQry: search,
          pageNum: pageNo,
        };
        if(reverseAmtId === ''){
            dispatch(getVirtualAccountList(data))
            setShowPageNtn(true);
        }
    }, [dispatch, pageNo, search,reverseAmtId])
    return (
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_VIRTUAL_ACCOUNT}
                subheader={`${TXT_HOME}/${TXT_VIRTUAL_ACCOUNT}`}
            />
            {/* <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                <TextField
                    size="small"
                    className={sharedClasses.searchTextBar}
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search DT/RT Code or Name"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                    endAdornment: (
                        <IconButton>
                        <SearchOutlined
                            className={sharedClasses.searchIconColour}
                        />
                        </IconButton>
                    ),
                    }}
                />
                </div>
            </article> */}
            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {
                    isLoading ? (
                    <TableBody>
                        <TableRow>
                        <TableCell colSpan={10} align='center'>
                            <ProgressSpinner />
                        </TableCell>
                        </TableRow>
                    </TableBody>
                    ) : (
                    <TableBody>
                        {
                        (virtualAccountList.length !== 0) ? (
                            virtualAccountList &&
                            virtualAccountList.map((item, key) => {
                            serialNo++;
                            key++;
                            return (
                                <TableRow key={key.toString()}>
                                    <TableCell>{serialNo}</TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                        {item.userCode}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                        {item.userName}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                        {item.balance}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell} >
                                        <div className={sharedClasses.reverseButtonWrap} >
                                            <Button disabled={item.balance <= 0 ? true : false} onClick={()=>{setReverseAmtId(item.userCode); setReverseAmt(item.balance);}} type="primary" className={sharedClasses.reverseButton}>Reverse</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                            })
                        ) : (
                            <TableRow>
                            <TableCell colSpan={10} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                            </TableRow>
                        )
                        }
                    </TableBody>
                    )
                }
    
                </TblContainer>
                {showPageNtn ? <TblPagination /> : ""}
            </section>
            </div>
            {
                reverseAmtId &&
                <ReverseModal reverseAmtId={reverseAmtId} setReverseAmtId={setReverseAmtId} reverseAmt={reverseAmt}/>                
            }
        </div>
    );
}

export default VirtualAccount