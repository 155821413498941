import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import PaymentHistory from './PaymentHistory/PaymentHistory';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '../../../Sharedfeatures/SharedClasses'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '../../../controls/Button';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#963460',
        },
    },
});

const CibilScoreModal = ({ open, onClose, tabValue }) => {

    const classes = makeStyles()

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "78%",
        bgcolor: "background.paper",
        borderRadius: "15px",
        boxShadow: 24,
        p: 4,
    };

    const [value, setValue] = useState(tabValue);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={onClose}
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2" className={classes.cbReportModalHeader}>
                        Credit Bureau Report
                    </Typography>

                    <Box sx={{ width: '100%' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange}
                                    centered
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    variant="fullWidth"
                                >
                                    <Tab label="Payment History" value="1" />
                                    {/* <Tab label="Credit Card Utilization" value="2" />
                                    <Tab label="Credit History" value="3" />
                                    <Tab label="Total Accounts" value="4" />
                                    <Tab label="Credit Enquiries" value="5" /> */}
                                </TabList>
                            </Box>
                            <TabPanel value="1"><PaymentHistory /></TabPanel>
                            {/* <TabPanel value="2">Credit Card Utilization</TabPanel>
                            <TabPanel value="3">Credit History</TabPanel>
                            <TabPanel value="4">Total Accounts</TabPanel>
                            <TabPanel value="5">Credit Enquiries</TabPanel> */}
                        </TabContext>
                        <div className={classes.paymentHistoryBackButtonParent}>
                            <Button
                                text="Back"
                                size="small"
                                variant="outlined"
                                startIcon={<ArrowBackIosIcon />}
                                className={classes.paymentHistoryBackButton}
                                onClick={onClose}
                            />
                        </div>
                    </Box>

                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default CibilScoreModal