import React, { useEffect, useState } from "react";
import {
  Paper,
  Container,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  TextField,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import { useSnackbar } from "../common/useSnackbar";
import {
  getPreApprovedLoan,
  acceptEditedOffer,
  rejectEditedOffer,
  reset_message,
  getPreApprovedExcel,
  resetEdtdOfferStates,
} from "../../slice/preApproved";
import { TXT_PRE_APPROVED, TXT_HOME } from "../../utils/header";
import { SearchOutlined } from "@material-ui/icons";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import "react-toastify/dist/ReactToastify.css";
import PageHeader from "../Sharedfeatures/PageHeader";
import DownloadIcon from "@mui/icons-material/Download";
import PreAppDialogueBox from "../common/PreAppDialogueBox";
import useExcel from "../common/useExcel";
import EditOffer from "./EditOffer";
import { useMemo } from "react";
import debounce from "lodash/debounce";

const PreApprovedLoan = () => {
    const sharedClasses = useSharedClass();

    const { preApprovedList, totalCount, analytics, message, isLoading, edtdStatus, edtdMsg } = useSelector((state) => state.preApproved);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [currentItem, setCurrentItem] = useState({});
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const snackBar = useSnackbar();
    const excelData = useExcel();

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(
        preApprovedList,
        HeadCells.preApprovedHead,
        totalCount
    );

    //Edit Offer Modal
    const [openEditModal, setOpenEditModal] = useState(false)

    const editModalOpen = (item) => {
        // setDialogText("reject");
        setCurrentItem(item);
        setOpenEditModal(true)
    }

    const editModalClose = () => {
        setOpenEditModal(false)
    }

    const onBtnClick = () => {
        setOpenDialogue(!openDialogue);
    };

    useEffect(() => {
        const data = {
        searchQuery: search,
        pageNo: pageNo,
        };
        dispatch(getPreApprovedLoan(data));
        dispatch(reset_message());
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, openEditModal]);

    useEffect(() => {
        if (message) {
        snackBar.openSnackbar(message);
        }
    }, [message]);

    const handleApprove = (item) => {
        const approveData = {
        loanapproval_id: item.loanapproval_id,
        statusId: item.statusId,
        };
        dispatch(acceptEditedOffer(approveData));
    };

    const handleReject = (item) => {
        const rejectData = {
        loanapproval_id: item.loanapproval_id,
        statusId: item.statusId,
        };
        dispatch(rejectEditedOffer(rejectData));
    };

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }

    // For showing success message for edited offers
    useEffect(() => {
        if (edtdStatus && edtdMsg) {
        snackBar.openSnackbar(edtdMsg);
        dispatch(resetEdtdOfferStates())
        editModalClose();
        }
    }, [edtdStatus, edtdMsg])

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQuery: search,
            pageNo: pageNo,
            totalCount,
        };
        dispatch(getPreApprovedExcel(data))
        .then(res=>{
            if(res.payload.success === true){
                let excelsheetdata = []
                res.payload.data?.map(item=>{
                    excelsheetdata.push({
                        "RT/DT Code" : item.userid,
                        "Name" : item.name,
                        "Loan Product" : item.loanName,
                        "Amount" : item.offer_amount,
                        "Edited Offer" : item.amount,
                        "Edit Offer" : item.amount,
                        "Tenure" : item.tenure,
                    })
                })
                excelData.generateExcelFile(excelsheetdata, 'Pre Approved Sheet', 'preapprovedsheet.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    return (
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_PRE_APPROVED}
                subheader={`${TXT_HOME}/${TXT_PRE_APPROVED}`}
            />

            <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                {/* <Toolbar className={sharedClasses.toolBar}> */}
                <TextField
                    size="small"
                    fullWidth
                    className={sharedClasses.lpsearchTextBar}
                    //value={search}
                    onChange={debouncedResults}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Name or RT/DT Code"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                    endAdornment: (
                        <IconButton>
                        <SearchOutlined
                            className={sharedClasses.searchIconColour}
                        />
                        </IconButton>
                    ),
                    }}
                />
                {/* </Toolbar> */}
                </div>
                <div style={{display:'flex', gap:'10px', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap'}}>
                    <div className={sharedClasses.innerHeadContainer}>
                        <h2 className={sharedClasses.containerHeader}>
                        Total Loan Offered:
                        </h2>
                        <p className={sharedClasses.containerBody}>
                        {analytics.total_loan_offered}
                        </p>
                    </div>
                    <div className={sharedClasses.innerHeadContainer}
                        style={{ border: "2px solid #2d105e", background: "#ffffff" }}
                    >
                        <h2
                        className={sharedClasses.containerHeader}
                        style={{ color: "#2d105e" }}
                        >
                        Total Amount Offered:
                        </h2>
                        <p
                        className={sharedClasses.containerBody}
                        style={{ color: "#2d105e" }}
                        >
                        {analytics.total_amount_offered}
                        </p>
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={sharedClasses.download}
                        onClick={downloadExcelsheet}
                        disabled = {totalCount === 0 ? true : false}
                        endIcon={<DownloadIcon />}
                    >
                        Download Report
                    </Button>
                </div>
            </article>
            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {isLoading ? (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={9} align='center'>
                        <ProgressSpinner />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                    {
                        (preApprovedList.length !== 0) ? (
                        preApprovedList &&
                        preApprovedList.map((item, key) => {
                            serialNo++;
                            return (
                            <TableRow
                                key={key.toString()}
                                sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                }}
                            >
                                <TableCell className={sharedClasses.tableCell}>
                                {serialNo}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.userid}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.name}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.loanName}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.offer_amount}
                                </TableCell>

                                <TableCell
                                className={sharedClasses.tableCell}
                                style={{ color: "#963460", fontWeight: 600 }}
                                >
                                {item.amount === item.offer_amount
                                    ? ""
                                    : item.amount}
                                </TableCell>

                                <TableCell
                                className={sharedClasses.tableCell}
                                style={{ textAlign: "center" }}
                                >
                                {item.amount ? (
                                    <Button onClick={() => editModalOpen(item)}
                                    disabled={(item.score >= 400) || (item.statusId < 2 || item.statusId >= 8)}
                                    color="primary" variant="outlined"
                                    style={{
                                        color: '#FF9800',
                                        border: '1px solid #FF9800',
                                        fontSize: '12px', width: '150px'
                                    }}>
                                    {item.amount}
                                    </Button>
                                ) : (
                                    <Button onClick={() => editModalOpen(item)}
                                    disabled={(item.score >= 400) || (item.statusId < 2 || item.statusId >= 8)}
                                    color="primary" variant="outlined" style={{
                                        color: '#FF9800',
                                        border: '1px solid #FF9800',
                                        fontSize: '12px', width: '50px'
                                    }}>
                                    Edit Offer
                                    </Button>
                                )}
                                </TableCell>

                                <TableCell className={sharedClasses.tableCell}>{item.tenure}</TableCell>

                                <TableCell className={sharedClasses.tableCell}>
                                <Tooltip
                                    title={
                                    item.statusId === 4 ? "Accept Edited Offer" : ""
                                    }
                                >
                                    <IconButton
                                    onClick={
                                        item.statusId === 4
                                        ? () => {
                                            setOpenDialogue(true);
                                            setDialogText("approve");
                                            setCurrentItem(item);
                                        }
                                        : ()=>{}
                                    }
                                    >
                                    {
                                        <DoneIcon
                                        color={
                                            item.statusId === 4
                                            ? "primary"
                                            : "disabled"
                                        }
                                        />
                                    }
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                    item.statusId === 4 ? "Reject Edited Offer" : ""
                                    }
                                >
                                    <IconButton
                                    onClick={
                                        item.statusId === 4
                                        ? () => {
                                            setOpenDialogue(true);
                                            setDialogText("reject");
                                            setCurrentItem(item);
                                        }
                                        : ()=>{}
                                    }
                                    >
                                    {
                                        <CloseRoundedIcon
                                        color={
                                            item.statusId === 4 ? "error" : "disabled"
                                        }
                                        />
                                    }
                                    </IconButton>
                                </Tooltip>
                                </TableCell>
                            </TableRow>
                            );
                        })
                        ) : (
                        <TableRow>
                            <TableCell colSpan={9} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                        </TableRow>
                        )
                    }
                    </TableBody>
                )}
                </TblContainer>
                <div>
                <Snackbar {...snackBar} />
                </div>
                {showPageNtn ? <TblPagination /> : ""}
            </section>
            </div>
            {openDialogue ? (
            <PreAppDialogueBox
                open={openDialogue}
                onClose={onBtnClick}
                text={dialogText}
                item={currentItem}
                approve={handleApprove}
                reject={handleReject}
            />
            ) : null}

            {
            openEditModal ? <EditOffer open={openEditModal} handleClose={editModalClose} item={currentItem} /> : ''
            }


        </div>
    );
};

export default PreApprovedLoan;
