import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatRupees } from '../../../utils/formatRupees';

const RepaymentsCards = ({cardData}) => {
    return (
        < div className='displayflexcol data-card-wrap'>
        {
            cardData?.map((item,ind)=>{
                return(
                    <div className={`data-card ${item?.title === 'Disbursed' ? 'disbursed' : item?.title === 'Repaid' ? 'repaid' : item?.title === 'Due' ? 'due' : ''} `} key={ind}>
                        <div className="header">{item?.title}</div>
                        <div className="displayflexcol">
                            <div className="displayflex">
                                <span className='label'>Amount</span>
                                <div className='value'>
                                    <CurrencyRupeeIcon className='rupee'/>
                                    <span>{formatRupees(item?.amount)}</span>
                                </div>
                            </div>
                            <div className="displayflex">
                                <span className='label'>Count</span>
                                <div className='value'>
                                    <span>{item?.count}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        </div>
    )
}

export default React.memo(RepaymentsCards)