import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { fontSize } from '@mui/system';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        padding: '10px'

    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    title: {
        fontSize: 26,
        textAlign: "center",
        color: "#b15c82",
        marginBottom: 13,
    },
    subHeader: {
        fontSize: 12,
        marginBottom: 10,
        color: "grey",
    },
    subHeaderText: {
        fontSize: 12,
        marginBottom: 7,
        color: "grey",
    },
    lineBreak: {
        borderWidth: 1,
        borderColor: "#3778C2",
        marginBottom: 7,
    },
    headingOfTable: {
        marginBottom: 8,
        marginTop: 30,
        color: '#b15c82',
        fontSize: 15,
        textTransform: 'uppercase',
        textDecoration: 'underline',
    },
    tableBorder: {
        border: '1px solid #b15c82'
    },
    tableHeader: {
        backgroundColor: '#b15c82',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableHeaderCell: {
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid black'
    },
    tableHeaderText: {
        padding: '7px 0',
        color: '#fff',
        fontSize: '12px',
        letterSpacing: '0.8px',
    },
    tableBody: {
        backgroundColor: '#dfc2cf',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableBodyCell: {
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid black'
    },
    tableBodyText: {
        padding: '7px 0',
        color: '#000000',
        fontSize: '12px',
    },
    footer: {
        position: 'absolute',
        width: '100%',
        bottom: '0',
        paddingLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerText: {
        fontSize: 10,
        color: "grey",
    },
    footerTextTwo: {
        fontSize: 10,
        color: "grey",
    }

});

const ECAPSReport = (props) => {
    const date = new Date().toLocaleDateString()
    const moment = require("moment");

    const business_score = props && props.data && props.data.business_score
    const vintage_period_data = props && props.data && props.data.ecaps_business_report[1]
    const distr_status_data = props && props.data && props.data.ecaps_business_report[4]
    const buss_trend_data = props && props.data && props.data.ecaps_business_report[3]
    const monthly_avg_buss_data = props && props.data && props.data.ecaps_business_report[2]
    const dist_retl_status_data = props && props.data && props.data.ecaps_business_report[0]

    return (
        <Document title="ECAPS Business Report">
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.title}>
                        <Text style={styles.title}>ECAPS BUSINESS REPORT</Text>
                    </View>
                    <View style={styles.subHeader}>
                        <Text style={styles.subHeaderText}>ECAPS Business Score: {business_score}</Text>
                        <Text style={styles.subHeaderText}>Overall Business Performance: {(business_score >= 200 && business_score < 400) ? 'Poor' :
                            (business_score >= 400 && business_score < 600) ? 'Good' :
                                (business_score >= 600 && business_score < 800) ? 'Fair' :
                                    (business_score >= 800 && business_score < 1000) ? 'Excellent' :
                                        'Outstanding'}</Text>
                    </View>
                    <View style={styles.lineBreak}></View>

                    <Text style={styles.headingOfTable}>Vintage Period</Text>
                    <View style={styles.tableBorder}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Title</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Points</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Impact</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Value</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{vintage_period_data.title}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{vintage_period_data.point}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{vintage_period_data.impact}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{vintage_period_data.value}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.headingOfTable}>Active Status of Distributor</Text>
                    <View style={styles.tableBorder}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Title</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Points</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Impact</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Value</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{distr_status_data.title}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{distr_status_data.point}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{distr_status_data.impact}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{distr_status_data.value}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.headingOfTable}>Business Trend</Text>
                    <View style={styles.tableBorder}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Title</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Points</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Impact</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Value</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{buss_trend_data.title}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{buss_trend_data.point}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{buss_trend_data.impact}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{buss_trend_data.value}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.headingOfTable}>Monthly Average Business</Text>
                    <View style={styles.tableBorder}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Title</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Points</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Impact</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Value</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{monthly_avg_buss_data.title}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{monthly_avg_buss_data.point}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{monthly_avg_buss_data.impact}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{monthly_avg_buss_data.value}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.headingOfTable}>Distributor/Retailer Classification</Text>
                    <View style={styles.tableBorder}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Title</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Points</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Impact</Text>
                            </View>
                            <View style={styles.tableHeaderCell}>
                                <Text style={styles.tableHeaderText}>Value</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{dist_retl_status_data.title}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{dist_retl_status_data.point}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{dist_retl_status_data.impact}</Text>
                            </View>
                            <View style={styles.tableBodyCell}>
                                <Text style={styles.tableBodyText}>{dist_retl_status_data.value}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.footer}>
                        <Text style={styles.footerText}>ECAPS Business Report</Text>
                        <Text style={styles.footerTextTwo}>Report Generated On: {moment(date).format("DD MMMM YYYY")}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default ECAPSReport