import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const NbfcConfDialog = ({ open, handleClose, handleAction, msg }) => {

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleAction}
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NbfcConfDialog