import React, { useState, useEffect } from "react";
import "./FinalDashboard.css";
import { Button, Card } from "@material-ui/core";
import { BiRupee } from "react-icons/bi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import BigGraphHeader from "./BigGraphHeader";
import { GRAPH_ONE_HEADER } from "../../utils/graphHeadings";
import { useDispatch, useSelector } from "react-redux";
import useYAxisFormatter from "../Sharedfeatures/useYAxisFormatter";
import { getInterestChart } from "../../slice/finaldashboard";
import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import ProgressSpinner from "../common/ProgressSpinner";

const InterestChart = ({year}) => {
  // const [year, setYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const { interestCollected, totalInterestCollected, setLoading } = useSelector((store) => store.finaldashboard);
  const { dataFormater } = useYAxisFormatter();
  const { CustomTooltip } = useCustomTooltip();

  // const interestCollected = [
  //   {
  //     "month": "January",
  //     "amount": 10,
  //     "charges": 20
  //   },
  //   {
  //     "month": "February",
  //     "amount": 10,
  //     "charges": 30
  //   },
  //   {
  //     "month": "March",
  //     "amount": 20,
  //     "charges": 20
  //   },
  //   {
  //     "month": "April",
  //     "amount": 40,
  //     "charges": 10
  //   },
  //   {
  //     "month": "May",
  //     "amount": 20,
  //     "charges": 50
  //   },
  //   {
  //     "month": "June",
  //     "amount": 50,
  //     "charges": 40
  //   },
  //   {
  //     "month": "July",
  //     "amount": 40,
  //     "charges": 30
  //   },
  //   {
  //     "month": "August",
  //     "amount": 30,
  //     "charges": 30
  //   },
  //   {
  //     "month": "September",
  //     "amount": 50,
  //     "charges": 20
  //   },
  //   {
  //     "month": "October",
  //     "amount": 10,
  //     "charges": 30
  //   },
  //   {
  //     "month": "November",
  //     "amount": 20,
  //     "charges": 30
  //   },
  //   {
  //     "month": "December",
  //     "amount": 10,
  //     "charges": 50
  //   }
  // ]

  useEffect(() => {
    const data = {
      year,
    };
    dispatch(getInterestChart(data));
  }, [year, dispatch]);

  // const handleYearChange = () => {
  //   const data = {
  //     year,
  //   };
  //   dispatch(getInterestChart(data));
  // };

  let data = [];
  interestCollected.map((obj) => {
    let cdata = {
      name: obj.month.substring(0, 3),
      amount: Number(obj.amount),
      month: obj.month + "" + obj.value,
      charge: Number(obj.charges)
    };
    data.push(cdata);
  });
  // const renderCustomizedLabel = (props) => {
  //   const { x, y, width, height, value } = props;
  //   const radius = 10;
  //   return (
  //     <g>
  //       <text x={x + 25} y={405} fill="black" textAnchor="middle" dominantBaseline="middle" fontSize="11px">
  //         {Number(value.toFixed(0))}
  //       </text>
  //     </g>
  //   );
  // };
  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //   return (
  //     <>
  //       <div>
  //         <Card style={{ padding: '7px 12px' }}>
  //           <p>Value : ₹ {payload[0].value}</p>
  //         </Card>
  //       </div>
  //     </>
  //   );
  //   }
  //   else {
  //     return null;
  //   }
  // };

  let curYear = new Date().getFullYear()
  let years = []
  for (let i = curYear; i >= 2022; i--) {
    years.push(i)
  }

  return (
    <div className="graph-one-card">
      <header className="graph-one-card-header">
        <BigGraphHeader header={GRAPH_ONE_HEADER} />

        <article className="ledgers">
          <article className="ledg-first-col">
            <h3 className="ledg-title">
              <article className="ledg-circle-one" />
              &nbsp;&nbsp;&nbsp;Interest collected
            </h3>
            <h3 className="ledg-title">
              <article className="ledg-circle-two" />
              &nbsp;&nbsp;&nbsp;Charge
            </h3>
          </article>
        </article>
      </header>

      <div className="right-info-card">
        {/* <label className="right-info-card-label" for="year">
          Year&nbsp;&nbsp;&nbsp;
        </label>
        <select
          id="year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {
            years.map(item => <option key={item} value={item}>{item}</option>)
          }
        </select>
        &nbsp;
        <Button
          variant="contained"
          className="go-button"
          onClick={handleYearChange}
        >
          GO!
        </Button> */}
        <div>
          <h3 className="int-collected">Total Interest Collected This Year</h3>
          <h5 className="int-collected-sum">
            <BiRupee />
            {Number(Number(totalInterestCollected).toFixed(2)).toLocaleString()}
          </h5>
        </div>
      </div>
      {setLoading ? (
        <div className="spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <ResponsiveContainer width="68%" height="80%">
          <BarChart
            width={790}
            height={450}
            data={data ? data : <p>Loading</p>}
            margin={{ bottom: 35 }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis dataKey="name" axisLine={true}></XAxis>
            <YAxis
              datakey="value"
              axisLine={true}
              tickFormatter={dataFormater}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Bar
              dataKey="amount"
              barSize={25}
              fill="#2DB6F5"
              margin={{ bottom: 50 }}
              animationBegin={400}
              animationDuration={400}
              animationEasing="linear"
            />
            <Bar
              dataKey="charge"
              barSize={25}
              fill="#39C86A"
              margin={{ bottom: 50 }}
              animationBegin={400}
              animationDuration={400}
              animationEasing="linear"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default React.memo(InterestChart);