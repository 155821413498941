import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

const partnerId = process.env.REACT_APP_PARTNER_ID

export const addLoan = createAsyncThunk(
  "addloan",
  async (state, thunkAPI) => {
    try {
      const url = '/loanproducts'
      const { ...data } = state
      const res = await DataService.post(url, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)

export const retrieveLoanlist = createAsyncThunk(
  "loanproducts",
  async ({ pageNum, searchQry }) => {
    const url = `/loanproducts/${partnerId}/list?page=${pageNum}&limit=10&searchqry=${searchQry}`
    const res = await DataService.get(url);
    return res.data;
  }
);


//download excel
export const getLoanProductsExcel = createAsyncThunk(
  "getLoanProductsexcel",
  async (data) => {
    const { pageNum, searchQry, totalCount } = data
    // const url = `/loanproducts/${partnerId}/list?page=${pageNum}&limit=10&searchqry=${searchQry}`
    const url = `/loanproducts/${partnerId}/list?page=${pageNum}&limit=${totalCount}&searchqry=${searchQry}`
    const res = await DataService.get(url);
    return res.data;
  }
);

export const editLoan = createAsyncThunk(
  "loanproducts/edit",
  async (state) => {
    const { id, ...data } = state;
    const url = `/loanproducts/${id}`
    const res = await DataService.put(url, data);
    return res.data;
  }
);

export const getLoanDetails = createAsyncThunk(
  `loanproducts/id`,
  async (id, { rejectWithValue }) => {
    try {
      const list = await DataService.get(`/loanproducts/${id}`);
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getApprovedLoanDetails = createAsyncThunk(
  `/applications/nbfc-processing-list/_id`,
  async (_id, { rejectWithValue }) => {
    try {
      const listDetails = await DataService.get(`/applications/nbfc-processing-list/` + _id);
      return listDetails;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getFloatingInterestRate = createAsyncThunk(
  "floatingInterestRate",
  async () => {
    const url = '/loanproducts/floatingrates'
    const res = await DataService.get(url)
    return res.data.data;
  }
)

export const reset_message = createAsyncThunk(
  "resetmessage",
  async () => {
    return;
  }
)


const initialState = {
  loanList: [],
  loanDataExcel: [],
  generateLoanOffers: [],
  loanAprovedDetail: [],
  editLoan: [],
  loanDetails: [],
  addLoanDetails: [],
  viewLoan: [],
  success: '',
  message: '',
  loanNameError: '',
  principalError: '',
  floatingInterest: '',
  setLoading: true,
  isModalOpen: false,
  totalCount:''
};

const loanSlice = createSlice({
  name: "loan",
  initialState,
  extraReducers: {
    [retrieveLoanlist.pending]: (state, action) => {
      state.setLoading = true;
    },
    [retrieveLoanlist.fulfilled]: (state, action) => {
      state.loanList = action.payload.data;
      state.totalCount = action.payload.totalItmes;   
      state.setLoading = false;
    },
    [getLoanProductsExcel.fulfilled]: (state, action) => {
      state.loanDataExcel = action.payload.data;
    },
    [getApprovedLoanDetails.fulfilled]: (state, action) => {
      state.loanAprovedDetail = action.payload.data;
    },
    [editLoan.fulfilled]: (state, action) => {
      state.success = action.payload.message;
      state.editLoan = action.payload;
    },
    [getLoanDetails.fulfilled]: (state, action) => {
      state.loanDetails = action.payload.data.data;
    },
    [addLoan.pending]: (state) => {
      state.isModalOpen = true;
    },
    [addLoan.fulfilled]: (state, action) => {
      state.isModalOpen = false;
      state.message = "Successfully Created"
      state.success = action.payload.success
      state.status = action.payload.message
      state.addLoanDetails = action.payload
    },
    [addLoan.rejected]: (state, action) => {
      state.isModalOpen = false;
      state.loanNameError = action.payload
    },
    [getFloatingInterestRate.fulfilled]: (state, action) => {
      state.floatingInterest = action.payload
    },
    [reset_message.fulfilled]: (state, action) => {
      state.message = "";
      state.loanDetails = '';
    },
  },
});

const { reducer } = loanSlice;

export default reducer;