import Login from './components/Login/Login';
import ProductList from "./components/Loans/ProductList"
import PartnersList from './components/Partners/PartnersList';
import NewPartners from './components/Partners/NewPartners';
import LoanView from './components/Loans/LoanView/LoanView';
import Logout from './components/Login/Logout';
import LoanApplication from './components/LoanApplication/LoanApplication';
import LoanDisbursed from './components/LoanDisbursed/LoanDisbursed';
import PreApprovedLoan from './components/PreApprovedLoan/PreApprovedLoan';
import AddLoan from './components/Loans/AddLoan'
import TrancheDisbursed from './components/TrancheDisbursed/TrancheDisbursed';
import LoanAccountDetails from './components/LoanSanctioned/LoanAccountDetails/LoanAccountDetails'
import CreditReports from './components/LoanSanctioned/CreditReports/CreditReports';

import { AiFillDashboard } from "react-icons/ai";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import PaymentsIcon from '@mui/icons-material/Payments';

import {
  TXT_LOAN_PRODUCTS,
  TXT_PRE_APPROVED,
  TXT_LOAN_APPLICATION,
  TXT_LOAN_SANCTIONED,
  TRANCHE_REQUEST,
  TXT_LOAN_DISBURSED,
  TXT_LOAN_STATEMENT,
  TXT_REPAYMENT,
  TXT_LOAN_GENERATED,
  DASHBOARD,
  TXT_LOAN_CLOSED,
  TXT_LOAN_DEFAULTED,
  TXT_CHARGES,
  TXT_LOAN_PRODUCT,
  TXT_UPCOMING_REPAYMENTS,
  TRANCHES
}
  from './utils/header';

import Repayment from './components/Repayment/Repayment';
import LoanStatement from './components/LoanSanctioned/LoanStatement/LoanStatement';
import LoanSanctioned from './components/LoanSanctioned/LoanSanctioned';
import FinalDashboard from './components/FinalDashboard/FinalDashboard';
import BorrowersList from './components/Borrower/BorrowersList';
import LoanAccountStatement from './components/LoanSanctioned/LoanStatement/LoanAccountStatement';
import LoansClosed from './components/LoansClosed/LoansClosed';
import LoansDefaulted from './components/LoansDefaulted/LoansDefaulted';
import OverdueCharges from './components/Charges/OverdueCharges';
import CreateCharge from './components/Charges/CreateCharge';
import Redirect from './components/Sharedfeatures/Redirect';
import UpcomingRepayments from './components/UpcomingRepayments/UpcomingRepayments';
import TranchesList from './components/TranchesList/TranchesList';
import ChargeDetails from './components/Charges/ChargeDetails';
import LoanAppParentTab from './components/LoanApplication/LoanAppParentTab';


var routes = [
  {
    collapse: true,
    path: "/dashboard",
    layout: "/admin",
    name: `${DASHBOARD}`,
    state: "dashboard",
    show: true,
    icon: <DashboardCustomizeIcon fontSize='large' />,
    component: FinalDashboard,
  },
  {
    collapse: false,
    path: "/loanList",
    layout: "/admin",
    name: `${TXT_LOAN_PRODUCTS}`,
    state: "loanList",
    key: "loanList",
    show: false,
    icon: <Inventory2Icon fontSize='large' />,
    component: ProductList,
  },
  {
    collapse: true,
    path: "/loanList",
    layout: "/admin",
    name: `${TXT_LOAN_PRODUCT}`,
    state: "loanList",
    key: "loanList",
    show: true,
    icon: <Inventory2Icon fontSize='large' />,
    views: [
      {
        collapse: false,
        path: "/loanList",
        layout: "/admin",
        name: `${TXT_LOAN_PRODUCTS}`,
        state: "loanList",
        key: "loanList",
        show: true,
        icon: <Inventory2Icon fontSize='large' />,
        component: ProductList,
      },
      {
        collapse: false,
        path: "/charges",
        layout: "/admin",
        name: `${TXT_CHARGES}`,
        state: "overdue charges",
        key: "Overdue Charges",
        show: true,
        icon: <LocalAtmOutlinedIcon fontSize='large' />,
        component: OverdueCharges,
      },
    ]
  },
  {
    collapse: false,
    path: "/partnerslist",
    layout: "/auth",
    name: "Partners",
    state: "partnersList",
    key: "partnersList",
    show: false,
    component: PartnersList,
  },
  {
    collapse: false,
    path: "/addPartner",
    layout: "/admin",
    name: "Add Partner",
    state: "add partner",
    key: "addpartner",
    show: false,
    component: NewPartners
  },
  {
    collapse: false,
    path: "/editPartner/:id",
    layout: "/admin",
    name: "Edit Partner",
    state: "edit partner",
    key: "editpartner",
    show: false,
    component: NewPartners
  },
  {
    collapse: false,
    path: "/addLoan",
    layout: "/admin",
    state: "add loan",
    key: "addloan",
    show: false,
    component: AddLoan
  },
  {
    collapse: false,
    path: "/editloan/:id",
    layout: "/admin",
    name: "Edit Loan",
    state: "edit loan",
    key: "editloan",
    show: false,
    component: AddLoan
  },
  {
    collapse: false,
    path: "/viewDetails/:id",
    layout: "/admin",
    name: "View Details",
    state: "view details",
    key: "viewdetails",
    show: false,
    component: LoanView
  },
  {
    collapse: false,
    path: "/charges",
    layout: "/admin",
    name: `${ TXT_CHARGES }`,
    state: "overdue charges",
    key: "Overdue Charges",
    show: true,
    icon: <LocalAtmOutlinedIcon fontSize='large' />,
    component: OverdueCharges,
  },
  {
    collapse: false,
    path: "/createcharge",
    layout: "/admin",
    state: "create charge",
    key: "createcharge",
    show: false,
    component: CreateCharge
  },
  {
    collapse: false,
    path: "/editcharge/:id",
    layout: "/admin",
    state: "edit charge",
    key: "editcharge",
    show: false,
    component: CreateCharge
  }, 
  {
    collapse: false,
    path: "/chargedetails/:id",
    layout: "/admin",
    state: "charge details",
    key: "chargedetails",
    show: false,
    component: ChargeDetails
  },
  {
    collapse: true,
    path: "/preapprovedloanoffer",
    layout: "/admin",
    name: `${ TXT_PRE_APPROVED }`,
    state: "pre approved",
    key: "preapproved",
    show: true,
    icon: <LocalOfferIcon fontSize='large' />,
    component: PreApprovedLoan,
  },
  // {
  //   collapse: false,
  //   path: "/loangenerated/loansanctioned/loanstatement/:id",
  //   layout: "/admin",
  //   name: `${ TXT_LOAN_STATEMENT }`,
  //   state: "LoanStatement",
  //   key: "loanstatement",
  //   show: false,
  //   icon: <AiFillDashboard/>,
  //   component: LoanStatements,
  // },
  {
    collapse: true,
    path: "/loanapplication",
    layout: "/admin",
    name: `${ TXT_LOAN_APPLICATION }`,
    state: "LoanApplication",
    key: "loanApplication",
    show: true,
    icon: <GroupWorkIcon fontSize='large' />,
    component: LoanAppParentTab,
  },
  {
    collapse: false,
    path: "/loansanctioned",
    layout: "/admin",
    name: `${ TXT_LOAN_SANCTIONED }`,
    state: "loan sanctioned",
    key: "loansanctioned",
    show: true,
    icon: <LocalOfferIcon fontSize='large' />,
    component: LoanSanctioned,
  },
  {
    collapse: false,
    path: "/loandisbursed",
    layout: "/admin",
    name: `${ TXT_LOAN_DISBURSED }`,
    state: "loan disbursed",
    key: "loandisbursed",
    icon: <PaymentIcon fontSize='large' />,
    component: LoanDisbursed,
  },
  {
    collapse: false,
    path: "/loandefaulted",
    layout: "/admin",
    name: `${ TXT_LOAN_DEFAULTED }`,
    state: "loan defaulted",
    key: "loandefaulted",
    show: true,
    icon: <LocalOfferIcon fontSize='large' />,
    component: LoansDefaulted,
  },
  {
    collapse: false,
    path: "/loanclosed",
    layout: "/admin",
    name: `${ TXT_LOAN_CLOSED }`,
    state: "loan closed",
    key: "loanclosed",
    show: true,
    icon: <PaymentIcon fontSize='large' />,
    component: LoansClosed,
  },
  {
    collapse: false,
    path: "/tranchedisbursed",
    layout: "/admin",
    name: `${ TRANCHE_REQUEST }`,
    state: "tranche request",
    key: "trancherequest",
    show: true,
    icon: <PaymentIcon fontSize='large' />,
    component: TrancheDisbursed,
  },
  {
    collapse: true,
    path: "/loangenerated",
    layout: "/admin",
    name: `${ TXT_LOAN_GENERATED }`,
    state: "loan generated",
    key: "loangenerated",
    show: true,
    icon: <CurrencyExchangeIcon fontSize='large' />,
    views: [
      {
        collapse: false,
        path: "/loansanctioned",
        layout: "/admin",
        name: `${ TXT_LOAN_SANCTIONED }`,
        state: "loan sanctioned",
        key: "loansanctioned",
        show: true,
        icon: <LocalOfferIcon fontSize='large' />,
        component: LoanSanctioned,
      },
      {
        path: "/loandisbursed",
        layout: "/admin",
        name: `${ TXT_LOAN_DISBURSED }`,
        state: "loan disbursed",
        key: "loandisbursed",
        icon: <PaymentIcon fontSize='large' />,
        component: LoanDisbursed,
      },
      {
        collapse: false,
        path: "/loandefaulted",
        layout: "/admin",
        name: `${ TXT_LOAN_DEFAULTED }`,
        state: "loan defaulted",
        key: "loandefaulted",
        show: true,
        icon: <LocalOfferIcon fontSize='large' />,
        component: LoansDefaulted,
      },
      {
        path: "/loanclosed",
        layout: "/admin",
        name: `${ TXT_LOAN_CLOSED }`,
        state: "loan closed",
        key: "loanclosed",
        icon: <PaymentIcon fontSize='large' />,
        component: LoansClosed,
      }
    ],
  },
  {
    collapse: false,
    path: "/loanstatement/:id",
    layout: "/admin",
    name: `${ TXT_LOAN_STATEMENT }`,
    state: "LoanStatement",
    key: "loanstatement",
    show: false,
    icon: <AiFillDashboard />,
    component: LoanStatement,
  },
  {
    collapse: false,
    path: "/loansanctioned/loanaccountdetails/:clientId",
    layout: "/admin",
    name: `${ TXT_LOAN_STATEMENT }`,
    state: "LoanAccountDetails",
    key: "LoanAccountDetails",
    show: false,
    icon: <AiFillDashboard />,
    component: LoanAccountDetails,
  },
  {
    collapse: false,
    path: "/loansanctioned/loanaccountdetails/loanaccountstatement/:id/:clientId",
    layout: "/admin",
    name: `${ TXT_LOAN_STATEMENT }`,
    state: "LoanStatement",
    key: "loanstatement",
    show: false,
    component: LoanAccountStatement,
  },
  {
    collapse: true,
    path: "/repayment",
    layout: "/admin",
    name: `${ TXT_REPAYMENT }`,
    state: "repayment",
    show: true,
    icon: <GroupWorkIcon fontSize='large' />,
    component: Repayment,
  },
  {
    collapse: true,
    path: "/upcomingrepayments",
    layout: "/admin",
    name: `${ TXT_UPCOMING_REPAYMENTS }`,
    state: "upcomingrepayment",
    show: true,
    icon: <PaymentsIcon fontSize='large' />,
    component: UpcomingRepayments,
  },
  {
    collapse: true,
    path: "/tranchescreated",
    layout: "/admin",
    name: `${ TRANCHES }`,
    state: "tranches",
    show: true,
    icon: <SplitscreenIcon fontSize='large' />,
    component: TranchesList,
  },
  {
    collapse: true,
    path: "/borrowers",
    layout: "/admin",
    name: "Borrowers",
    state: "borrowers",
    key: "borrowers",
    show: false,
    icon: <CurrencyExchangeIcon fontSize='large' />,
    component: BorrowersList
  },
  {
    collapse: false,
    path: "/",
    layout: "/admin",
    name: "Redirect",
    state: "redirect",
    key: "redirect",
    show: false,
    component: Redirect
  },
  {
    collapse: true,
    path: "/logout",
    layout: "/auth",
    name: "Logout",
    state: "logout",
    key: "logout",
    show: true,
    icon: <LogoutIcon fontSize='large' />,
    component: Logout,
  },
  {
    collapse: false,
    path: "/login",
    layout: "/auth",
    name: "Login",
    state: "login",
    key: "login",
    show: false,
    component: Login
  },
];
export default routes;
