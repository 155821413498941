import React, { useEffect } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import './FinalDashboard.css'
import GraphHeader from './GraphHeader'
import { useDispatch, useSelector } from 'react-redux';
import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import { getRepaymentsData } from '../../slice/finaldashboard';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import ProgressSpinner from '../common/ProgressSpinner';

const RepaymentsChart = ({ year }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRepaymentsData(year));
  }, [dispatch, year]);

  const { repayments, repayments_totalVolume, repaymentsLoader } = useSelector((store) => store.finaldashboard)

  let data = [];
  repayments.map((obj) => {
    let cdata = {
      name: obj.month.substring(0, 3),
      value: Number(obj.amount),
    };
    data.push(cdata);
  });

  const { CustomTooltip } = useCustomTooltip();
  const { dataFormater } = useYAxisFormatter()

  return (
    <div className='second-row-card'>
      <GraphHeader header={"Repayments"}
        subheader={Number(Number(repayments_totalVolume).toFixed(2)).toLocaleString()}
      />
      {repaymentsLoader && (
        <div className="dash_spinner_parent">
          <ProgressSpinner />
        </div>
      )}
      <ResponsiveContainer width="90%" height="65%">
        <LineChart
          width={680}
          height={180}
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <XAxis dataKey="name" axisLine={false} />
          <YAxis dataKey="value" axisLine={false} tickFormatter={dataFormater} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          {
            !repaymentsLoader && <Line type="monotone" dataKey="value" stroke="#7C4DFF" activeDot={{ r: 8 }} strokeWidth={3} />
          }
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default RepaymentsChart