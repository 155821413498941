import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles, Select as MuiSelect, MenuItem } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        width: '105%',
        marginLeft: '280px',
        marginTop: '120px',
    },
    newButton: {
        position: 'absolute',
        right: '10px',
    },
    toolBar: {
        top: 0
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        width: '130%'
    },
    textField: {
        width: '100%',
        maxWidth: '290px',
        height: '45px',
        marginLeft: '0px'
    },
    headingContent: {
        position: 'absolute',
        left: '21%',
        top: '24px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: "column",
        marginBottom: '20px'
    },
    title: {
        alignItems: 'center',
        display: 'flex',
    },
    heading: {
        fontSize: '1.6rem',
        color: '#770249',
        fontWeight: '600',
        marginLeft: '20px'
    },
    iconHeading: {
        fontSize: '2rem',
        color: '#770249',
        marginBottom: '2px'
    },
    breadCrumb: {
        color: '#4b4848',
        fontSize: '1.0rem',
        fontWeight: '300'
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '71%',
    },
    buttons: {
        width: '126px',
        marginTop: '20px'
    },
    button: {
        width: '126px',
        color: '#fff',
        backgroundColor: '#1a0045',
        borderRadius: '14px'
    }
}))
export default function
    Input(props) {
    const classes = useStyles()
    const { name, className, label, text, disabled, onChange, type, error = null, value, ...other } = props
    return (
        <TextField
            className={className}
            variant="outlined"
            type={type}
            name={name}
            label={label}
            onChange={onChange}
            value={value}
            disabled={disabled}
            autoComplete='off'
            inputProps={{
                style: {
                    fontSize: 14,
                    height: 40,
                    width: 272,
                    padding: '0 14px',
                    fontWeight: 'bold'
                },
                min: 0,
            }}
            InputLabelProps={{ shrink: true }}
            {...(error && { error: true, helperText: error })}
            {...other}
        />
    )
}
