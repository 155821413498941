import React, { useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import './FinalDashboard.css'
import GraphHeader from './GraphHeader';
import { GRAPH_FOUR_HEADER, GRAPH_FOUR_SUBHEADER } from '../../utils/graphHeadings';
import { useDispatch, useSelector } from 'react-redux';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import { getLoanSanctionedData } from "../../slice/finaldashboard";
import ProgressSpinner from "../common/ProgressSpinner";

const SanctionChart = ({year}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoanSanctionedData(year));
  }, [year, dispatch]);

  const { loanSanctioned, loanSanctioned_totalVolume, sanctionLoader } = useSelector((store) => store.finaldashboard)
  const total_Volume = loanSanctioned_totalVolume

  let data = [];
  loanSanctioned.map((obj) => {
    let cdata = {
      name: obj.month.substring(0, 3),
      value: Number(obj.amount),
    };
    data.push(cdata);
  });

  const { dataFormater } = useYAxisFormatter()
  const { CustomTooltip } = useCustomTooltip();

  return (
    // <div className='second-row-card'>

    //   <GraphHeader header={GRAPH_FOUR_HEADER} subheader={Number(Number(total_Volume).toFixed(2)).toLocaleString()} />
    //   {sanctionLoader ? (
    //     <div className="dash_spinner_parent">
    //       <ProgressSpinner />
    //     </div>
    //   ) : (
    //     <ResponsiveContainer width="90%" height="65%">
    //       <BarChart
    //         width={680}
    //         height={180}
    //         data={data}
    //       >
    //         <XAxis dataKey="name" axisLine={false} tick={{ fontSize: 12 }} />
    //         <YAxis axisLine={false} tickFormatter={dataFormater} />
    //         <Tooltip cursor={false} content={<CustomTooltip />} />
    //         <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
    //         <Bar dataKey="value" fill="#9E00FF" radius={8} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
    //       </BarChart>
    //     </ResponsiveContainer>)}
    // </div>


    <div className='second-row-card'>

      <GraphHeader header={GRAPH_FOUR_HEADER} subheader={Number(Number(total_Volume).toFixed(2)).toLocaleString()} />
      {sanctionLoader && (
        <div className="dash_spinner_parent">
          <ProgressSpinner />
        </div>
      )}
        <ResponsiveContainer width="90%" height="65%">
          <BarChart
            width={680}
            height={180}
            data={data}
          >
            <XAxis dataKey="name" axisLine={false} tick={{ fontSize: 12 }} />
            <YAxis axisLine={false} tickFormatter={dataFormater} />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
            {
              !sanctionLoader && <Bar dataKey="value" fill="#9E00FF" radius={8} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
            }

          </BarChart>
        </ResponsiveContainer>
      
    </div>
  )
}

export default SanctionChart