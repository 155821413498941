import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'

export const getBorrowersList = createAsyncThunk(
    'borrowers-list',
    async ({ pageNum, searchQry }) => {
        const url = `/loans/borrowers?page=${pageNum}&limit=10&searchqry=${searchQry}`;
        const res = await DataService.get(url)
        return res.data;
    }
)

const initialState = {
    isLoading: true,
    borrowersList: [],
    totalCount: ''
}

const borrowersListSlice = createSlice({
    name: 'borrowers list',
    initialState,
    extraReducers: {
        [getBorrowersList.pending]: (state) => {
            state.isLoading = true;
        },
        [getBorrowersList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.borrowersList = action.payload.data
            state.totalCount = action.payload.totalItmes
        },
        [getBorrowersList.rejected]: (state) => {
            state.isLoading = false;
        },
    }
})

export default borrowersListSlice.reducer