import React, { useState } from 'react'
import {
    Button,
    Grid,
    Typography,
    Card,
    Snackbar,
} from '@mui/material'

import { styled } from '@mui/system'
// import { Span } from '../Typography'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import useSharedClasses from '../Sharedfeatures/SharedClasses';
import { addPartner } from "../../slice/partners"



const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}))

const initialValues = {
    name: "",
    email: ""
}

const NewPartners = () => {
    const [values, setValues] = useState(initialValues);
    const { message, success } = useSelector((state) => state.partner);
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    }

    const handleSubmit = () => {
        dispatch(addPartner(values))
            .then((res) => {
                navigate("/admin/partnerslist");
            })
            .catch((error) => {
            })
    }

    return (
        <Card className={sharedClasses.smallCard}>

            <Typography component="h1" variant="h5" className={sharedClasses.typography}>
                Add Partner
            </Typography>
            <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                <Grid style={{ marginLeft: '13px' }} container spacing={6} >
                    <Grid item lg={5.5} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <TextField
                            type="text"
                            name="name"
                            id="standard-basic"
                            onChange={handleChange}
                            value={values.name}
                            validators={[
                                'required'
                            ]}
                            label="Partner Name"
                            errorMessages={['This field is required']}
                        />
                        <TextField
                            label="Email"
                            onChange={handleChange}
                            type="text"
                            name="email"
                            value={values.email}
                            validators={['required']}
                            errorMessages={[
                                'This field is required',
                            ]}
                        />
                        <Button color="primary" variant="contained" type="submit" className={sharedClasses.button}>
                            {/* <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
                                Submit
                            </Span> */}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Card>
    )
}

export default NewPartners

