import React, { useState } from "react";
import Logo from "../../nbfclogo.svg";
import { Avatar } from '@mui/material';
import Header from "../Header/Header";
import "antd/dist/antd.css";
import routes from '../../Routes';
import { useLocation } from "react-router-dom";
import { Menu } from "antd";
import { SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const { SubMenu } = Menu;

const Navbar = () => {
  let location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const createLinks = (routes) => {
    return (<Menu theme="dark" mode="inline">
      {routes.map((route, key) =>
        route.collapse === true && route.key !== 'loangenerated' && route.key !== 'loanList' ?
          (
            <Menu.Item key={route.name} icon={route.icon} title={route.name}>
              <NavLink to={route.layout + route.path} className={({ isActive }) => (isActive ? 'link active' : 'link')}>{route.name}</NavLink>
            </Menu.Item>
          ) : route.collapse === true && (route.key === 'loangenerated' || route.key === 'loanList') ?
            (<SubMenu key={route.name} icon={route.icon} title={route.name} className="link">
              {route && route.views.map((subItem) =>
              (
                <Menu.Item key={subItem.name} icon={subItem.icon} title={subItem.name} style={{ marginTop: '5px', marginLeft: '20px' }}>
                  <NavLink to={subItem.layout + subItem.path} className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                    {subItem.name}
                  </NavLink>
                </Menu.Item>)
              )}
            </SubMenu>)
            : null
      )}
    </Menu>)
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <>
      <div id="sidebar">
        <SidebarHeader>
          <Header />
          <div className="logotext">
            <div className="avatar">
              <Avatar
                sx={{
                  width: "100px !important",
                  height: "110px !important",
                  bgcolor: "#FFFFFF",
                }}
              >
                <img src={Logo} alt="logo" />
              </Avatar>
            </div>
            <p id="atlas">ATLAS</p>
            <p id="admin">ADMIN</p>
          </div>
        </SidebarHeader>

        <SidebarContent>{createLinks(routes)}</SidebarContent>
      </div>
    </>
  );
};

export default Navbar;
