import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    pageContent: {
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      background: "#FEFEFE",
      marginTop: "40px",
      // backgroundColor: '#ff0000',
      borderRadius: "16px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
    },
    employeeItems: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listHeading: {
      width: "200px",
      fontStyle: "normal",
      fontWeight: "1000",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listValues: {
      width: "200px",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },

    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "5px",
      width: "130%",
      marginTop: "14px",
    },
    textField: {
      width: "100%",
      maxWidth: "300px",
      height: "45px",
      marginLeft: "39px",
    },
    principalPayment: {
      padding: "5px",
    },
    addLoanPaper: {
      // width: "78vw",
      // paddingLeft: "40px",
      // paddingRight: '40px',
      // paddingBottom: '20px',
      // ['@media (max-width: 1400px)']: {
      //   width: "70vw",
      //  },
      padding:'30px'
    }
  })
);
