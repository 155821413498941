import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import { Typography, Grid } from '@material-ui/core'
import { TiTick } from 'react-icons/ti'

const DistrRetlr = () => {
    const classes = makeStyles()
    return (
        <>
            <Typography className={classes.ebrBodyTabHeader}>Distributor/Retailer Classification</Typography>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>SD/DT</p>
                            <p className={classes.ebrBodyCellContent}>10 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>RT</p>
                            <p className={classes.ebrBodyCellContent}>5 Points</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default DistrRetlr