import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import menurouts from '../routes/menuroutes'

const MainContent = () => {
    let navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken");

    const getRoutes = (routes) => {
        return routes.map((route, key) => {
        if (accessToken) {
            if (route.layout === "/admin") {
            return (
                <>
                    {
                        route.collapse === false ?
                            <Route path={route.path} key={key} element={<route.component />} />
                        :
                        route.children?.map((childroute,childkey)=>{
                            return <Route path={childroute.path} key={`${key}_${childkey}`} element={<childroute.component />} />
                        })
                        
                    }
                </>
            );
            }
        } else {
            navigate("/auth/login");
        }
        });
    };
    return (
        <Routes>{getRoutes(menurouts)}</Routes>
    )
}

export default MainContent