import React, { useState } from 'react'
import { Grid, Paper, Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import '../Stepper/stepper.css'
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReactSpeedometer from "react-d3-speedometer";
import { PDFDownloadLink, PDFViewer, Document, Page, } from "@react-pdf/renderer";
import { ECAPS_REPORT } from '../../../utils/header';
import Button from "../../controls/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BusinessStats from './BusinessStats';
import { BsHourglass, BsPeople, BsPieChart, BsFillRecordCircleFill, BsGraphUp } from "react-icons/bs"
import BRModalTwo from './BRModalTwo';
import { useSelector } from 'react-redux';
import ECAPSReport from './ECAPSReport';
import { FaDownload } from "react-icons/fa";

const BRModalOne = ({ open, onClose }) => {

    const moment = require("moment");
    const { buss_report_data } = useSelector((store) => store.loanApplication)
    const date = new Date().toLocaleDateString()
    const pdf = <ECAPSReport data={buss_report_data} />

    const theme = createTheme({
        palette: {
            secondary: {
                main: "#963460",
            },
        },
    });

    const [value, setValue] = useState("1");

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const textColor = "#AAA";

    //BRModalTwo

    const [brmTwo, setBrmTwo] = useState(false)

    const openBrmTwo = () => {
        setBrmTwo(true)
    }

    const closeBrmTwo = () => {
        setBrmTwo(false)
    }

    // Modal Variables
    const score = buss_report_data && buss_report_data.business_score
    const buss_stats = buss_report_data && buss_report_data.ecaps_business_report

    const dist_ret_details = buss_stats[0]
    const vp_details = buss_stats[1]
    const avg_buss_details = buss_stats[2]
    const buss_trend_details = buss_stats[3]
    const dist_status_details = buss_stats[4]

    return (
        <>
            <ThemeProvider theme={theme}>
                <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
                    <DialogTitle className="creditHeading">
                        {ECAPS_REPORT}
                    </DialogTitle>
                    <DialogContent style={{ height: "651px" }}>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                        <Grid container alignItems="stretch" spacing={2}>
                            <Grid item xs={6} style={{ display: "flex" }}>
                                <Card className="card-parent">
                                    <h1 className="congratHead">Congrats!</h1>
                                    <h3 className="creditHead">
                                        Your Impact Score Till{" "}
                                        {moment(date).format("DD MMMM YYYY")}
                                    </h3>
                                    <div className="speedometerParent">
                                        <div>
                                            <ReactSpeedometer
                                                needleHeightRatio={(score < 400 || score > 1000) ? 0 : 0.7}
                                                maxSegmentLabels={4}
                                                segments={5555}
                                                minValue={400}
                                                value={score}
                                                textColor={textColor}
                                                // width={400}
                                                ringWidth={47}
                                            />

                                            <div className="outercircle">
                                                <p className="value">{score}</p>
                                                <p className="text">{(score < 400) ? "Not Eligible" :
                                                    (score >= 400 && score < 600) ? "Poor" :
                                                        (score >= 600 && score < 800) ? "Good" :
                                                            (score >= 800 && score <= 1000) ? "Fair" :
                                                                (score > 1000) ? "Excellent" :
                                                                    ""
                                                }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px' }}>
                                        <PDFDownloadLink
                                            document={pdf}
                                            style={{ padding: '10px 20px', color: '#5A5DF6', border: '1px solid #5A5DF6', borderRadius: '8px', boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)' }}
                                            fileName={"ECAPS Business Report" + new Date().getTime() + ".pdf"}
                                        >
                                            {/* {({ blob, url, loading, error }) =>
                                                loading ? "Loading . . ." : "Download Report"
                                            } */}
                                            <FaDownload style={{ marginRight: '10px' }} />Download Report
                                        </PDFDownloadLink>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid item xs={6} style={{ display: "flex" }}>
                                <Card className="card-parent">
                                    <TabContext value={value}>
                                        <TabList
                                            onChange={handleTabChange}
                                            // centered
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                        // variant="fullWidth"
                                        >
                                            <Tab label="ECAPS Business Report" value="1" />
                                            {/* <Tab label="CIBIL" value="2" /> */}
                                        </TabList>

                                        <TabPanel value="1">
                                            <BusinessStats
                                                title={'Vintage Period'}
                                                value={vp_details.point}
                                                impact={`Impact : ${vp_details.impact}`}
                                                impactStatus={vp_details.value}
                                                onClick={openBrmTwo}
                                                startIcon={<BsHourglass className="cell-start-end-icon" />}
                                            />
                                            <BusinessStats
                                                title={'Active Status of Distributor'}
                                                value={dist_status_details.point}
                                                impact={`Impact : ${dist_status_details.impact}`}
                                                impactStatus={dist_status_details.value}
                                                onClick={openBrmTwo}
                                                startIcon={<BsFillRecordCircleFill className="cell-start-end-icon" />}
                                            />
                                            <BusinessStats
                                                title={'Business Trend'}
                                                value={buss_trend_details.point}
                                                impact={`Impact : ${buss_trend_details.impact}`}
                                                impactStatus={buss_trend_details.value}
                                                onClick={openBrmTwo}
                                                startIcon={<BsGraphUp className="cell-start-end-icon" />}
                                            />
                                            <BusinessStats
                                                title={'Monthly Average Business'}
                                                value={avg_buss_details.point}
                                                impact={`Impact : ${avg_buss_details.impact}`}
                                                impactStatus={avg_buss_details.value}
                                                onClick={openBrmTwo}
                                                startIcon={<BsPieChart className="cell-start-end-icon" />}
                                            />
                                            <BusinessStats
                                                title={"Distributor/ Retailer Classification"}
                                                value={dist_ret_details.point}
                                                impact={`Impact : ${dist_ret_details.impact}`}
                                                impactStatus={"SD"}
                                                onClick={openBrmTwo}
                                                startIcon={<BsPeople className="cell-start-end-icon" />}
                                            />
                                        </TabPanel>

                                        {/* <TabPanel value="2">CIBIL REPORT</TabPanel> */}
                                    </TabContext>
                                </Card>
                            </Grid>
                        </Grid>

                        <div className="backButtonParent">
                            <Button
                                text="Back"
                                size="small"
                                variant="outlined"
                                startIcon={<ArrowBackIosIcon />}
                                className="downloadReportButton"
                                onClick={onClose}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
            {
                brmTwo ? <BRModalTwo open={brmTwo} handleClose={closeBrmTwo} /> : null
            }
        </>
    )
}

export default BRModalOne