import React, { useEffect, useState } from "react";
import {
  TableRow,
  Box,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Container,
  makeStyles,
} from "@material-ui/core";
import useModalTable from "../Sharedfeatures/useModalTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ProgressSpinner from "../common/ProgressSpinner";

const useStyles = makeStyles({
  header: {
    color: "#963460",
    fontSize: "24px",
    position: "relative",
    left: "-126px",
    top: "-44px",
    padding: '0 30px'
  },
  closeIconButton: {
    position: "absolute",
    right: "-148px",
    top: "-34px",
  },
});

const TrancheModal = ({ open, onClose, tranche, trancheData, loading }) => {

  const moment = require("moment");
  const { TblContainer, TblHead } = useModalTable(trancheData, tranche);
  // const [data, setData] = useState(false);
  const classes = useStyles();

  // useEffect(() => {
  //   trancheData && trancheData.length != 0 ? setData(true) : setData(false);
  // }, [trancheData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "57%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    maxHeight: "70%",
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={classes.header}
          >
            <PageHeader header="Tranche Details" />
            <IconButton className={classes.closeIconButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <Container>
            <TblContainer>
              <TblHead />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align='center'>
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {trancheData && trancheData.length != 0 ? (
                    trancheData &&
                    trancheData.map((item, key) => {
                      key++;
                      return (
                        <TableRow key={key.toString()}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{item.trancheid}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          {/* <TableCell>{item.bank_reference_number}</TableCell> */}
                          <TableCell>{item.loan_amount}</TableCell>
                          <TableCell>{item.tranche_amount}</TableCell>
                          <TableCell>
                            {moment(item.expected_disbursal_date).format(
                              "DD MMMM YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(item.payback_date).format("DD MMMM YYYY")}
                          </TableCell>
                          <TableCell>{item.status_text}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        align="center"
                      >
                        No Data to Show
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </TblContainer>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default TrancheModal;
