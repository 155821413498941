import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'
export const getRepaymentsList = createAsyncThunk(
    'getrepaymentslist',
    async ({ pageNum, searchQry, fromDate, toDate }) => {
        const url = `/loans/repayment-list?page=${pageNum}&limit=10&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await DataService.get(url)
        return res.data;
    }
)
// export const generateExcel = createAsyncThunk(
//     "generateExcel",
//     async ({ fromDate, toDate }) => {
//         const url = `excel/repaymentList?fromDate=${fromDate}&toDate=${toDate}`;
//         const res = await DataService.get(url);
//         return res.data.data;
//     }
// );
//download Repayment excel
export const getRepaymentsDataExcel = createAsyncThunk(
    'RepaymentsDataExcel',
    async (data, thunkAPI) => {
        const { pageNum, searchQry, fromDate, toDate, totalCount } = data;
        try {
            const url = searchQry ?
                `/loans/repayment-list?page=1&limit=${totalCount}&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}` :
                `/loans/repayment-list?page=1&limit=${totalCount}&fromDate=${fromDate}&toDate=${toDate}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);
const initialState = {
    repaymentsList: [],
    totalCount: 0,
    excelDataSheet: [],
    repaymentDataExcel: [],
    isLoading: true
}
const repaymentsSlice = createSlice({
    name: 'repayment slice',
    initialState,
    extraReducers: {
        [getRepaymentsList.pending]: (state) => {
            state.isLoading = true
        },
        [getRepaymentsList.fulfilled]: (state, action) => {
            state.repaymentsList = action.payload.data
            state.totalCount = action.payload.totalItmes
            state.isLoading = false
        },
        [getRepaymentsList.rejected]: (state) => {
            state.isLoading = false
        },
        [getRepaymentsDataExcel.fulfilled]: (state, action) => {
            state.repaymentDataExcel = action.payload.data;
        },
        // [generateExcel.fulfilled]: (state, action) => {
        //     state.excelDataSheet = action.payload.data
        // }
    }
})
export default repaymentsSlice.reducer