import React, { useState, useEffect } from 'react'
import { TableBody, TableRow, TableCell, Toolbar, TextField, IconButton } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import { getIncompleteApplicationList } from '../../slice/loanapplication';
import ProgressSpinner from "../common/ProgressSpinner";
import { useMemo } from "react";
import debounce from "lodash/debounce";


const IncompleteApplications = () => {

    const sharedClasses = useSharedClasses();
    const [search, setSearch] = useState("");
    // console.log('Searchnames', search )

    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);

    const { incompApplications, totalCount, loading } = useSelector((store) => store.loanApplication);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(incompApplications, HeadCells.IncompleteApplicationsHead, totalCount);

    useEffect(() => {
        const data = {
        searchQry: search,
        pageNum: pageNo,
        };
        dispatch(getIncompleteApplicationList(data))
        setShowPageNtn(true)
    }, [dispatch, pageNo, search])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    return (
        <>
        <Toolbar className={sharedClasses.tabToolbar}>
            <TextField
            size="small"
            className={sharedClasses.searchTextBar}
            //value={search}
            onChange={debouncedResults}
            id="standard-bare"
            autoComplete="off"
            variant="outlined"
            placeholder="Search Name or RT/DT Code"
            InputLabelProps={{ shrink: false }}
            InputProps={{
                endAdornment: (
                <IconButton>
                    <SearchOutlined
                    className={sharedClasses.searchIconColour}
                    />
                </IconButton>
                ),
            }}
            />
        </Toolbar>

        <section className={sharedClasses.tabMainSection}>
            <TblContainer>
            <TblHead />
            {
                loading ? (
                <TableBody>
                    <TableRow>
                    <TableCell colSpan={10} align="center">
                        <ProgressSpinner />
                    </TableCell>
                    </TableRow>
                </TableBody>
                ) : (
                <TableBody>
                    {
                    incompApplications.length !== 0 ? (
                        incompApplications.map((item, key) => {
                        key++;
                        serialNo++;
                        return (
                            <TableRow key={item.id.toString()}>
                            <TableCell className={sharedClasses.tableCell}>
                                {serialNo}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.userid}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.name}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.fos.name}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.mobile}
                            </TableCell>
                            {/* <TableCell className={sharedClasses.tableCell}>
                                {item.email}
                            </TableCell> */}
                            <TableCell className={sharedClasses.tableCell}>
                                {item.ShortName}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.amount}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.tenure}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.loanapproval.currentStatus}
                                {/* (item.loanapproval.currentStatus == "Edited-Offer-Accepted") ? "Edited Offer Accepted" :
                                    (item.loanapproval.currentStatus == "Documentation-Step3-Completed") ? "Step 3 Completed" :
                                    (item.loanapproval.currentStatus == "Documentation-Step2-Completed") ? "Step 2 Completed" :
                                        (item.loanapproval.currentStatus == "Documentation-Step1-Completed") ? "Step 1 Completed" :
                                        (item.loanapproval.currentStatus == "Offer-edited") ? "Offer Edited" :
                                            ""
                                } */}
                            </TableCell>
                            </TableRow>
                        );
                        })
                    ) : (
                        <TableRow>
                        <TableCell
                            colSpan={10}
                            align="center"
                            className={sharedClasses.tableCell}
                        >
                            No Data to Show
                        </TableCell>
                        </TableRow>
                    )
                    }
                </TableBody>
                )
            }
            </TblContainer>
            {showPageNtn ? <TblPagination /> : ""}
        </section>
        </>
    )
}

export default IncompleteApplications