import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getChargesOverdue = createAsyncThunk(
    "chargesOverdue",
    async ({ pageNum, searchQry }) => {
        const url = `/charges?page=${pageNum}&limit=10&searchqry=${searchQry}`;
        const res = await DataService.get(url);
        return res.data;
    }
);

export const getChargeDetails = createAsyncThunk(
    "chargeDetails",
    async (id) => {
        const url = `/charges/${id}`;
        const res = await DataService.get(url);
        return res.data;
    }
)

export const getChargesListDropdown = createAsyncThunk(
    "ChargesListDropdown",
    async () => {
        const url = `/charges/all`;
        const res = await DataService.get(url);
        return res.data;
    }
)

export const createChargeDropdowns = createAsyncThunk(
    "dropdownOptions",
    async () => {
        const url = `/charges/template`;
        const res = await DataService.get(url);
        return res.data;
    }
)

export const createCharge = createAsyncThunk(
    "createCharge",
    async (data, thunkAPI) => {
        try {
            const url = `/charges`;
            const res = await DataService.post(url, data);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const editCharge = createAsyncThunk(
    "editCharge",
    async (data, thunkAPI) => {
        const {id} = data
        try {
            const url = `/charges/${id}`;
            delete data.id;
            const res = await DataService.put(url, data);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const reset_state = createAsyncThunk(
    "reset_state",
    async () => {
      return;
    }
  )
  
const initialState = {
    chargesList: [],
    chargeDetails: {},
    firstItem: {},
    setLoading: false,
    totalCount: '',
    success: '',
    message: '',
    errorMessage: '',
    //Dropdown states below
    currency: [],
    chargesAppliesTo: [],
    chargePaymentBy: [],
    chargeCalculation_loan: [],
    chargeCalculation_savings: [],
    chargeCalculation_client: [],
    chargeCalculation_shares: [],
    chargeTimeTypes_loan: [],
    chargeTimeTypes_savings: [],
    chargeTimeTypes_client: [],
    chargeTimeTypes_shares: [],
};

const chargesSlice = createSlice({
    name: 'charges',
    initialState,
    extraReducers: {
        [getChargesOverdue.pending]: (state) => {
            state.setLoading = true;
        },
        [getChargesOverdue.fulfilled]: (state, action) => {
            state.chargesList = action.payload.data;
            state.totalCount = action.payload.totalItmes;  
            state.setLoading = false;
        },
        [getChargesOverdue.rejected]: (state) => {
            state.setLoading = false;
        },
        [getChargeDetails.fulfilled]: (state, action) => {
            state.chargeDetails = action.payload.data;
            state.setLoading = false;
        },
        [getChargesListDropdown.fulfilled]: (state, action) => {
            state.chargesList = action.payload.data;
            state.firstItem = action.payload.data[0];
        },
        [createChargeDropdowns.fulfilled]: (state, action) => {
            state.currency = action.payload.data.currencyOptions;
            state.chargesAppliesTo = action.payload.data.chargeAppliesToOptions;
            state.chargePaymentBy = action.payload.data.chargePaymetModeOptions;
            state.chargeCalculation_loan = action.payload.data.loanChargeCalculationTypeOptions;
            state.chargeCalculation_savings = action.payload.data.savingsChargeCalculationTypeOptions;
            state.chargeCalculation_client = action.payload.data.clientChargeCalculationTypeOptions;
            state.chargeCalculation_shares = action.payload.data.shareChargeCalculationTypeOptions;
            state.chargeTimeTypes_loan = action.payload.data.loanChargeTimeTypeOptions;
            state.chargeTimeTypes_savings = action.payload.data.savingsChargeTimeTypeOptions;
            state.chargeTimeTypes_client = action.payload.data.clientChargeTimeTypeOptions;
            state.chargeTimeTypes_shares = action.payload.data.shareChargeTimeTypeOptions;
        },
        [createCharge.pending]: (state) => {
            state.setLoading = true;
        },
        [createCharge.fulfilled]: (state, action) => {
            state.setLoading = false;
            state.success = action.payload.success;
            state.message = "Charge Created Successfully";
        },
        [createCharge.rejected]: (state, error) => {
            state.setLoading = false;
            state.success = error.payload.success;
            state.errorMessage = error.payload.message;
        },
        [editCharge.pending]: (state) => {
            state.setLoading = true;
        },
        [editCharge.fulfilled]: (state, action) => {
            state.setLoading = false;
            state.success = action.payload.success;
            state.message = "Charge Edited Successfully";
        },
        [editCharge.rejected]: (state, error) => {
            state.setLoading = false;
            state.success = error.payload.success;
            state.errorMessage = error.payload.message;
        },
    },
    reducers: {
        resetSuccessState: (state) => {
            state.success = "";
            state.message = "";
            state.errorMessage = "";
        },
    }
})

export const { resetSuccessState } = chargesSlice.actions;
export default chargesSlice.reducer