import React from 'react'
import makeStyles from '../../../../Sharedfeatures/SharedClasses'
import Button from '../../../../controls/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PaymentHistoryBodyCell from './PaymentHistoryBodyCell';
import PaymentHistoryHeader from './PaymentHistoryHeader';
import { useSelector } from 'react-redux';

const PaymentHistory = () => {
  const classes = makeStyles()
  const { cibil_data } = useSelector((store) => store.loanApplication)

  const bank_details_array = cibil_data.data.cCRResponse.cIRReportDataLst[0].cIRReportData.retailAccountDetails
 

  return (
    <>
      <PaymentHistoryHeader />

      <div className={classes.paymentHistoryBodyCellsContainer}>

      {
        bank_details_array && bank_details_array.map((item) => (
          <PaymentHistoryBodyCell key={item.seq} name={item.institution} acno={item.accountNumber} status={item.accountStatus} upDate={item.dateReported}/>
        ))
      }

        {/* <PaymentHistoryBodyCell />
        <PaymentHistoryBodyCell />
        <PaymentHistoryBodyCell />
        <PaymentHistoryBodyCell />
        <PaymentHistoryBodyCell /> */}

        {/* <div className={classes.paymentHistoryBackButtonParent}>
          <Button
            text="Back"
            size="small"
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            className={classes.paymentHistoryBackButton}
          />
        </div> */}

      </div>
    </>
  )
}

export default PaymentHistory