import dashboardActiveIcon from '../assets/images/menu-icons/dashboard-active.svg'
import dashboardInactiveIcon from '../assets/images/menu-icons/dashboard-inactive.svg'
import loanProductsActiveIcon from '../assets/images/menu-icons/loan-products-active.svg'
import loanProductsInactiveIcon from '../assets/images/menu-icons/loan-products-inactive.svg'
import overdueChargesActiveIcon from '../assets/images/menu-icons/overdue-charges-active.svg'
import overdueChargesInactiveIcon from '../assets/images/menu-icons/overdue-charges-inactive.svg'
import preApprovedLoanOffersActiveIcon from '../assets/images/menu-icons/pre-approved-loan-offers-active.svg'
import preApprovedLoanOffersInactiveIcon from '../assets/images/menu-icons/pre-approved-loan-offers-inactive.svg'
import loanApplicationsActiveIcon from '../assets/images/menu-icons/loan-applications-active.svg'
import loanApplicationsInactiveIcon from '../assets/images/menu-icons/loan-applications-inactive.svg'

import loansGeneratedActiveIcon from '../assets/images/menu-icons/loans-generated-active.svg'
import loansGeneratedInactiveIcon from '../assets/images/menu-icons/loans-generated-inactive.svg'

import loansSanctionedActiveIcon from '../assets/images/menu-icons/loans-sanctioned-active.svg'
import loansSanctionedInactiveIcon from '../assets/images/menu-icons/loans-sanctioned-inactive.svg'

import loansDisbursedActiveIcon from '../assets/images/menu-icons/loans-disbursed-active.svg'
import loansDisbursedInactiveIcon from '../assets/images/menu-icons/loans-disbursed-inactive.svg'

import loanDefaultedActiveIcon from '../assets/images/menu-icons/loan-defaulted-active.svg'
import loanDefaultedInactiveIcon from '../assets/images/menu-icons/loan-defaulted-inactive.svg'

import loanClosedActiveIcon from '../assets/images/menu-icons/loan-closed-active.svg'
import loanClosedInactiveIcon from '../assets/images/menu-icons/loan-closed-inactive.svg'

import latestRepaymentsActiveIcon from '../assets/images/menu-icons/latest-repayments-active.svg'
import latestRepaymentsInactiveIcon from '../assets/images/menu-icons/latest-repayments-inactive.svg'

import upcomingRepaymentsActiveIcon from '../assets/images/menu-icons/upcoming-repayments-active.svg'
import upcomingRepaymentsInactiveIcon from '../assets/images/menu-icons/upcoming-repayments-inactive.svg'
import inProgressRepaymentsActiveIcon from '../assets/images/menu-icons/in-progress-repayments-active.svg'
import inProgressRepaymentsInactiveIcon from '../assets/images/menu-icons/in-progress-repayments-inactive.svg'

import tranchesCreatedActiveIcon from '../assets/images/menu-icons/tranches-created-active.svg'
import tranchesCreatedInactiveIcon from '../assets/images/menu-icons/tranches-created-inactive.svg'

import borrowersActiveIcon from '../assets/images/menu-icons/borrowers-active.svg'
import borrowersInactiveIcon from '../assets/images/menu-icons/borrowers-inactive.svg'

import virtualAccountActiveIcon from '../assets/images/menu-icons/virtual-account-active.svg'
import virtualAccountInactiveIcon from '../assets/images/menu-icons/virtual-account-inactive.svg'

import FinalDashboard from '../components/FinalDashboard/FinalDashboard'
import ProductList from '../components/Loans/ProductList'
import OverdueCharges from '../components/Charges/OverdueCharges'
import PreApprovedLoan from '../components/PreApprovedLoan/PreApprovedLoan'
import LoanAppParentTab from '../components/LoanApplication/LoanAppParentTab'
import LoanSanctioned from '../components/LoanSanctioned/LoanSanctioned'

import LoansDefaulted from '../components/LoansDefaulted/LoansDefaulted'
import LoansClosed from '../components/LoansClosed/LoansClosed'
import Repayment from '../components/Repayment/Repayment'
import UpcomingRepayments from '../components/UpcomingRepayments/UpcomingRepayments'
import TranchesList from '../components/TranchesList/TranchesList'
import BorrowersList from '../components/Borrower/BorrowersList'
import Logout from '../components/Login/Logout'
import LoanAvailed from '../components/LoanDisbursed/LoanDisbursed'
import AddLoan from '../components/Loans/AddLoan'
import CreateCharge from '../components/Charges/CreateCharge'
import LoanView from '../components/Loans/LoanView/LoanView'
import ChargeDetails from '../components/Charges/ChargeDetails'
import Dashboard from '../components/Dashboard/Dashboard'
import LoanAccountDetails from '../components/LoanSanctioned/LoanAccountDetails/LoanAccountDetails'
import LoanAccountStatement from '../components/LoanSanctioned/LoanStatement/LoanAccountStatement'
import VirtualAccount from '../components/VirtualAccount/VirtualAccount'
import InProgressRepayments from '../components/InProgressRepayments/InProgressRepayments'

const routs = [
    {
        collapse: false,
        path: "dashboard",
        layout: "/admin",
        name: 'Dashboard',
        menuicon: dashboardInactiveIcon,
        menuiconActive: dashboardActiveIcon,
        show: true,
        component : Dashboard
    },
    {
        collapse: true,
        path: "",
        layout: "/admin",
        name: 'Loan Products',
        menuicon: loanProductsInactiveIcon,
        menuiconActive: loanProductsActiveIcon,
        show: true,
        children : [
            {
                collapse: false,
                path: "loanList",
                layout: "/admin",
                name: 'Loan Products',
                menuicon: loanProductsInactiveIcon,
                menuiconActive: loanProductsActiveIcon,
                show: true,
                component : ProductList
            },
            {
                collapse: false,
                path: "charges",
                layout: "/admin",
                name: 'Overdue Charges',
                menuicon: overdueChargesInactiveIcon,
                menuiconActive: overdueChargesActiveIcon,
                show: true,
                component : OverdueCharges
            },
        ]
    },
    {
        collapse: false,
        path: "addloan",
        layout: "/admin",
        name: "Add New Loan",
        state: "add loan",
        key: "addloan",
        show: false,
        component: AddLoan
    },
    {
        collapse: false,
        path: "createcharge",
        layout: "/admin",
        name: "Create Charge",
        state: "create charge",
        key: "createcharge",
        show: false,
        component: CreateCharge
    },
    {
        collapse: false,
        path: "editloan/:id",
        layout: "/admin",
        name: "Edit Loan",
        state: "edit loan",
        key: "editloan",
        show: false,
        component: AddLoan
    },
    {
        collapse: false,
        path: "editcharge/:id",
        layout: "/admin",
        name: "Edit Charge",
        state: "edit charge",
        key: "editcharge",
        show: false,
        component: CreateCharge
    },
    {
        collapse: false,
        path: "viewdetails/:id",
        layout: "/admin",
        name: "View Loan Details",
        state: "view details",
        key: "viewdetails",
        show: false,
        component: LoanView
    },
    {
        collapse: false,
        path: "chargedetails/:id",
        layout: "/admin",
        name: "View Charge Details",
        state: "charge details",
        key: "chargedetails",
        show: false,
        component: ChargeDetails
    },
    {
        collapse: false,
        path: "preapprovedloanoffer",
        layout: "/admin",
        name: 'Pre Approved Loan Offers',
        menuicon: preApprovedLoanOffersInactiveIcon,
        menuiconActive: preApprovedLoanOffersActiveIcon,
        show: true,
        component : PreApprovedLoan
    },
    {
        collapse: false,
        path: "loanapplication",
        layout: "/admin",
        name: 'Loan Applications',
        menuicon: loanApplicationsInactiveIcon,
        menuiconActive: loanApplicationsActiveIcon,
        show: true,
        component : LoanAppParentTab
    },
    {
        collapse: true,
        path: "",
        layout: "/admin",
        name: 'Loans Generated',
        menuicon: loansGeneratedInactiveIcon,
        menuiconActive: loansGeneratedActiveIcon,
        show: true,
        children : [
            {
                collapse: false,
                path: "loansanctioned",
                layout: "/admin",
                name: 'Loans Sanctioned',
                menuicon: loansSanctionedInactiveIcon,
                menuiconActive: loansSanctionedActiveIcon,
                show: true,
                component : LoanSanctioned
            },
            {
                collapse: false,
                path: "loandisbursed",
                layout: "/admin",
                name: 'Loans Disbursed',
                menuicon: loansDisbursedInactiveIcon,
                menuiconActive: loansDisbursedActiveIcon,
                show: true,
                component : LoanAvailed
            },
            {
                collapse: false,
                path: "loandefaulted",
                layout: "/admin",
                name: 'Loan Defaulted',
                menuicon: loanDefaultedInactiveIcon,
                menuiconActive: loanDefaultedActiveIcon,
                show: true,
                component : LoansDefaulted
            },
            {
                collapse: false,
                path: "loanclosed",
                layout: "/admin",
                name: 'Loan Closed',
                menuicon: loanClosedInactiveIcon,
                menuiconActive: loanClosedActiveIcon,
                show: true,
                component : LoansClosed
            },
        ]
    },
    {
        collapse: false,
        path: "loansanctioned/loanaccountdetails/:clientId",
        layout: "/admin",
        name: 'Loan Statement',
        state: "LoanAccountDetails",
        key: "LoanAccountDetails",
        show: false,
        component: LoanAccountDetails,
    },
    {
        collapse: false,
        path: "/loansanctioned/loanaccountdetails/loanaccountstatement/:id/:clientId",
        layout: "/admin",
        name: 'Loan Statement',
        state: "LoanStatement",
        key: "loanstatement",
        show: false,
        component: LoanAccountStatement
      },
    {
        collapse: false,
        path: "repayment",
        layout: "/admin",
        name: 'Latest Repayments',
        menuicon: latestRepaymentsInactiveIcon,
        menuiconActive: latestRepaymentsActiveIcon,
        show: true,
        component : Repayment
    },
    {
        collapse: false,
        path: "inprogressrepayments",
        layout: "/admin",
        name: 'In Progress Repayments',
        menuicon: inProgressRepaymentsInactiveIcon,
        menuiconActive: inProgressRepaymentsActiveIcon,
        show: true,
        component : InProgressRepayments
    },

    {
        collapse: false,
        path: "upcomingrepayments",
        layout: "/admin",
        name: 'Upcoming Repayments',
        menuicon: upcomingRepaymentsInactiveIcon,
        menuiconActive: upcomingRepaymentsActiveIcon,
        show: true,
        component : UpcomingRepayments
    },
    {
        collapse: false,
        path: "tranchescreated",
        layout: "/admin",
        name: 'Tranches Created',
        menuicon: tranchesCreatedInactiveIcon,
        menuiconActive: tranchesCreatedActiveIcon,
        show: true,
        component : TranchesList
    },
    {
        collapse: false,
        path: "virtual-account",
        layout: "/admin",
        name: 'Virtual Acc. Balance',
        menuicon: virtualAccountInactiveIcon,
        menuiconActive: virtualAccountActiveIcon,
        show: true,
        component : VirtualAccount
    },
    {
        collapse: false,
        path: "borrowers",
        layout: "/admin",
        name: 'Borrowers',
        menuicon: borrowersInactiveIcon,
        menuiconActive: borrowersActiveIcon,
        show: true,
        component : BorrowersList
    },
    // {
    //     collapse: false,
    //     path: "logout",
    //     layout: "/admin",
    //     name: 'Logout',
    //     menuicon: logoutInactiveIcon,
    //     menuiconActive: logoutActiveIcon,
    //     show: true,
    //     component : Logout
    // },
    
]

export default routs