import { Select, Skeleton } from 'antd'
import React, { useState } from 'react'
import './repayments.css'
import RepaymentsCards from './RepaymentsCards'
import RepaymentsChart from './RepaymentsChart'
import { useDispatch, useSelector } from 'react-redux'
import { getallmonth, getcommonapidata } from '../../../slice/commonslice'
import { getRepayments, getRepaymentsData } from '../../../slice/DashboardSlice/repaymentsSlice'
import { useEffect } from 'react'
import { Suspense } from 'react'
import { lazy } from 'react'
const DpdSection = lazy(()=> import('./DpdSection')) 
const month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
const date = new Date()
const Repayments = () => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(month[date.getMonth()]);
    const {isLoading, repaymentsData} = useSelector(getRepaymentsData)
    const {commonFilterStates, allMonth} = useSelector(getcommonapidata)
    const dispatch = useDispatch();
    const selectProps = {
        value,
        options,
        onChange: (newValue) => {
          setValue(newValue);
          dispatch(getRepayments({state:commonFilterStates,month:newValue}))

        },
        placeholder : 'Select Month'
    };
    useEffect(()=>{
        const postValue = {
            state:commonFilterStates, month: `${value === 'Select Month' ? '' : value}`
        }
        if(!repaymentsData){
            dispatch(getRepayments(postValue))
        }
    },[repaymentsData])
    useEffect(()=>{
        const postValue = {
            state:commonFilterStates, month: `${value === 'Select Month' ? '' : value}`
        }
        dispatch(getRepayments(postValue))
    },[commonFilterStates])
    useEffect(()=>{
        if(!allMonth){
            dispatch(getallmonth())
        }else{
            const newoptions = [];
            allMonth.map((item)=>{
              return newoptions.push({
                label:item.monthName,
                value:item.monthValue
              })
            })
            setOptions(newoptions)
        }
    },[allMonth])
    return (
        <div className="displayflexcol">
            <h2 className="section-title">Repayments</h2>
            <div className="card-wrap repayments-card">
                <div className="displayflexcol">
                    <div className="filter-select">
                        <Select {...selectProps} showArrow className='select-month'/>
                    </div>
                    <div className="displayflex repayments-chart-wrap">
                        {
                            isLoading || !repaymentsData? 
                            <Skeleton active/>
                            :
                            <>
                                <RepaymentsCards cardData={repaymentsData?.card_data}/>
                                <RepaymentsChart graphData={repaymentsData?.graphData}/>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Suspense fallback={<Skeleton active/>}>
                <DpdSection/>
            </Suspense>
        </div>
    )
}

export default Repayments