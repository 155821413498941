import { makeStyles } from "@material-ui/core";

const tranchesStyle = makeStyles({
    modalwrap: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxHeight:700,
        backgroundColor:'#ffffff',
        borderRadius: '15px',
        padding : '30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    iconwrap : {
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    mainContentWrap : {
        display :'flex',
        flexDirection : 'column',
        gap : '30px',
        justifyContent : 'center',
        alignItems : 'center',
        width: 400
    },
    viewMainContentWrap:{
        display :'flex',
        flexDirection : 'column',
        gap : '30px',
        justifyContent : 'center',
        alignItems : 'center',
        width: '100%'
    },
    mainTitle : {
        color: '#963460',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '36px',
        letterSpacing: '0.18px',
    },
    buttonWrap : {
        display : 'flex',
        justifyContent : 'space-between',
        alignItems : 'center',
        width:'100%'
    },
    cancelBtn : {
        display: 'inline-flex',
        minWidth: '64px',
        padding: '8px 20.941px 8px 21.649px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        background: '#ECECEC',
        border:'none',
        cursor:'pointer',
        "&:hover": {
            background: "#c3bebe",
        },
    },
    confirmBtn : {
        display: 'inline-flex',
        height: '42.25px',
        minWidth: '64px',
        padding: '8px 20.697px 8px 21.693px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '4px',
        background: '#3F51B5',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        border:'none',
        cursor:'pointer',
        "&:hover": {
            background: "#041a97",
        },
    },
    close : {
        color: '#585858',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '26.25px',
        letterSpacing: '0.429px',
        textTransform: 'uppercase',
    },
    confirm : {
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '26.25px',
        letterSpacing: '0.429px',
        textTransform: 'uppercase',
    },
    formContentWrap : {
        display :'flex',
        flexDirection : 'column',
        gap : '30px',
        justifyContent : 'center',
        alignItems : 'center',
        width: '100%'
    },
    gridContainer:{
        maxHeight:600,
        overflowY :'scroll'
    },
    viewLabel:{
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'Arial',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.15px',
    },
    viewValue:{
        color: '#444445',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.15px',
        wordBreak : 'break-word'
    },
})

export default tranchesStyle