import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import { Typography, Grid } from '@material-ui/core'
import { TiTick } from 'react-icons/ti'
import { ImCross } from 'react-icons/im'

const BusinessTrend = () => {
    const classes = makeStyles()
    return (
        <>
            <Typography className={classes.ebrBodyTabHeader}>Business Trend</Typography>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Growing above 80%</p>
                            <p className={classes.ebrBodyCellContent}>10 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Growing above 50%</p>
                            <p className={classes.ebrBodyCellContent}>5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Growing above 30%</p>
                            <p className={classes.ebrBodyCellContent}>2.5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.violetCircle}>
                                <TiTick color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Growing above 5%</p>
                            <p className={classes.ebrBodyCellContent}>1 Point</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.redCircle}>
                                <ImCross color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Not Applicable</p>
                            <p className={classes.ebrBodyCellContent}>0.5 Points</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={classes.ebrBodyCells}>
                        <div className={classes.ebrBodyCellLeft}>
                            <div className={classes.redCircle}>
                                <ImCross color='#ffffff' size="20px" />
                            </div>
                        </div>
                        <div className={classes.ebrBodyCellRight}>
                            <p className={classes.ebrBodyCellContent}>Falling</p>
                            <p className={classes.ebrBodyCellContent}>-1 Points</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default BusinessTrend