import { IconButton } from '@material-ui/core'
import React from 'react'

const CloseIcon = () => {
    return (
        <IconButton>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M10.9699 10.9301C10.7826 11.1163 10.5291 11.2209 10.2649 11.2209C10.0007 11.2209 9.74728 11.1163 9.55992 10.9301L1.01992 2.49009C0.926191 2.39713 0.851796 2.28653 0.801028 2.16467C0.750259 2.04281 0.724121 1.9121 0.724121 1.78009C0.724121 1.64808 0.750259 1.51737 0.801028 1.39551C0.851796 1.27365 0.926191 1.16305 1.01992 1.07009C1.20728 0.883839 1.46073 0.779297 1.72492 0.779297C1.9891 0.779297 2.24256 0.883839 2.42992 1.07009L10.9699 9.51009C11.0636 9.60305 11.138 9.71365 11.1888 9.83551C11.2396 9.95737 11.2657 10.0881 11.2657 10.2201C11.2657 10.3521 11.2396 10.4828 11.1888 10.6047C11.138 10.7265 11.0636 10.8371 10.9699 10.9301Z" fill="#666666"/>
            <path opacity="0.3" d="M2.42989 11.0006C2.23893 11.1943 1.97889 11.3041 1.70696 11.3059C1.43503 11.3078 1.1735 11.2016 0.97989 11.0106C0.786282 10.8197 0.67646 10.5596 0.674585 10.2877C0.67271 10.0158 0.778934 9.75425 0.96989 9.56064L9.50989 1.00064C9.70085 0.807034 9.96089 0.697212 10.2328 0.695337C10.5047 0.693462 10.7663 0.799686 10.9599 0.990642C11.1535 1.1816 11.2633 1.44164 11.2652 1.71357C11.2671 1.9855 11.1608 2.24703 10.9699 2.44064L2.42989 11.0006Z" fill="#666666"/>
            </svg>
        </IconButton> 
    )
}

export default CloseIcon