import { http, instance } from '../http-common'
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const get = (url) => {
    return http.get(url)
}

const post = (url, data) => {
    return http.post(url, data)
}

const put = (url, data) => {
    return instance.put(url, data);
}

const login = (username, password) => {
    return axios
      .post(API_URL + "users/login/superadmin", {
        username: username,
        password: password,
      })
      .then((response) => {
        if (response.data.data.token) {
          localStorage.setItem("accessToken", response.data.data.token);
        }
        return response.data;
      });
  };

  const logout = (url) => {
    return http.post(url);
  }

const DataService = {
    get,
    post,
    put,
    login,
    logout,
};

export default DataService;