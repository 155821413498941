import React, { useEffect } from "react";
import { DEFAULTED_HEADER } from "../../utils/graphHeadings";
import "./FinalDashboard.css";
import GraphHeader from "./GraphHeader";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
import useYAxisFormatter from "../Sharedfeatures/useYAxisFormatter";
import { useDispatch, useSelector } from "react-redux";
// import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import { getLoanDefaultedData } from "../../slice/finaldashboard";
import ProgressSpinner from "../common/ProgressSpinner";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, ComposedChart, ZAxis } from 'recharts';
import { Card } from "@material-ui/core";

const Defaulted = ({year}) => {
  const { dataFormater } = useYAxisFormatter();
  // const { CustomTooltip } = useCustomTooltip();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoanDefaultedData(year));
  }, [year, dispatch]);
  const { loan_defaulted, loan_defaulted_totalVolume, defaultLoader } = useSelector((store) => store.finaldashboard);

  const loan_defaulted_asc = [...loan_defaulted].sort((a, b) => a.dpd - b.dpd);

  let data = [];
  loan_defaulted_asc && loan_defaulted_asc.map((obj) => {
    let cdata = {
      name: obj.dpd,
      value: Number(obj.count),
      volume: obj.volume
    };
    data.push(cdata);
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <>
          <div>
            <Card style={{ padding: '7px 12px' }}>
              {/* <h4>{`DPD : ${payload[0].value}`}</h4> */}
              <h4>{`Count : ${payload[1].value}`}</h4>
              <h4>{`Volume : ${Number(payload[0].payload.volume).toFixed(2)}`}</h4>
            </Card>
          </div>
        </>
      );
    }
    else {
      return null;
    }
  };

  const formatXAxis = (tickItem) => {
    if (tickItem <= 90) {
      return tickItem
    }
    else {
      return 'NPA'
    }
  }

  return (
    <div className="second-row-card">
      <GraphHeader header={DEFAULTED_HEADER} subheader={Number(Number(loan_defaulted_totalVolume).toFixed(2)).toLocaleString()} />
      {
        defaultLoader && (
          <div className="dash_spinner_parent">
            <ProgressSpinner />
          </div>
        )
      }

      <ResponsiveContainer width="90%" height="65%">
        {/* <AreaChart
          width={500}
          height={180}
          data={data}
          margin={{ left: 10, right: 30, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis dataKey="name" axisLine={true} label={{ value: "Due Past Date", position: "insideBottomCenter", dy: 30 }} />
          <YAxis axisLine={false} tickFormatter={dataFormater} label={{ value: 'No of Defaults', angle: -90, position: 'insideLeft', dy: 30 }} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          {
            !defaultLoader && <Area
              type="monotone"
              dataKey="value"
              stackId="1"
              stroke="#7C4DFF"
              fill="#FF8A65"
              activeDot={{ fill: '#7C4DFF', stroke: '#fff', strokeWidth: 3, r: 5, className: "boxShadow" }}
              dot={{ fill: '#7C4DFF', stroke: '#fff', strokeWidth: 2, r: 5, className: "boxShadow" }}
            />
          }

        </AreaChart> */}

        <ComposedChart
          width={400}
          height={400}
          margin={{ left: 10, right: 30, bottom: 20 }}
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            dataKey="name"
            type="number"
            ticks={[0, 15, 30, 45, 60, 75, 90, 105]}
            tickFormatter={formatXAxis}
            domain={[0, 105]}
            allowDataOverflow={true}
            axisLine={true}
            label={{ value: "Due Past Date", position: "insideBottomCenter", dy: 30 }} />
          <YAxis dataKey="value" axisLine={false} allowDecimals={false} tickFormatter={dataFormater} label={{ value: 'No of Defaults', angle: -90, position: 'insideLeft', dy: 30 }} />
          <ZAxis range={[150, 150]} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Area type="monotone" dataKey="value" fill="#ffe3da" stroke="#9E00FF" />
          <Scatter dataKey="value" fill="#9E00FF" />
        </ComposedChart>

      </ResponsiveContainer>
    </div>
  );
};

export default Defaulted;
