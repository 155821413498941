import React,{ useState,useEffect } from 'react'
import loanUser from '../../../assets/images/dashboard-icons/loan-user.svg'
import creditLine from '../../../assets/images/dashboard-icons/credit-line.svg'
import revenue from '../../../assets/images/dashboard-icons/revenue.svg'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import './welcomecard.css'
import { useDispatch, useSelector } from 'react-redux';
import { getAllDashboardData, getbusinesstrend } from '../../../slice/DashboardSlice/welcomeSlice';
import { Skeleton } from 'antd';
import { formatRupees } from '../../../utils/formatRupees';

const WelcomeCard = () => {
    const [greetingMsg, setGreetingMsg] = useState('')
    const [iconClick, setIconClick] = useState(false)
    const [monthData, setMonthData] = useState('current')
    const {isLoading, businessTrendData} = useSelector(getAllDashboardData)
    const dispatch = useDispatch();
    const onButtonClick = (val)=>{
        setMonthData(val)
        dispatch(getbusinesstrend(val))
    }
    useEffect(()=>{
        let timeNow = new Date().getHours();
        let greeting = timeNow >= 5 && timeNow < 12 ? "Good Morning": timeNow >= 12 && timeNow < 18 ? "Good Afternoon" : "Good Evening";
        setGreetingMsg(greeting)
    },[])
    useEffect(()=>{
        if(!businessTrendData){
            dispatch(getbusinesstrend(monthData))
        }
    },[monthData])
    return (
        <div className="section-wrap displayflexcol">
            <div className="welcome-card card-wrap">
                <div className="wl-title">{greetingMsg} <span>ATLAS ADMIN</span></div>
                <div className="wl-subtitle">Let’s dive into the analytics that make your business better!</div>
            </div>
            <div className="welcome-trend-card card-wrap displayflexcol">
                <div className="header-wrap displayflex">
                    <div className="header-title">
                        <h2 className='maintitle'>{`${businessTrendData?.title ? 'Business Trend for '+businessTrendData?.title : 'Business Trend'}`}</h2>
                    </div>
                    <div className="header-actions displayflex">
                        <button className={`btns displayflex ${monthData === 'current' ? 'active-month' : ''}`} onClick={()=>onButtonClick('current')}>This Month</button>
                        <button className={`btns displayflex ${monthData === 'previous' ? 'active-month' : ''}`} onClick={()=>onButtonClick('previous')}>Previous Month</button>
                    </div>
                </div>
                <div className="welcome-cards cards">
                    {
                        isLoading || !businessTrendData ? 
                        <Skeleton active/>
                        :
                        <>
                            <div className="card displayflex loan-user">
                                <div className="displayflex full-width">
                                    <div className="displayflexcol contentwrap">
                                        <p className="card-title">{businessTrendData?.active_loan_user}</p>
                                        <span className="card-subtitle">Active Loan Users</span>
                                    </div>
                                    <div className="icon">
                                        <img src={loanUser} alt="Active Loan Users" />
                                    </div>
                                </div>
                            </div>
                            <div className="card displayflex credit-line">
                                <div className="displayflex full-width">
                                    <div className="displayflexcol contentwrap">
                                        <p className="card-title"><CurrencyRupeeIcon/>{formatRupees(businessTrendData?.active_credit_line)} </p>
                                        <div className="displayflex full-width">
                                            <span className="card-subtitle">Active Credit Line</span>
                                            <div className="" onClick={()=> setIconClick(!iconClick)}>
                                                {
                                                    !iconClick ? 
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#4F37F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15 11L12 14L9 11" stroke="#4F37F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M12.4199 3.48438C7.44936 3.48438 3.41992 7.51381 3.41992 12.4844C3.41992 17.4549 7.44936 21.4844 12.4199 21.4844C17.3905 21.4844 21.4199 17.4549 21.4199 12.4844C21.4199 7.51381 17.3905 3.48438 12.4199 3.48438Z" stroke="#4F37F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M9.41992 13.4844L12.4199 10.4844L15.4199 13.4844" stroke="#4F37F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                }
                                            </div>
                                        </div>
                                        {
                                            iconClick &&
                                                <>
                                                    <p className="card-title"><CurrencyRupeeIcon/>{formatRupees(businessTrendData?.credit_line_use)} </p>
                                                    <span className="card-subtitle">Credit Line in use</span>
                                                </>

                                        }
                                    </div>
                                    <div className="icon">
                                        <img src={creditLine} alt="Active Credit Line" />
                                    </div>
                                </div>
                            </div>
                            <div className="card displayflex revenue">
                                <div className="displayflex full-width">
                                    <div className="displayflexcol contentwrap">
                                        <p className="card-title"><CurrencyRupeeIcon/>{formatRupees(businessTrendData?.total_revenue)}</p>
                                        <span className="card-subtitle">Total Revenue</span>
                                    </div>
                                    <div className="icon">
                                        <img src={revenue} alt="Total Revenue" />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(WelcomeCard)