import React, { useEffect, useState } from "react";
import { Button, TableBody, TableCell, TableRow } from "@material-ui/core";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import { DatePicker } from 'antd';
import { TXT_HOME, TXT_UPCOMING_REPAYMENTS } from "../../utils/header";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import { getUpRepaymentsDataExcel, getupcomingRepayments } from "../../slice/upcomingRepayments";
import moment from "moment";
import useExcel from "../common/useExcel";
import DownloadIcon from '@mui/icons-material/Download';
import { disableDates } from "../../utils/disableDates";

const UpcomingRepayments = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;


    const sharedClasses = useSharedClass();
    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const excelData = useExcel();


    const { upcRepList, totalCount, isLoading, } = useSelector((store) => store.upcomingRepayments);
    const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(upcRepList, HeadCells.upcomingRepaymentsHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    useEffect(() => {
        const dates = {
            pageNo,
            fromDate,
            toDate
        }
        dispatch(getupcomingRepayments(dates))
        setShowPageNtn(true);
    }, [fromDate, toDate, pageNo, dispatch])


    let serialNo = PAGE_SIZE * slNo;

    const downloadExcelsheet = ()=>{
        const data = {
            pageNo,
            fromDate,
            toDate,
            totalCount
        };
        dispatch(getUpRepaymentsDataExcel(data))
        .then(res=>{
            if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "RT/DT Code" : item.userid,
                    "Tranche Id" : item.trancheid,
                    "Name" : item.name,
                    "Tranche Amount" : item.tranche_amount,
                    "Loan Amount" : item.loan_amount,
                    "Payable" : item.payableAmount,
                    "Payback Date" : moment(item.payback_date).format('DD MMMM YYYY'),
                    "Status" : item.status_text,
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'UpcomingRepaymentListSheet', 'upcomingrepaymentlist.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_UPCOMING_REPAYMENTS}
                        subheader={`${TXT_HOME}/${TXT_UPCOMING_REPAYMENTS}`}
                    />

                    <article className={sharedClasses.searchAddParent}>
                        <div className={sharedClasses.lpsearchBar} />

                        <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                            <RangePicker
                                placeholder={["From Date", "To Date"]}
                                defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                                format={dateFormat}
                                className={sharedClasses.datePicker}
                                onChange={onDateChange}
                                disabledDate={disableDates}
                                allowClear = {false}

                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                className={sharedClasses.download}
                                onClick={downloadExcelsheet}
                                disabled = {totalCount === 0 ? true : false}
                                endIcon={<DownloadIcon />}
                            >
                                Download Ledger
                            </Button>
                        </div>
                    </article>

                    <section className={sharedClasses.mainSection}>
                        <TblContainer>
                            <TblHead />

                            {
                                isLoading ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align='center'>
                                                <ProgressSpinner />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {
                                            (upcRepList.length !== 0) ?
                                                (
                                                    upcRepList &&
                                                    upcRepList.map((item, key) => {
                                                        key++;
                                                        serialNo++;
                                                        return (
                                                            <TableRow key={key.toString()}>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {serialNo}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.userid}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.trancheid}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.tranche_amount}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.loan_amount}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.payableAmount}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {moment(item.payback_date).format('DD MMMM YYYY')}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.status_text}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={8} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                                                    </TableRow>
                                                )
                                        }
                                    </TableBody>
                                )
                            }

                        </TblContainer>
                        {showPageNtn ? <TblPagination /> : ""}
                    </section>

                </div>
            </div>
        </>
    )
}

export default UpcomingRepayments