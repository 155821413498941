import React, { useState } from "react";
import { Grid, Paper, Card } from "@mui/material";
import Button from "../../controls/Button";
import "./stepper.css";
import { CREDIT_REPORT, ECAPS_REPORT } from "../../../utils/header";
import { resetStatus } from "../../../slice/loanapplication";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import ReactSpeedometer from "react-d3-speedometer";
import Equifax from "./Equifax";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import makeStyles from "../../Sharedfeatures/SharedClasses";
import DownloadIcon from "@mui/icons-material/Download";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CibilScoreModal from "../CreditReport/ModalTwo/CibilScoreModal";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CreditReport from "./CreditReport";
import BRModalOne from "../ECaps Business Report/BRModalOne";
import { FaDownload } from "react-icons/fa";

const CreditReports = (props) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('1');
  const { cibil_data } = useSelector((store) => store.loanApplication);

  const moment = require("moment");
  const pdf = <CreditReport data={cibil_data} />;



  // API DATAS.............................................................
  const score_data = cibil_data && cibil_data.data && cibil_data.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.scoreDetails[0]?.value;
  const credit_enquiries_total = cibil_data && cibil_data.data && cibil_data.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.enquirySummary?.total;
  const total_active_accounts = cibil_data && cibil_data.data && cibil_data.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.retailAccountsSummary?.noOfActiveAccounts;
  const enq_date = cibil_data && cibil_data.data && cibil_data.data?.cCRResponse?.cIRReportDataLst[0]?.cIRReportData?.enquirySummary?.recent;



  const handleClick = () => {
    // dispatch(resetStatus(false))
    setOpen(true);
  };

  const handleClose = () => {
    // dispatch(resetStatus(false))
    setOpen(false);
  };

  const [cibilOpen, setCibilOpen] = useState(false);

  // const openCblModal = () => {
  //   setCibilOpen(true)
  // }

  const closeCblModal = () => {
    setCibilOpen(false);
  };

  const openPaymentHistory = () => {
    setCibilOpen(true);
    setTab('1')
  };

  const openCreditCardUtilization = () => {
    setCibilOpen(true);
    setTab('2')
  }

  const openCreditHistory = () => {
    setCibilOpen(true);
    setTab('3')
  }

  const openTotalAccounts = () => {
    setCibilOpen(true);
    setTab('4')
  }

  const openCreditEnquiries = () => {
    setCibilOpen(true);
    setTab('5')
  }

  const [brmOpen, setBrmOpen] = useState(false)

  const openBrModal = () => {
    setBrmOpen(true)
  }

  const closeBrModal = () => {
    setBrmOpen(false)
  }

  const textColor = "#AAA";

  const [value, setValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#963460",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={{ padding: "50px 100px" }}>
          <Grid item xs={4}>
            <span className="stepper-label">{CREDIT_REPORT}</span>
          </Grid>
          <Grid item xs={4}>
            <Button
              text={"Review"}
              color="primary"
              className="stepperButton"
              onClick={handleClick}
            ></Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ padding: "0 100px 50px 100px" }}>
          <Grid item xs={4}>
            <span className="stepper-label">{ECAPS_REPORT}</span>
          </Grid>
          <Grid item xs={4}>
            <Button
              text={"Review"}
              color="primary"
              className="stepperButton"
              onClick={openBrModal}
            ></Button>
          </Grid>
        </Grid>

        {open ? (
          <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
              <DialogTitle className="creditHeading">
                {CREDIT_REPORT}
              </DialogTitle>
              <DialogContent style={{ height: "651px" }}>
                <DialogContentText id="alert-dialog-description"></DialogContentText>
                <Grid container alignItems="stretch" spacing={2}>
                  <Grid item xs={6} style={{ display: "flex" }}>
                    <Card className="card-parent">
                      <h1 className="congratHead">Congrats!</h1>
                      <h3 className="creditHead">
                        Your Credit Score For{" "}
                        {moment(enq_date).format("DD MMMM YYYY")}
                      </h3>
                      <div className="speedometerParent">
                        <div>
                          <ReactSpeedometer
                            needleHeightRatio={(score_data < 400 || score_data > 1000) ? 0 : 0.7}
                            maxSegmentLabels={4}
                            segments={5555}
                            minValue={400}
                            value={score_data}
                            textColor={textColor}
                            // width={400}
                            ringWidth={47}
                          />

                          <div className="outercircle">
                            <p className="value">{score_data}</p>
                            <p className="text">{(score_data < 400) ? "Not Eligible" :
                              (score_data >= 400 && score_data < 600) ? "Poor" :
                                (score_data >= 600 && score_data < 800) ? "Good" :
                                  (score_data >= 800 && score_data <= 1000) ? "Fair" :
                                    (score_data > 1000) ? "Excellent" :
                                      ""
                            }</p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px' }}
                      >
                        <PDFDownloadLink
                          document={pdf}
                          style={{ padding: '10px 20px', color: '#5A5DF6', border: '1px solid #5A5DF6', borderRadius: '8px', boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)' }}
                          fileName={"Credit Report" + new Date().getTime() + ".pdf"}
                        >
                          {/* {({ blob, url, loading, error }) =>
                            loading ? "Loading . . ." : "Download Report"
                          } */}
                          <FaDownload style={{ marginRight: '10px' }} />Download Report
                        </PDFDownloadLink>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={6} style={{ display: "flex" }}>
                    <Card className="card-parent">
                      <TabContext value={value}>
                        <TabList
                          onChange={handleTabChange}
                          centered
                          textColor="secondary"
                          indicatorColor="secondary"
                          variant="fullWidth"
                        >
                          <Tab label="Equifax" value="1" />
                          {/* <Tab label="CIBIL" value="2" /> */}
                        </TabList>

                        <TabPanel value="1">
                          <Equifax
                            title={"Payment History"}
                            value={""}
                            // impact={"Impact :"}
                            impactStatus={"Payment on time"}
                            onClick={openPaymentHistory}
                          />
                          <Equifax
                            title={"Credit Card Utilization"}
                            value={""}
                            // impact={"Impact :"}
                            impactStatus={"Credit limit used"}
                            onClick={openCreditCardUtilization}
                          />
                          <Equifax
                            title={"Credit History"}
                            value={""}
                            // impact={"Impact :"}
                            impactStatus={"Age of oldest account"}
                            onClick={openCreditHistory}
                          />
                          <Equifax
                            title={"Total Accounts"}
                            value={total_active_accounts}
                            // impact={"Impact :"}
                            impactStatus={"Active accounts"}
                            onClick={openTotalAccounts}
                          />
                          <Equifax
                            title={"Credit Enquiries"}
                            value={credit_enquiries_total}
                            // impact={"Impact :"}
                            impactStatus={"Total Enquiries"}
                            onClick={openCreditEnquiries}
                          />
                        </TabPanel>

                        <TabPanel value="2">CIBIL REPORT</TabPanel>
                      </TabContext>
                    </Card>
                  </Grid>
                </Grid>

                <div className="backButtonParent">
                  <Button
                    text="Back"
                    size="small"
                    variant="outlined"
                    startIcon={<ArrowBackIosIcon />}
                    className="downloadReportButton"
                    onClick={handleClose}
                  />
                </div>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          ""
        )}
      </ThemeProvider>
      {cibilOpen ? (
        <CibilScoreModal open={cibilOpen} onClose={closeCblModal} tabValue={'1'} />
      ) : null}

      {
        brmOpen ? (<BRModalOne open={brmOpen} onClose={closeBrModal} />) : null
      }


    </>
  );
};

export default CreditReports;
