import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import DataService from "../services/DataService";


const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "users/login/superadmin",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await DataService.login(username, password);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async () => {
    const url = `users/logout/superadmin`
    const res = await DataService.logout(url)
    return res.data.data
  });

// const initialState = user
//   ? { isLoggedIn: true, user }
//   : { isLoggedIn: false, user: null };

const initialState = {
  user: [],
  isLoggedIn: [],
  message: '',
  flag: true
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.message = ""
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.message = action.payload;
      state.flag = !state.flag
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;