import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "../services/DataService";
export const getVirtualAccountList = createAsyncThunk('getVirtualAccountList',
    async ({ pageNum, searchQry }) => {
        const url = `/accounts/balance?page=${pageNum}&limit=10&searchqry=${searchQry}`;
        //const url = `https://admin.atlasnbfc.in/api/v1/excro/check-borrowers-balance?page=${pageNum}&limit=10&searchqry=${searchQry}`
        const res = await DataService.get(url)
        return res.data;
    }
)
export const reverseAmount = createAsyncThunk('reverseAmount',
    async (data) => {
        const url = `/accounts/reverse-balance`
        const res = await DataService.post(url,data)
        return res.data;
    }
)

const initialState = {
    isLoading: true,
    virtualAccountList: [],
    totalCount: ''
}

const virtualAccountSlice = createSlice({
    name: 'virtualAccountSlice',
    initialState,
    extraReducers: {
        [getVirtualAccountList.pending]: (state) => {
            state.isLoading = true;
        },
        [getVirtualAccountList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.virtualAccountList = action.payload.data
            state.totalCount = action.payload.totalItmes
        },
        [getVirtualAccountList.rejected]: (state) => {
            state.isLoading = false;
        },
    }
})

export default virtualAccountSlice.reducer