import React, { useState, useEffect } from "react";
import {
  Select as MuiSelect,
  MenuItem,
  Button,
  Box,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Checkbox,
  Radio,
  FormControl
} from "@material-ui/core";
import Icon from "../common/Icon";
import Input from "../controls/Input";
import { star } from "../../utils/index";
import LoanSettingsOptions from "./Options/LoanSettingsOptions";
import {
  AMORTIZATION,
  INTEREST_METHOD,
  INT_CALC_PERIOD,
  REPAYMENT_STRATEGY,
  MORATORIUM,
  INTEREST_FREE_PERIOD,
  ARREARS_TOLERANCE,
  DAYS_IN_YEAR,
  DAYS_IN_MONTH,
  OVERDUE_DAYS,
  NPA_DAYS,
  VAR_INSTALLMENT,
  INT_RECALCULATION,
  FREQUENCY_RECALCULATE_OUTSTANDING,
  FREQUENCY_INTERVAL_RECALCULATION,
  MAX_DISBURSEMENTS,
  MAX_OUTSTANDING_BAL,
  ENABLE_MULTIDISBURSAL,
} from "../../utils/info";

import makeStyles from "./styles.jsx";

function LoanSettings({ value, handleInputChange, handleNext, handleBack }) {
  const classes = makeStyles();
  const [PICError, setPICError] = useState("");
  const [IRCError, setIRCError] = useState("");
  const [IREError, setIREError] = useState("");
  const [RCFTERror, setRCFTError] = useState(false);
  const [guarantee, setGuaranteeReq] = useState(false);
  const [GTCError, setGTCError] = useState("");
  const [ROPError, setROP] = useState("");
  const [RFIERror, setRFIERror] = useState("");
  const [ICPError, setICPError] = useState("");
  const [ITError, setITError] = useState("");

  function clearError() {
    setPICError("");
    setIRCError("");
    setRCFTError("");
    setIREError("");
    setGTCError("");
    setROP("");
    setRFIERror("");
    setICPError("");
    setITError("");
  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (
      value.isLinkedToFloatingInterestRates === "true" &&
      value.isInterestRecalculationEnabled === "false"
    ) {
      setIREError(
        "Interest Recalculation must be true while Floating Interest Rate applied"
      );
      setTimeout(clearError, 15000);
    } else if (
      value.isLinkedToFloatingInterestRates === "true" &&
      value.interestType === 1
    ) {
      setITError(
        "Interest Type must be Declining Balance while choosing Floating Interest Rate"
      );
    } else if (value.graceOnInterestCharged > value.numberOfRepayments) {
      setGTCError(
        "Interest Free Period must be less than Number of Repayments"
      );
      setTimeout(clearError, 15000);
    } else if (
      value.isInterestRecalculationEnabled === "true" &&
      !value.recalculationRestFrequencyType
    ) {
      setROP("Mandatory");
      setTimeout(clearError, 15000);
    } else if (
      value.isInterestRecalculationEnabled === "true" &&
      !value.recalculationRestFrequencyInterval
    ) {
      setRFIERror("Mandatory");
      setTimeout(clearError, 15000);
    } else if (value.interestCalculationPeriodType === "") {
      setICPError("Required");
      setTimeout(clearError, 15000);
    } else {
      handleNext();
    }
  };

  const NUMERIC_DASH_REGEX = /^[e+-.]+$/;

  return (
    <>
      <h1>Loan Tranche Details</h1>
      <hr />

      <div className={classes.container}>
        <h3>
          Amortization Type <span style={{ color: `${star}` }}>*</span>
          <Icon title={AMORTIZATION} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="amortizationType"
          value={value.amortizationType}
          onChange={handleInputChange}
        // error={irfError}
        >
          {LoanSettingsOptions.amortization.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>


      <div className={classes.container}>
        <h3>
          Interest Method <span style={{ color: `${star}` }}>*</span>
          <Icon title={INTEREST_METHOD} />
        </h3>
        <FormControl disabled className={classes.textField}>
          <MuiSelect
            // className={classes.textField}
            variant="outlined"
            labelId=""
            name="interestType"
            value={1}
            // value.isLinkedToFloatingInterestRates === "true"
            //   ? (value.interestType = 0)
            //   : value.interestType
            // }
            onChange={handleInputChange}
          >
            {LoanSettingsOptions.interestType.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      </div>
      <p style={{ color: "red" }}>{ITError}</p>


      <div className={classes.container}>
        <h3>Is Equal Amortization</h3>
        <MuiRadioGroup
          row
          name="isEqualAmortization"
          value={value.isEqualAmortization}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.group.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </MuiRadioGroup>
      </div>


      <div className={classes.container}>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Enable Multiple Disbursal"
          disabled
        />
      </div>


      <div className={classes.container}>
        <h3>
          Interest Calculation Period Type{" "}
          <span style={{ color: `${star}` }}>*</span>
          <Icon title={INT_CALC_PERIOD} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="interestCalculationPeriodType"
          value={
            value.isLinkedToFloatingInterestRates === "true"
              ? (value.interestCalculationPeriodType = 0)
              : value.interestCalculationPeriodType
          }
          onChange={handleInputChange}
          error={ICPError}
        >
          {LoanSettingsOptions.calculationPeriodType.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>


      {value.interestCalculationPeriodType !== 0 ? (
        <div className={classes.container}>
          <h3>Calculate Interest For Exact days in Partial Period</h3>
          <MuiRadioGroup
            row
            name="allowPartialPeriodInterestCalcualtion"
            value={value.allowPartialPeriodInterestCalcualtion}
            onChange={handleInputChange}
          >
            {LoanSettingsOptions.group.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.name}
                control={<Radio />}
                label={item.name}
              />
            ))}
          </MuiRadioGroup>
        </div>
      ) : (
        ""
      )}


      <div className={classes.container}>
        <h3>
          Repayment Strategy <span style={{ color: `${star}` }}>*</span>
          <Icon title={REPAYMENT_STRATEGY} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="transactionProcessingStrategyId"
          value={value.transactionProcessingStrategyId}
          onChange={handleInputChange}
        // error={repaymentStrategyError}
        >
          {LoanSettingsOptions.repaymentStrategy.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>


      {/* <div className={classes.container}>
        <h3>
          Moratorium
          <Icon title={MORATORIUM} />
        </h3>
        <Input
          className={classes.principalPayment}
          label="On Principal Payment"
          onChange={handleInputChange}
          type="text"
          name="graceOnPrincipalPayment"
          value={value.graceOnPrincipalPayment || ""}
        />
        <Input
          className={classes.principalPayment}
          label="On Interest Payment"
          onChange={handleInputChange}
          type="text"
          name="graceOnInterestPayment"
          value={value.graceOnInterestPayment || ""}
        />
      </div> */}
      <div className={classes.container}>
        <h3>
          Interest Free Period
          <Icon title={INTEREST_FREE_PERIOD} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="graceOnInterestCharged"
          value={value.graceOnInterestCharged || ""}
          error={GTCError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>


      <div className={classes.container}>
        <h3>
          Arrears tolerance
          <Icon title={ARREARS_TOLERANCE} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="text"
          name="inArrearsTolerance"
          value={value.inArrearsTolerance || ""}
        />
      </div>


      <div className={classes.container}>
        <h3>
          Days In Year <span style={{ color: `${star}` }}>*</span>
          <Icon title={DAYS_IN_YEAR} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="daysInYearType"
          value={value.daysInYearType}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.daysInYear.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>


      <div className={classes.container}>
        <h3>
          Days In Month <span style={{ color: `${star}` }}>*</span>
          <Icon title={DAYS_IN_MONTH} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="daysInMonthType"
          value={value.daysInMonthType}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.daysInMonth.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>


      <div className={classes.container}>
        <h3>Allow fixing of the installment amount</h3>
        <MuiRadioGroup
          row
          name="canDefineInstallmentAmount"
          value={value.canDefineInstallmentAmount}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.group.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </MuiRadioGroup>
      </div>


      <div className={classes.container}>
        <h3>
          No: of days a loan may be overdue before moving into arrears
          <Icon title={OVERDUE_DAYS} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="text"
          name="graceOnArrearsAgeing"
          value={value.graceOnArrearsAgeing || ""}
        />
      </div>


      <div className={classes.container}>
        <h3>
          Max no: of days a loan may be overdue before becoming a NPA
          <Icon title={NPA_DAYS} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="text"
          name="overdueDaysForNPA"
          value={value.overdueDaysForNPA || ""}
        />
      </div>


      <div className={classes.container}>
        <h3>
          Account moves out of NPA only after all arrears have been cleared?
        </h3>
        <MuiRadioGroup
          row
          name="accountMovesOutOfNPAOnlyOnArrearsCompletion"
          value={value.accountMovesOutOfNPAOnlyOnArrearsCompletion}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.group.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </MuiRadioGroup>
      </div>


      <div className={classes.container}>
        <h3>Principal Threshold (%) for Last Instalment</h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="text"
          name="principalThresholdForLastInstallment"
          value={value.principalThresholdForLastInstallment || ""}
        />
      </div>


      {/* <h1>Interest Recalculation</h1>
      <hr />
      <div className={classes.container}>
        <h3>Interest Recalculation</h3>
        <MuiRadioGroup
          row
          name="isInterestRecalculationEnabled"
          value={value.isInterestRecalculationEnabled}
          onChange={handleInputChange}
        >
          {LoanSettingsOptions.group.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </MuiRadioGroup>
      </div>
      <p style={{ color: "red" }}>{IREError}</p>
      {value.isInterestRecalculationEnabled === "true" ? (
        <>
          <div className={classes.container}>
            <h3>
              Pre-closure interest calculation rule*
            </h3>
            <MuiSelect
              className={classes.textField}
              variant="outlined"
              labelId=""
              name="preClosureInterestCalculationStrategy"
              value={value.preClosureInterestCalculationStrategy}
              onChange={handleInputChange}
              error={PICError}
            >
              {LoanSettingsOptions.preClosureInterestCalculationStrategyOptions.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className={classes.container}>
            <h3>
              Interest recalculation compounding on*
              <Icon title={INT_RECALCULATION} />
            </h3>
            <MuiSelect
              className={classes.textField}
              variant="outlined"
              name="interestRecalculationCompoundingMethod"
              value={value.interestRecalculationCompoundingMethod}
              onChange={handleInputChange}
              error={IRCError}
            >
              {LoanSettingsOptions.interestRecalculationCompoundingTypeOptions.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className={classes.container}>
            <h3>
              Frequency for recalculate Outstanding Principal
              <Icon title={FREQUENCY_RECALCULATE_OUTSTANDING} />
            </h3>
            <MuiSelect
              className={classes.textField}
              variant="outlined"
              labelId=""
              name="recalculationRestFrequencyType"
              value={value.recalculationRestFrequencyType}
              onChange={handleInputChange}
              error={ROPError}
            >
              {LoanSettingsOptions.interestRecalculationFrequencyTypeOptions.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          {value.interestRecalculationCompoundingMethod === 2 ||
          value.interestRecalculationCompoundingMethod === 1 ||
          value.interestRecalculationCompoundingMethod === 3 ? (
            <>
              <div className={classes.container}>
                <h3>
                  Frequency for compounding*
                  <Icon title={FREQUENCY_INTERVAL_RECALCULATION} />
                </h3>
                <MuiSelect
                  className={classes.textField}
                  variant="outlined"
                  labelId=""
                  name="recalculationCompoundingFrequencyType"
                  value={value.recalculationCompoundingFrequencyType}
                  onChange={handleInputChange}
                  error={RCFTERror}
                >
                  {LoanSettingsOptions.interestRecalculationFrequencyTypeOptions.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </MuiSelect>
              </div>
            </>
          ) : (
            ""
          )} */}
      {/* {value.recalculationCompoundingFrequencyType === 3 ? (
            <>
              <div className={classes.container}>
                <h3>Day</h3>
                <MuiSelect
                  className={classes.textField}
                  variant="outlined"
                  labelId=""
                  name="recalculationCompoundingFrequencyDayOfWeekType"
                  value={value.recalculationCompoundingFrequencyDayOfWeekType}
                  onChange={handleInputChange}
                >
                  {LoanSettingsOptions.recalculationCompoundingFrequencyDayOfWeekTypeOptions.map(
                    (item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    )
                  )}
                </MuiSelect>
              </div>
            </>
          ) : (
            ""
          )} */}
      {/* {value.interestRecalculationCompoundingMethod === 1 || value.interestRecalculationCompoundingMethod === 2 || value.interestRecalculationCompoundingMethod === 3 || value.interestRecalculationCompoundingMethod === 4 ? (
            <>
              <div className={classes.container}>
                <h3>
                  Frequency Interval for compounding*
                  <Icon title={FREQUENCY_INTERVAL_RECALCULATION} />
                </h3>
                <Input
                  label=""
                  onChange={handleInputChange}
                  type="text"
                  name="recalculationCompoundingFrequencyInterval"
                  value={value.recalculationCompoundingFrequencyInterval || ""}
                />
              </div>
            </>
          ) : (
            ""
          )} */}
      {/* <div className={classes.container}>
            <h3>
              Frequency Interval for recalculations*
              <Icon title={FREQUENCY_INTERVAL_RECALCULATION} />
            </h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="text"
              name="recalculationRestFrequencyInterval"
              value={value.recalculationRestFrequencyInterval || ""}
              error={RFIERror}
            />
          </div>
          <div className={classes.container}>
            <h3>
              Advance payments adjustment type
              <Icon title={FREQUENCY_INTERVAL_RECALCULATION} />
            </h3>
            <MuiSelect
              className={classes.textField}
              variant="outlined"
              labelId=""
              name="rescheduleStrategyMethod"
              value={value.rescheduleStrategyMethod}
              onChange={handleInputChange}
            >
              {LoanSettingsOptions.rescheduleStrategyMethodOptions.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className={classes.container}>
            <h3>Is Arrears recognization based on original schedule*</h3>
            <MuiRadioGroup
              row
              name="isArrearsBasedOnOriginalSchedule"
              value={value.isArrearsBasedOnOriginalSchedule}
              onChange={handleInputChange}
            >
              {LoanSettingsOptions.group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
          </div>
        </>
      ) : (
        " "
      )} */}
      {/* <h1>Guarantee Requirements</h1>
      <hr />
      <div className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => {
                setGuaranteeReq(!guarantee);
              }}
            />
          }
          label="Place Guarantee Funds On-Hold?"
        />
      </div> */}
      {/* {guarantee ? (
        <>
          <div className={classes.container}>
            <h3>Mandatory Guarantee: (%)</h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="text"
              name="mandatoryGuarantee"
              value={value.mandatoryGuarantee || ""}
              // error={norError}
            />
          </div>
          <div className={classes.container}>
            <h3>Minimum Guarantee From Own Funds: (%)</h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="text"
              name="minimumGuaranteeFromOwnFunds"
              value={value.minimumGuaranteeFromOwnFunds || ""}
              // error={norError}
            />
          </div>
          <div className={classes.container}>
            <h3>Minimum Guarantee From Guarantor Funds:(%)</h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="text"
              name="minimumGuaranteeFromGuarantor"
              value={value.minimumGuaranteeFromGuarantor || ""}
              // error={norError}
            />
          </div>
        </>
      ) : (
        ""
      )} */}
      {/* <h1>Configurable Terms and Settings</h1>
      <hr />
      <div className={classes.container}>
            <h3>Allow overriding select terms and settings in loan accounts</h3>
            <MuiRadioGroup
              row
              name="allowAttributeConfiguration"
              value={value.allowAttributeConfiguration}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Amortization</h3>
            <MuiRadioGroup
              row
              name="amortizationType"
              value={value.amortizationType}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Repayment Strategy</h3>
            <MuiRadioGroup
              row
              name="transactionProcessingStrategy"
              value={value.transactionProcessingStrategy}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Arrears Tolerance</h3>
            <MuiRadioGroup
              row
              name="arrearsTolerance"
              value={value.arrearsTolerance}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Interest calculation period</h3>
            <MuiRadioGroup
              row
              name="interestCalcPeriod"
              value={value.interestCalcPeriod}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Repaid every</h3>
            <MuiRadioGroup
              row
              name="repaymentFrequency"
              value={value.repaymentFrequency}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Interest Method</h3>
            <MuiRadioGroup
              row
              name="interestMethod"
              value={value.interestMethod}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Moratorium</h3>
            <MuiRadioGroup
              row
              name="graceOnPrincipalAndInterest"
              value={value.graceOnPrincipalAndInterest}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div>
      <div className={classes.container}>
            <h3>Number of days a loan may be overdue before moving into arrears</h3>
            <MuiRadioGroup
              row
              name="graceOnArrearsAging"
              value={value.graceOnArrearsAging}
              onChange={handleInputChange}
            >
              {group.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
      </div> */}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, width: 750 }}>
        <Button
          className={classes.nextBtn}
          variant="contained"
          color="primary"
          data-testid="nextBtn"
          onClick={handleBack}
        >
          BACK
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          className={classes.nextBtn}
          variant="contained"
          color="primary"
          data-testid="nextBtn"
          onClick={submitFormData}
        >
          NEXT
        </Button>
      </Box>
    </>
  );
}

export default LoanSettings;
