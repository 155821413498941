import React from 'react'
import './FinalDashboard.css'

const BigGraphHeader = ({ header, subHeader }) => {
    return (
        <div>
            <h2 className='g-one-header'>{header}</h2>
            <h4 className='g-one-subheader'>{subHeader}</h4>
        </div>
    )
}

export default BigGraphHeader