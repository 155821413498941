import React from 'react'
import makeStyles from '../../../../Sharedfeatures/SharedClasses'

const PaymentHistoryBodyCell = ({name, acno, status, upDate}) => {
    const classes = makeStyles()
    const moment = require("moment");
    return (
        <>
            <div className={classes.paymentHistoryBodyCells}>

                <div className={classes.bodyCellOne}>{name}</div>

                <div className={classes.bodyCellTwo}>
                    <h4 className={classes.bodyCellContent}>{acno}</h4>
                </div>

                <div className={classes.bodyCellThree}>
                    <h4 className={classes.bodyCellContent}>Timely Payments</h4>
                    <h4 className={classes.bodyCellContent}>3/3</h4>
                </div>

                <div className={classes.bodyCellFour}>
                    <h4 className={classes.bodyCellContent}>Status</h4>
                    <h4 className={classes.bodyCellContent}>{status}</h4>
                </div>

                <div className={classes.bodyCellFour}>
                    <h4 className={classes.bodyCellContent}>Last Updated</h4>
                    <h4 style={{ color: '#23D945' }} className={classes.bodyCellContent}>{moment(upDate).format("DD-MM-YYYY")}</h4>
                </div>
            </div>
        </>
    )
}

export default PaymentHistoryBodyCell