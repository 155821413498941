export const GRAPH_ONE_HEADER = 'Interest Collected';
export const GRAPH_ONE_SUBHEADER = 'Chart how your business grows';

export const PREAPPROVED_HEADER = 'Pre-Approved Loan Offers Generated';

export const GRAPH_THREE_HEADER = 'Loan Disbursed';
export const GRAPH_THREE_SUBHEADER = '823,470.00';

export const GRAPH_FOUR_HEADER = 'Loan Sanctioned';
export const GRAPH_FOUR_SUBHEADER = '823,470.00';

export const DEFAULTED_HEADER = 'Defaulted';
export const DEFAULTED_SUBHEADER = '823,470.00';

export const GRAPH_SIX_HEADER = 'Loan Defaulted - NPA';
export const GRAPH_SIX_SUBHEADER = '823,470.00';

export const GRAPH_SEVEN_HEADER = 'Tranche Requests';
export const GRAPH_SEVEN_SUBHEADER = '823,470.00';

export const REGION_WISE_LOAN_DISBURSAL = ' Loan Disbursal - Region Wise';
export const REGION_WISE_LOAN_DISBURSAL_SUBHEADER = '2022';

export const DUEAMOUNT_HEADER = 'Due Amount';
export const DUEAMOUNT__SUBHEADER = '2022';


