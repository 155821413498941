import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "@material-ui/core";
import Personal from './Personal';
import Official from './Official';
import Bank from './Bank';
import CreditModal from "./CreditModal"
import Documents from './Documents';
import useStyles from './LoanAppStyles';

const steps = ["Personal", "Official", "Bank",
    // "CreditModal",
    "Documents"];

const SanctionDetailsStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles()

    const handleNext = (handleClose) => {

        if (activeStep < (steps.length - 1)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
            handleClose()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepCount = (activeStep) => {
        switch (activeStep) {
            case 0:
                return <Personal />
            case 1:
                return <Official />
            case 2:
                return <Bank />
            // case 3:
            //     return <CreditModal />
            case 3:
                return <Documents />
            default:
                return 'unknown step'
        }
    }

    return (
        <>
            <Box className={classes.stepperParent} sx={{ p: 4 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <>
                    <Box className={classes.stepperComponent} sx={{ p: 4 }}>
                        {getStepCount(activeStep)}
                    </Box>

                    <Box className={classes.stepperButtonsParent} sx={{ pt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        {activeStep === steps.length - 1 ? '' :
                            <Button
                                variant='contained'
                                color="primary"
                                onClick={handleNext}
                            >
                                Next
                            </Button>}
                    </Box>
                </>
            </Box>
        </>
    )
}

export default SanctionDetailsStepper