import React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "../controls/Button"

const PreAppDialogueBox = ({open, onClose, text, item, approve, reject}) => {

  return (
    <>
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {text} this offer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            text={"Disagree"}
            color="primary"
            onClick={onClose}
          ></Button>
          <Button
            text={"Agree"}
            color="primary"
            onClick={text === 'approve' ?
            (() => {
              approve(item)
              onClose()
            }) :
            (() => {
              reject(item)
              onClose()
            })}
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PreAppDialogueBox