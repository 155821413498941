import { Skeleton } from 'antd'
import React, { Suspense, lazy } from 'react'
import './loanstatus.css'
const GeographicalChart = lazy(()=> import('./GeographicalGraph/GeographicalChart'))
const WeeklyLoansChart = lazy(()=> import('./WeeklyLoans/WeeklyLoansChart')) ;
const LoanStatus = () => {
    return (
        <div className="section-wrap displayflexcol loan-status-section">
            <h2 className="section-title">Loan Status</h2>
            <div className="card-wrap">
                <Suspense fallback={<Skeleton active/>}>
                    <WeeklyLoansChart/>
                </Suspense>
            </div>
            <div className="card-wrap">
                <Suspense fallback={<Skeleton active />}>
                    <GeographicalChart/>
                </Suspense>
            </div>
        </div>
    )
}

export default LoanStatus