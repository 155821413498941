import { Box, Modal, Typography } from '@material-ui/core'
import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import BRModalHeader from './BRModalHeader';
import BusinessAmount from './BusinessAmount';
import BusinessTrend from './BusinessTrend';
import DistributorStatus from './DistributorStatus';
import DistrRetlr from './DistrRetlr';
import VintagePeriod from './VintagePeriod';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '../../controls/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '78%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const BRModalTwo = ({ open, handleClose }) => {
  const classes = makeStyles()
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>

          <Typography variant="h6" component="h2" className={classes.cbReportModalHeader} style={{ padding: '25px 0' }}>
            ECAPS Business Report
          </Typography>

          <BRModalHeader />

          <Box style={{ padding: '25px 45px 0px 45px', overflowY: "scroll", height: '430px' }}>

            <Typography variant="h6" gutterBottom component="div" className={classes.boxTitle}>Eligibility Criteria</Typography>

            <VintagePeriod />
            <DistributorStatus />
            <BusinessTrend />
            <BusinessAmount />
            <DistrRetlr />


          </Box>

          <div className={classes.paymentHistoryBackButtonParent}>
            <Button
              text="Back"
              size="small"
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              className={classes.ebrBackButton}
            onClick={handleClose}
            />
          </div>
        </Box>

      </Modal>
    </>
  )
}

export default BRModalTwo