import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ProgressSpinner from '../common/ProgressSpinner';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';

const Today = ({ todayData, loader }) => {
  const { dataFormater } = useYAxisFormatter();
  const { count, volume } = todayData

  const data = [

    {
      day: "Today",
      count,
      volume: Number(volume)
    }
  ];
  return (
    <div className='loan-disbursed-region-wise'>
      {
        loader ? (
          <div className='dash_spinner_parent'>
            <ProgressSpinner />
          </div>
        ) : (
          <ResponsiveContainer width="95%" height="95%">
            <BarChart
              width={500}
              height={500}
              data={data}
              margin={{
                top: 15,
                right: 30,
                left: 20,
                bottom: -8,
              }}
              barSize={60}
            >
              <XAxis dataKey="day" axisLine={false} />
              <YAxis axisLine={false} tickFormatter={dataFormater} />
              <Tooltip cursor={false}
              // content={<CustomTooltip />} 

              />
              <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
              <Bar dataKey="count" fill="#7C4DFF" />
              <Bar dataKey="volume" fill="#7C4DFF" />
            </BarChart>
          </ResponsiveContainer>
        )
      }

    </div>
  )
}

export default Today