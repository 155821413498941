import React from "react";
import { Typography, Grid, ListItemText } from "@material-ui/core";
import styles from "./loanviewstyles";
import { useSelector } from "react-redux";

const TermsView = () => {
  const myStyles = styles();
  const { loanDetails } = useSelector((state) => state.loan);
  
  return (
    <>
      <Grid className={myStyles.viewHeading}>
        <h1>Terms</h1>
        <hr />
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={6}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Principal:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails.principal}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Maximum Principal:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails.maxPrincipal}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Minimum Principal:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails.minPrincipal}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Number of Repayments:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails.numberOfRepayments}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Repay Every
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails && loanDetails.repaymentEvery} {""}
                    {loanDetails.repaymentFrequencyType &&
                      loanDetails.repaymentFrequencyType.value}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Interest Rate
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails &&
                    loanDetails.isLinkedToFloatingInterestRates === false
                      ? "Nominal Interest Rate"
                      : "Floating Interest Rate"}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "25px" }}>
          <Grid item xs={4}>
            <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
              <Grid item md={12}>
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listHeading}>
                      Loan Status:
                    </Typography>
                  }
                />
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listValues}>
                      {loanDetails.status == "loanProduct.active"
                        ? "Active"
                        : "Inactive"}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
              <Grid item md={12}>
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listHeading}>
                      Minimum days between disbursal and first repayment date:
                    </Typography>
                  }
                />
                <ListItemText
                  className={styles.employeeItems}
                  primary={
                    <Typography className={myStyles.listValues}>
                      {loanDetails &&
                        loanDetails.minimumDaysBetweenDisbursalAndFirstRepayment}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "25px" }}></Grid>
      </Grid>
    </>
  );
};

export default TermsView;
