import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
  } from "@material-ui/core";
  import { PAGE_SIZE } from "../../utils/variable";
  import Pagination from "@mui/material/Pagination";
  import React, { useState } from "react";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      width: '70vw',
      [theme.breakpoints.up("sm")]: {
      },
      [theme.breakpoints.up("md")]: {
      },
      [theme.breakpoints.up("lg")]: {
      },
      [theme.breakpoints.up("xl")]: {
      },
    },
    table: {
      marginTop: theme.spacing(5),
      maxWidth: '72vw',
      position: 'relative',
      "&": {
        borderRadius: "20px",
        // width: "85vw",
      },
      "& thead th": {
        fontWeight: "600",
        fontSize: "15px",
        letter: "8%",
        color: "#f8f7fc",
        lineHeight: "22.5px",
        backgroundColor: "#1A0045",
      },
      "& thead th:first-child": {
        borderRadius: "15px 0 0 15px"
      },
      "& thead th:last-child": {
        borderRadius: "0 15px 15px 0"
      },
      "& tbody td": {
        fontWeight: "500",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
      },
    },
  }));

  export default function useModalTable(records, headCells, totalCount) {
    const classes = useStyles();
    const [pageNo, setPageNo] = useState(1);
    const [slNo, setSlNo] = useState(0);

    const handleChange = (event, value) => {
      setPageNo(value);
      setSlNo(value - 1);
    };

    const TblContainer = (props) => (
      <Table className={classes.table}>{props.children}</Table>
    );

    const TblHead = (props) => (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.label}>{headCell.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
    const TblPagination = () => (
      <Pagination
        className={classes.table}
        count={Math.ceil(totalCount / PAGE_SIZE)}
        page={pageNo}
        onChange={handleChange}
      />
    );
    return {
      TblContainer,
      TblHead,
      TblPagination,
      pageNo,
      slNo,
    };
  }
  