import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import DashboardGreeting from "./DashboardGreeting";
import DateTimeBar from "./DateTimeBar";
import InterestChart from "./InterestChart";
import PreApprovedLoanChart from "./PreApprovedLoanChart";
import DisbursedChart from "./DisbursedChart";
import SanctionChart from "./SanctionChart";
import Defaulted from "./Defaulted";
import TrancheChart from "./TrancheChart";
import RegionGraphs from "./RegionGraphs";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import RepaymentsChart from "./RepaymentsChart";
import SelectYear from "./SelectYear";
import './FinalDashboard.css'

const FinalDashboard = () => {

  const sharedClasses = useSharedClasses();
  let curYear = new Date().getFullYear()
  let years = []
  for (let i = curYear; i >= 2022; i--) {
    years.push(i)
  }
  const [year, setYear] = useState(years[0])

  return (
      <>
        <Grid container className="grid-gap">
          <Grid item sm={12} md={12} lg={12}>
            <DashboardGreeting />
          </Grid>
        </Grid>

        <Grid container className="grid-gap">
          <Grid item sm={0} md={0} lg={4}></Grid>

          <Grid item sm={0} md={6} lg={4}>
            <SelectYear year={year} setYear={setYear} years={years} />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <DateTimeBar />
          </Grid>
        </Grid>

        <Grid container className="grid-gap">
          <Grid item sm={12} md={12} lg={12}>
            <InterestChart year={year} />
          </Grid>
        </Grid>

        <Grid container className="grid-gap-two">
          <Grid item sm={12} md={6} lg={4}>
            <SanctionChart year={year} />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <DisbursedChart year={year} />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <Defaulted year={year} />
          </Grid>
        </Grid>

        <Grid container className="grid-gap-two">
          <Grid item sm={12} md={4} lg={4}>
            <PreApprovedLoanChart year={year} />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <RepaymentsChart year={year} />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TrancheChart />
          </Grid>
        </Grid>

        <Grid container className='grid-gap'>
          <Grid item sm={12} md={12} lg={12}>
            <RegionGraphs year={year} />
          </Grid>
        </Grid>

        <Grid container className="grid-gap">
          {/* For bottom spacing purpose. Don't remove this block */}
        </Grid>
      </>
  );
};

export default FinalDashboard;
