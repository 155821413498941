import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, IconButton, makeStyles, TableBody, TableCell, TableRow } from '@material-ui/core';
import PageHeader from '../Sharedfeatures/PageHeader';
import CloseIcon from "@mui/icons-material/Close";
import useModalTable from '../Sharedfeatures/useModalTable';
import ProgressSpinner from './ProgressSpinner';

const useStyles = makeStyles({
  header: {
    color: "#963460",
    fontSize: "24px",
    position: "relative",
    left: "-126px",
    top: "-44px",
    padding: '0 30px'
  },
  closeIconButton: {
    position: "absolute",
    right: "-148px",
    top: "-34px",
  },
});

const RepaymentModal = ({ open, handleClose, headCells, data, loading }) => {
  const classes = useStyles()
  const moment = require("moment");
  const { TblContainer, TblHead } = useModalTable(data, headCells);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '57%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: "70%",
    overflow: "auto",
    bgcolor: 'background.paper',
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" className={classes.header}>
            <PageHeader header="Repayment Details" />
            <IconButton className={classes.closeIconButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>

          <Container>
            <TblContainer>
              <TblHead />
              {
                loading ?
                  (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={7} align='center'>
                          <ProgressSpinner />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {data && data.length !== 0 ? (
                        data &&
                        data.map(({ trancheid, amount, principal_portion, interest_portion, transaction_date, penalty }, key) => {
                          key++;
                          return (
                            <TableRow key={key.toString()}>
                              <TableCell>{key}</TableCell>
                              <TableCell>{trancheid}</TableCell>
                              <TableCell>{Number(amount).toFixed(2)}</TableCell>
                              <TableCell>{Number(principal_portion).toFixed(2)}</TableCell>
                              <TableCell>{Number(interest_portion).toFixed(2)}</TableCell>
                              <TableCell align='center'>{penalty}</TableCell>
                              <TableCell align='center'>
                                {moment(transaction_date).format("DD MMMM YYYY")}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            align="center"
                          >
                            No Data to Show
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )
              }
            </TblContainer>
          </Container>
        </Box>
      </Modal>
    </>
  )
}

export default RepaymentModal