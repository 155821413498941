import React, { useEffect } from 'react'
import useSharedClasses from "../../Sharedfeatures/SharedClasses";
import PageHeader from "../../Sharedfeatures/PageHeader";
import { TXT_LOAN_STATEMENT, TXT_HOME, TXT_LOAN_SANCTIONED, TXT_LOAN_ACCOUNTDET, BACK_BUTTON } from '../../../utils/header';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography, Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoanStatement } from "../../../slice/loanSanctioned";
import useTable from '../../Sharedfeatures/UseTable';
import HeadCells from '../../common/HeadCells';
import { LOAN_SANCTIONED } from "../../../utils/url";
import Button from '../../controls/Button';


const useStyles = makeStyles({
    table: {
        marginTop: '100px',
        '&': {
            borderRadius: '20px',
        },
        '& thead th': {
            fontWeight: '600',
            fontSize: '15px',
            letter: '8%',
            color: "#f8f7fc",
            lineHeight: '22.5px',
            backgroundColor: "#1A0045",
        },
        '& tbody td': {
            fontWeight: '500',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
        },
        "& thead th:first-child": {
            borderRadius: "15px 0 0 15px"
        },
        "& thead th:last-child": {
            borderRadius: "0 15px 15px 0"
        },
    },

})

const LoanAccountStatement = () => {

    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch()
    const { id , clientId} = useParams();
    const tableClass = useStyles()

    const { loanStatement } = useSelector((state) => state.loanSanction);
    const { TblContainer, TblHead } = useTable(loanStatement, HeadCells.loanStatementHead)

    const dateFormatter = (dateArray) => {
        let recArray = dateArray
        let dateRArray = recArray.map(item => item).reverse()
        let lengthOfArray = dateRArray.length
        let correctedDate = []
        dateRArray.map((item, key) => {
            key++;
            if (key < lengthOfArray)
                correctedDate.push(`${item}-`)
            else
                correctedDate.push(`${item}`)
        })
        return correctedDate
    }


    useEffect(() => {
        dispatch(getLoanStatement(id));
    }, [dispatch, id]);

    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_LOAN_STATEMENT}
                        subheader={`${TXT_HOME}/${TXT_LOAN_SANCTIONED}/${TXT_LOAN_ACCOUNTDET}/${TXT_LOAN_STATEMENT}`}
                        icon={BACK_BUTTON}
                        link={`/admin/loansanctioned/loanaccountdetails/${clientId}`}
                    />
                    <section className={sharedClasses.mainSection}>
                        <div className={sharedClasses.lstcontainer}>

                            <Paper className={sharedClasses.tableBoxShadow}>
                                <TableContainer className={sharedClasses.lsttableLeft}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name:</TableCell>
                                            <TableCell>{loanStatement.clientName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Office Id:</TableCell>
                                            <TableCell>{loanStatement.clientOfficeId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Account No:</TableCell>
                                            <TableCell>{loanStatement.clientAccountNo}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Tranche No:</TableCell>
                                            <TableCell>{loanStatement && loanStatement.externalId}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </TableContainer>
                            </Paper>

                            <Paper className={sharedClasses.tableBoxShadow}>
                                <TableContainer className={sharedClasses.lsttableRight}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Loan Id:</TableCell>
                                            <TableCell>{loanStatement && loanStatement.loanProductId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Loan Name:</TableCell>
                                            <TableCell>{loanStatement.loanProductName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Loan Type:</TableCell>
                                            <TableCell>{loanStatement && loanStatement.loanType && loanStatement.loanType.value}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Annual Interest Rate:</TableCell>
                                            <TableCell>{loanStatement.annualInterestRate}%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </TableContainer>
                            </Paper>

                        </div>

                        <Typography variant="h5" className={sharedClasses.loanDetailsTableOneCaption}>Basic Details</Typography>

                        <Grid container className={sharedClasses.cardSection}>
                            <Grid item md={5}>
                                <Paper className={sharedClasses.loanStatementCards}>
                                    <TableContainer className={sharedClasses.lsttableLeft}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Principal:</TableCell>
                                                <TableCell>{loanStatement.principal}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Approved Principal:</TableCell>
                                                <TableCell>{loanStatement && loanStatement.approvedPrincipal}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Proposed Principal:</TableCell>
                                                <TableCell>{loanStatement.proposedPrincipal}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Net Disbursal Amount:</TableCell>
                                                <TableCell>{loanStatement.netDisbursalAmount}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={5}>
                                <Paper className={sharedClasses.loanStatementCards}>
                                    <TableContainer className={sharedClasses.lsttableLeft}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Repayment Frequency:</TableCell>
                                                <TableCell>{loanStatement.repaymentEvery} {loanStatement && loanStatement.repaymentFrequencyType && loanStatement.repaymentFrequencyType.value}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Interest Rate:</TableCell>
                                                <TableCell>{loanStatement.interestRatePerPeriod}% {loanStatement && loanStatement.interestRateFrequencyType && loanStatement.interestRateFrequencyType.value}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Interest Rate Type:</TableCell>
                                                <TableCell>{loanStatement.isFloatingInterestRate === false ? "Nominal Interest Rate" : "Floating Interest Rate"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Amortization Type:</TableCell>
                                                <TableCell>{loanStatement && loanStatement.amortizationType && loanStatement.amortizationType.value}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" className={sharedClasses.loanStatementSummaryTableCaption}>Summary</Typography>
                        <Paper className={sharedClasses.tableBoxShadow} style={{ marginTop: '100px' }}>
                            <TblContainer>
                                <TblHead />
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.principalDisbursed}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.principalPaid}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.principalOutstanding}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.principalOverdue}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.interestCharged}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.interestPaid}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.interestWaived}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.interestOutstanding}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.interestOverdue}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.totalExpectedCostOfLoan}</TableCell>
                                        <TableCell align='center'>{loanStatement && loanStatement.summary && loanStatement.summary.totalExpectedRepayment}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </TblContainer>
                        </Paper>

                        <Typography variant="h5" className={sharedClasses.loanStatementTransactionTableCaption}>Transactions</Typography>
                        <Paper className={sharedClasses.tableBoxShadow}>
                            <Table className={tableClass.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>Sr No.</TableCell>
                                        <TableCell align='center'>Amt.</TableCell>
                                        <TableCell align='center'>Payment Type</TableCell>
                                        <TableCell align='center'>Net Disb. Amt</TableCell>
                                        <TableCell align='center'>O/s Loan Balance</TableCell>
                                        <TableCell align='center'>Date</TableCell>
                                        {/* <TableCell align='center'>No. of Repayments</TableCell> */}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        loanStatement.transactions && loanStatement.transactions.map((item, key) => {
                                            key++;
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell align='center'>{key}</TableCell>
                                                    <TableCell align='center'>{item.amount}</TableCell>
                                                    <TableCell align='center'>{item && item.type && item.type.value}</TableCell>
                                                    <TableCell align='center'>{item.netDisbursalAmount}</TableCell>
                                                    <TableCell align='center'>{item.outstandingLoanBalance}</TableCell>
                                                    <TableCell align='center'>{dateFormatter(item.date)}</TableCell>
                                                    {/* <TableCell align='center'>{item.numberOfRepayments}</TableCell> */}
                                                </TableRow>
                                            )
                                        }
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Paper>

                        <Grid container className='grid-gap'>
                            {/* For bottom spacing purpose. Don't remove this block */}
                        </Grid>
                        <Grid container className='grid-gap'>
                            {/* For bottom spacing purpose. Don't remove this block */}
                        </Grid>
                    </section>
                </div>
            </div>
        </>
    )
}

export default LoanAccountStatement