import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getTranchesList = createAsyncThunk('getTranchesList', async (data)=>{
    try{
        const { fromDate, toDate, pageNo } = data;
        const url = `/loans/tranches-list?fromDate=${fromDate}&toDate=${toDate}&page=${pageNo}&limit=10`;
        const response = await DataService.get(url);
        return response.data
    }catch (err){
        return err.response
    }
})

export const getTranchesListExcel = createAsyncThunk('getTranchesListExcel', async (data)=>{
    try{
        const { fromDate, toDate, totalCount } = data;

        const url = `/loans/disbursed-tranches-list?fromDate=${fromDate}&toDate=${toDate}&page=1&limit=${totalCount}`;
        const response = await DataService.get(url);
        return response.data
    }catch (err){
        return err.response
    }
})

export const markTrancheSuccess = createAsyncThunk('markTrancheSuccess', async (data)=>{
    try{

        const url = `/loans/mark-tranche-disbursed`;
        const response = await DataService.post(url,data);
        return response.data
    }catch (err){
        return err.response
    }
})
export const markTrancheFailed = createAsyncThunk('markTrancheFailed', async (data)=>{
    try{

        const url = `/loans/mark-tranche-failed`;
        const response = await DataService.post(url,data);
        return response.data
    }catch (err){
        return err.response
    }
})

const initialState = {
    tranchesList: [],
    tranchesListExcel:[],
    isLoading: true,
    totalCount: ''
}

const tranchesListSlice = createSlice({
    name : 'tranchesListSlice',
    initialState,
    extraReducers:{
        [getTranchesList.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getTranchesList.fulfilled]:(state,{payload})=>{
            return {...state, tranchesList:payload?.data,totalCount:payload.totalItmes, isLoading:false}
        },
        [getTranchesList.rejected]: (state) => {
            return {...state, isLoading:false}
        },
        [getTranchesListExcel.fulfilled]:(state,{payload})=>{
            return {...state, tranchesListExcel:payload?.data, isLoading:false}
        },
    }
})

export default tranchesListSlice.reducer