import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editOfferAmount, resetEdtdOfferStates } from '../../slice/preApproved';
import { useForm } from "../Sharedfeatures/useForm";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
};

const EditOffer = ({ value, open, handleClose, item }) => {

    const [amountError, setAmountError] = useState("");
    const [amount, setAmount] = useState()
    const dispatch = useDispatch()
    const { edtdStatus, errorMessage } = useSelector((state) => state.preApproved);

    useEffect(() => {
        if (!edtdStatus && errorMessage) {
            setAmountError(errorMessage);
            setTimeout(() => setAmountError(""), 3000);
            dispatch(resetEdtdOfferStates())
        }
    }, [errorMessage])


    function clearError() {
        setAmountError("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Number(amount)) {
            const data = {
                amount,
                loanAppId: item.loanapproval_id
            }
            dispatch(editOfferAmount(data))
        }
        else {
            setAmountError("Amount must be 50000 or Above");
            setTimeout(clearError, 2000);
        }
    }

    const handleValueChange = (e) => {
        setAmount(e.target.value)
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant="h6" textAlign={'center'} component="h2">
                        Edit Loan Offer
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 2 }}>
                        Name: {item.name}
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 2 }}>
                        Initial Amount: {item.amount}
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 2 }}>
                        Please enter the Edited Amount Below:
                    </Typography>
                    <form autoComplete='off' onSubmit={handleSubmit}>
                        <TextField
                            name="amount"
                            value={amount}
                            type="number"
                            onChange={handleValueChange}
                            label="Amount"
                            variant="outlined"
                            error={amountError}
                            helperText={amountError}
                            fullWidth
                        />
                        <Stack direction="row" spacing={2} sx={{ mt: 2, pl: 5, pr: 5 }} justifyContent={'space-evenly'}>
                            <Button type='submit' variant="contained">Submit</Button>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default EditOffer