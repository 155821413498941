import * as React from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const Icon = ({title}) => {
  return (
    <Tooltip title={title}>
      <IconButton>
        <QuestionMarkIcon color="primary" sx={{fontSize: 'medium'}}/>
      </IconButton>
    </Tooltip>
  );
};

export default Icon;
