import React from 'react'
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { Grid, ListItemText, Typography } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

const GridView = ({ leftHeader, leftDetail, rightHeader, rightDetail }) => {

    const styles = useSharedClasses();

    const { charge_details_loading } = useSelector((store) => store.chargesOverdue)

    return (
        <>
            <Grid container spacing={2} className={styles.gridContainerParent}>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={styles.listHeading}>{leftHeader}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={styles.listValues}>{charge_details_loading ? <Skeleton /> : leftDetail}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={styles.listHeading}>{rightHeader}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={styles.listValues}>{charge_details_loading ? <Skeleton /> : rightDetail}</Typography>} />
                </Grid>
            </Grid>
        </>
    )
}

export default GridView