import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'
import { ImCross } from 'react-icons/im'
import { TiTick } from 'react-icons/ti'

const VintagePeriod = () => {
  const classes = makeStyles()
  return (
    <>
      <Typography className={classes.ebrBodyTabHeader}>Vintage Period</Typography>
      
      <Grid container spacing={3}>

        <Grid item md={4}>
          <div className={classes.ebrBodyCells}>
            <div className={classes.ebrBodyCellLeft}>
              <div className={classes.violetCircle}>
                <TiTick color='#ffffff' size="20px" />
              </div>
            </div>
            <div className={classes.ebrBodyCellRight}>
              <p className={classes.ebrBodyCellContent}>&#62;= 12 Months</p>
              <p className={classes.ebrBodyCellContent}>10 Points</p>
            </div>
          </div>
        </Grid>

        <Grid item md={4}>
          <div className={classes.ebrBodyCells}>
            <div className={classes.ebrBodyCellLeft}>
              <div className={classes.violetCircle}>
                <TiTick color='#ffffff' size="20px" />
              </div>
            </div>
            <div className={classes.ebrBodyCellRight}>
              <p className={classes.ebrBodyCellContent}>&#62;= 6 Months</p>
              <p className={classes.ebrBodyCellContent}>5 Points</p>
            </div>
          </div>
        </Grid>

        <Grid item md={4}>
          <div className={classes.ebrBodyCells}>
            <div className={classes.ebrBodyCellLeft}>
              <div className={classes.violetCircle}>
                <TiTick color='#ffffff' size="20px" />
              </div>
            </div>
            <div className={classes.ebrBodyCellRight}>
              <p className={classes.ebrBodyCellContent}>&#62;= 3 Months</p>
              <p className={classes.ebrBodyCellContent}>2.5 Points</p>
            </div>
          </div>
        </Grid>

        <Grid item md={4}>
          <div className={classes.ebrBodyCells}>
            <div className={classes.ebrBodyCellLeft}>
              <div className={classes.redCircle}>
                <ImCross color='#ffffff' size="20px" />
              </div>
            </div>
            <div className={classes.ebrBodyCellRight}>
              <p className={classes.ebrBodyCellContent}>&#60; 3 Months</p>
              <p className={classes.ebrBodyCellContent}>-1 Points</p>
            </div>
          </div>
        </Grid>

      </Grid>
    </>
  )
}

export default VintagePeriod