import React, { createContext, useEffect, useMemo, useState } from "react";
import { TextField, TableBody, TableCell, IconButton, TableRow, Button } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import { getLoanSanctionList, getLoanSanctionedExcel, getLoanAppStatement, getLoanTrancheList,  reset_message, resetStateValues } from "../../slice/loanSanctioned";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TrancheModal from "../common/TrancheModal";
import ProgressSpinner from "../common/ProgressSpinner";
import { TXT_LOAN_SANCTIONED, TXT_HOME } from "../../utils/header";
import PageHeader from "../Sharedfeatures/PageHeader";
import DownloadIcon from "@mui/icons-material/Download";
import useExcel from "../common/useExcel";
import { DatePicker } from 'antd';
import LoanSanctionModal from "./LoanSanctionModal";
import { BsFillEyeFill } from "react-icons/bs";
import debounce from "lodash/debounce";


const CustomerData = createContext()

const LoanSanctioned = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;


    const [open, setOpen] = useState(false);
    const sharedClasses = useSharedClasses();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;

    const { loanSanctionList, loanSanctionedView, loanTrancheList, totalCount, setLoading } = useSelector((state) => state.loanSanction);

    const dispatch = useDispatch();
    const excelData = useExcel();

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(loanSanctionList, HeadCells.loanSanctionedHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }
    useEffect(() => {
        const data = {
        searchQry: search,
        pageNum: pageNo,
        fromDate,
        toDate,
        };
        dispatch(getLoanSanctionList(data));
        setShowPageNtn(true);
    }, [dispatch, fromDate, toDate, pageNo, search, ]);

    const handleClose = () => {
        setOpen(!open);
        dispatch(reset_message());
    };

    const onBtnClick = (loanapproval_id) => {
        setOpen(!open);
        const data = {
        loanapproval_id: loanapproval_id,
        };
        if (data) dispatch(getLoanTrancheList(data));
        dispatch(reset_message())
    };

    //View Details Modal
    const [viewDetails, setViewDetails] = useState(false)

    const handleDetailsOpen = (loanapproval_id) => {
        dispatch(getLoanAppStatement(loanapproval_id))
        setViewDetails(true)
    }
    const handleDetailsClose = () => {
        setViewDetails(false)
        dispatch(resetStateValues())
    }

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQry: search,
            pageNum: pageNo,
            fromDate,
            toDate,
            totalCount
        };
        dispatch(getLoanSanctionedExcel(data))
        .then(res=>{
            if(res.payload.success === true){
                let excelsheetdata = []
                res.payload.data?.map(item=>{
                    excelsheetdata.push({
                        "RT/DT Code" : item.customerid,
                        "Name" : item.name,
                        "Loan Product" : item.loanName,
                        "Tenure" : item.tenure,
                        "Amount" : item.amount_sanctioned,
                        "Interest Rate" : item.interest_rate
                    })
                })
                excelData.generateExcelFile(excelsheetdata, 'LoanSanctionedSheet', 'loansanctionedlist.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    return (
        <>
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_LOAN_SANCTIONED}
                subheader={`${TXT_HOME}/${TXT_LOAN_SANCTIONED}`}
            />
            <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                    <TextField
                        size="small"
                        className={sharedClasses.lpsearchTextBar}
                        //value={search}
                        onChange={debouncedResults}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                        endAdornment: (
                            <IconButton>
                            <SearchOutlined
                                className={sharedClasses.searchIconColour}
                            />
                            </IconButton>
                        ),
                        }}
                    />
                </div>

                <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                    <RangePicker
                        placeholder={["From Date", "To Date"]}
                        format={dateFormat}
                        className={sharedClasses.datePicker}
                        onChange={onDateChange}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        className={sharedClasses.download}
                        onClick={downloadExcelsheet}
                        disabled = {totalCount === 0 ? true : false}
                        endIcon={<DownloadIcon />}
                    >
                        Download Report
                    </Button>
                </div>
            </article>

            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {setLoading ? (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={9} align='center'>
                        <ProgressSpinner />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                    {
                        (loanSanctionList.length !== 0) ?
                        (loanSanctionList &&
                            loanSanctionList.map((item, index) => {
                            serialNo++;
                            return (
                                <>
                                <TableRow key={item.id.toString()}>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {serialNo}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.customerid}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.name}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {" "}
                                    {item.loanName}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.tenure}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.amount_sanctioned}
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    {item.interest_rate}
                                    </TableCell>
                                    <TableCell
                                    onClick={() => onBtnClick(item.loanapproval_id)}
                                    className={sharedClasses.tableCell}
                                    >
                                    <Button color="primary" variant="outlined">
                                        {" "}
                                        Show
                                    </Button>
                                    </TableCell>
                                    <TableCell className={sharedClasses.tableCell}>
                                    <Link to={`loanaccountdetails/${item.clientId}`}>
                                        <Button variant="outlined" color="primary">
                                        View
                                        </Button>
                                    </Link>
                                    </TableCell>
                                    <TableCell align="center" className={sharedClasses.tableCell}>
                                    <IconButton onClick={() => handleDetailsOpen(item.loanapproval_id)}>
                                        <BsFillEyeFill className={sharedClasses.actionIcon} />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                                </>
                            );
                            })
                        ) : (
                            <TableRow>
                            <TableCell colSpan={9} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                )}
                </TblContainer>
                {showPageNtn ? <TblPagination /> : ""}
            </section>

            {open ? (
                <TrancheModal
                open={open}
                onClose={handleClose}
                tranche={HeadCells.tranche_list_client}
                trancheData={loanTrancheList}
                loading={setLoading}
                />
            ) : null}
            <CustomerData.Provider value={loanSanctionedView}>
                {
                viewDetails ? <LoanSanctionModal open={viewDetails} handleClose={handleDetailsClose} /> : ''
                }
            </CustomerData.Provider>
            </div>
        </div>
        </>
    );
};

export default LoanSanctioned;
export { CustomerData }