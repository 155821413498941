import React from 'react'
import { TextField } from '@material-ui/core'

const TextBox = ({name, label, type, onChange, error=null, value, ...other }) => {
  return (
    <>
        <TextField
            variant="outlined"
            name={name}
            label={label}
            onChange={onChange}
            value={value}
            type={type}
            {...other}
            {...(error && { error: true, helperText: error })} />
    </>
  )
}

export default TextBox