import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Select as MuiSelect,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";
import {
  Grid,
  Card,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { editLoan, getLoanDetails, addLoan } from "../../slice/loan";
import { useSnackbar } from "../common/useSnackbar";
import Input from "../controls/Input";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { LOAN_LIST } from "../../utils/url";
import ProgressSpinner from "../common/ProgressSpinner";
import LoadingModal from "./LoadingModal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    width: "130%",
    marginTop: "14px",
  },
  backNext: {
    marginLeft: "17rem",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginLeft: "37%",
    },
    justifyContent: "space-between",
  },
  buttons: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      boxShadow: "none",
    },
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
}));

function Preview({ value, handleBack }) {
  const snackBar = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loanNameError, message, isModalOpen } = useSelector((state, message) => state.loan);
  const { partnerList } = useSelector((state) => state.partner);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  function clear() {
    setErrors("");
    setSuccess("");
  }
  useEffect(() => {
    if (message) {
      snackBar.openSnackbar(message);
      setTimeout(() => {
        navigate(`${LOAN_LIST}`);
      }, [5000]);
    }
  }, [message]);

  const handleSubmit = (event) => {
    if (value.isLinkedToFloatingInterestRates === "true") {
      delete value.interestRateFrequencyType;
      delete value.interestRatePerPeriod;
    } else {
      delete value.defaultDifferentialLendingRate;
      delete value.minDifferentialLendingRate;
      delete value.interestRateDifferential;
      delete value.maxDifferentialLendingRate;
      delete value.floatingRatesId;
      delete value.isFloatingInterestRateCalculationAllowed;
      delete value.isArrearsBasedOnOriginalSchedule;
      delete value.preClosureInterestCalculationStrategy;
      delete value.recalculationCompoundingFrequencyInterval;
      delete value.recalculationRestFrequencyInterval;
    }
    event.preventDefault();
    if (id) {
      dispatch(editLoan(value))
        .then((res) => {
          if (
            res.payload.message === "success" &&
            res.payload.success === true
          ) {
            let message = "Succesfully Edited !!";
            snackBar.openSnackbar(message);
            setTimeout(() => {
              navigate(`${LOAN_LIST}`);
            }, [5000]);
          }
        })
        .catch((error) => { });
    } else {
      dispatch(addLoan(value))
      // .then((res) => {
      //   if (
      //     res.payload.message === "success" &&
      //     res.payload.success === true
      //   ) {
      //     let message = "Succesfully Created !!";
      //     snackBar.openSnackbar(message);
      //     setTimeout(() => {
      //       navigate(`${LOAN_LIST}`);
      //     }, [5000]);
      //   }
      // })
      // .catch((error) => {});
    }
    setTimeout(clear(), 3000);
  };
  useEffect(() => {
    setErrors(loanNameError);
  }, [loanNameError]);

  return (
    <>
      
      <div className={classes.container}>
        <h3>Product Name </h3>
        <div>
          <Typography>{value.name}</Typography>
        </div>
      </div>
      <ToastContainer />
      <div className={classes.container}>
        <h3>Short Name </h3>
        <div>
          <Typography>{value.shortName}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Currency</h3>
        <div>
          <Typography>{value.currencyCode}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Decimal Places</h3>
        <div>
          <Typography>{value.digitsAfterDecimal}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Currency In Multiples Of</h3>
        <div>
          <Typography>{value.inMultiplesOf}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Principal</h3>
        <div>
          <Typography>{value.principal}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Minimum Principal</h3>
        <div>
          <Typography>{value.minPrincipal}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Maximum Principal</h3>
        <div>
          <Typography>{value.maxPrincipal}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Number Of Repayments</h3>
        <div>
          <Typography>{value.numberOfRepayments}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Interest Rate Type</h3>
        <div>
          <Typography>
            {value.isLinkedToFloatingInterestRates === "false"
              ? "Nominal Interest Rate"
              : "Floating Interest Rate"}
          </Typography>
        </div>
      </div>
      {value.isLinkedToFloatingInterestRates === "false" ? (
        <>
          <div className={classes.container}>
            <h3>Nominal Interest Rate</h3>
            <div>
              <Typography>{value.interestRatePerPeriod}</Typography>
            </div>
          </div>
          <div className={classes.container}>
            <h3>Interest Rate Frequency Type</h3>
            <div>
              <Typography>
                {value.interestRateFrequencyType === 2
                  ? "Per Month"
                  : value.interestRateFrequencyType === 3
                    ? "Per Year"
                    : "Whole Term"}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.container}>
            <h3>Floating Interest Rate</h3>
            <div>
              <Typography>{value.defaultDifferentialLendingRate}</Typography>
            </div>
          </div>
          <div className={classes.container}>
            <h3>Minimum Floating Interest Rate</h3>
            <div>
              <Typography>{value.minDifferentialLendingRate}</Typography>
            </div>
          </div>
          <div className={classes.container}>
            <h3>Maximum Floating Interest Rate</h3>
            <div>
              <Typography>{value.maxDifferentialLendingRate}</Typography>
            </div>
          </div>
        </>
      )}
      <div className={classes.container}>
        <h3>Interest Calculation Period Type</h3>
        <div>
          <Typography>
            {value.interestCalculationPeriodType == 0
              ? "Daily"
              : "Same As Repayment"}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Repayment Frequency Type</h3>
        <div>
          <Typography>
            {value.repaymentFrequencyType === 0
              ? "Days"
              : value.repaymentFrequencyType === 1
                ? "Weeks"
                : "Months"}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Multi Disburse Loan</h3>
        <div>
          <Typography>{value.multiDisburseLoan}</Typography>
        </div>
      </div>
      {value.multiDisburseLoan === false ? (
        <>
          <div className={classes.container}>
            <h3>Max Tranche Count</h3>
            <div>
              <Typography>{value.maxTrancheCount}</Typography>
            </div>
          </div>
          <div className={classes.container}>
            <h3>Out Standing Loan Balance</h3>
            <div>
              <Typography>{value.outstandingLoanBalance}</Typography>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className={classes.container}>
        <h3>Amortization Type</h3>
        <div>
          <Typography>
            {value.amortizationType === 1
              ? "Equal Installments"
              : "Equal Principal Payments"}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Interest Type</h3>
        <div>
          <Typography>
            {value.interestType === 0 ? "Declining Balance" : "Flat"}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Days In Year</h3>
        <div>
          <Typography>
            {value.daysInYearType == 1
              ? "Actual"
              : value.daysInYearType == 360
                ? "360 Days"
                : value.daysInYearType == 364
                  ? "364 Days"
                  : "365 Days"}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Days In Month</h3>
        <div>
          <Typography>
            {value.daysInMonthType == 1 ? "Actual" : "30 Days"}
          </Typography>
        </div>
      </div>

      <div>
        <Snackbar {...snackBar} />
      </div>

      {errors ? (
        <Typography style={{ color: "red" }}>{errors.message}</Typography>
      ) : (
        ""
      )}
      {success ? (
        <Typography style={{ color: "blue" }}>{success}</Typography>
      ) : (
        ""
      )}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, width: 750 }}>
        <Button
          className={classes.nextBtn}
          variant="contained"
          color="primary"
          data-testid="nextBtn"
          onClick={handleBack}
        >
          BACK
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Box sx={{ flex: "1 1 auto" }} />
        <Button className={classes.buttons} onClick={handleSubmit}>
          {id ? "Edit Loan" : "Add Loan"}
        </Button>
      </Box>
      {
        isModalOpen ? <LoadingModal open={isModalOpen} /> : ""
      }
    </>
  );
}

export default Preview;
