import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  Container,
  Grid,
  ListItemText,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import styles from "./loanviewstyles";
import useSharedClasses from "../../Sharedfeatures/SharedClasses";
import { useDispatch, useSelector } from "react-redux";
import { getLoanDetails, reset_message } from "../../../slice/loan";
import {
  TXT_VIEW_LOAN,
  TXT_LOAN_PRODUCTS,
  TXT_HOME,
  BACK_BUTTON,
} from "../../../utils/header";
import PageHeader from "../../Sharedfeatures/PageHeader";
import CurrencyView from "./CurrencyView";
import TermsView from "./TermsView";
import SettingsView from "./SettingsView";
import { LOAN_LIST } from "../../../utils/url";
import ChargesView from "./ChargesView";

const LoanView = () => {
  const sharedClasses = useSharedClasses();
  const dispatch = useDispatch();
  const { id } = useParams();
  const myStyles = styles();

  useEffect(() => {
    dispatch(getLoanDetails(id));
    dispatch(reset_message());
  }, []);

  const { loanDetails } = useSelector((state) => state.loan);

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <Paper className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_VIEW_LOAN}
            subheader={`${TXT_HOME}/${TXT_LOAN_PRODUCTS}/${TXT_VIEW_LOAN}`}
            icon={BACK_BUTTON}
            link={LOAN_LIST}
          />

          <section>
            <Container className={sharedClasses.parentContainer}>
              <Grid
                container
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid item xs={10}>
                  <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                    <Grid item md={6}>
                      <ListItemText
                        className={styles.employeeItems}
                        primary={
                          <Typography className={myStyles.listHeading}>
                            Name:
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item md={6}>
                      <ListItemText
                        className={styles.employeeItems}
                        primary={
                          <Typography className={myStyles.listValues}>
                            {loanDetails.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <Grid
                    container
                    spacing={25}
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Grid item md={6}>
                      <ListItemText
                        className={styles.employeeItems}
                        primary={
                          <Typography className={myStyles.listHeading}>
                            Short Name:
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item md={6}>
                      <ListItemText
                        className={styles.employeeItems}
                        primary={
                          <Typography className={myStyles.listValues}>
                            {loanDetails.shortName}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <CurrencyView />
              <TermsView />
              <ChargesView />
              <SettingsView />
            </Container>
          </section>
        </Paper>
      </div>
    </>
  );
};

export default LoanView;
