import React, { useState, useEffect } from "react";
import { TableBody, TableRow, TableCell, Button, TextField, IconButton } from "@material-ui/core";
import useTable from "../Sharedfeatures/UseTable";
import { useDispatch, useSelector } from "react-redux";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import TrancheModal from "../common/TrancheModal";
import HeadCells from "../common/HeadCells";
import { getLoanDisbursedList, getLoanDisbursedExcel, generateExcel, getRepaymentDetails } from "../../slice/loanDisbursed";
import { getLoanTrancheList, reset_message } from "../../slice/loanSanctioned";
import ProgressSpinner from "../common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { TXT_LOAN_DISBURSED, TXT_HOME } from "../../utils/header";
import { SearchOutlined } from "@material-ui/icons";
import PageHeader from "../Sharedfeatures/PageHeader";
import DownloadIcon from '@mui/icons-material/Download';
import useExcel from '../common/useExcel'
import RepaymentModal from "../common/RepaymentModal";
import { DatePicker } from 'antd';
import { useMemo } from "react";
import debounce from "lodash/debounce";

const LoanAvailed = () => {
    //For getting today and yesterday date
    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;


    const [open, setOpen] = useState(false);
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const { disburseList, disburseExcel, totalCount, excelDataSheet, isLoading, repDetails, repLoading } = useSelector((state) => state.loanDisburse);

    const { loanTrancheList, setLoading } = useSelector((state) => state.loanSanction);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(disburseList, HeadCells.loanDisbursedHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    // useEffect(() => {
    //   const dates = {
    //     fromDate,
    //     toDate
    //   }
    //   dispatch(generateExcel(dates));
    // }, [dispatch, fromDate, toDate]);

    const excelData = useExcel()

    useEffect(() => {
        const data = {  
        searchQry: search,
        pageNum: pageNo,
        fromDate,
        toDate,
        totalCount
        };
        dispatch(getLoanDisbursedList(data));
        //dispatch(getLoanDisbursedExcel(data));
        setShowPageNtn(true);
    }, [dispatch, fromDate, toDate, pageNo, search, totalCount]);

    const handleClose = () => {
        setOpen(!open);
        dispatch(reset_message());
    };
    const getTrancheDe = (loanapproval_id) => {
        setOpen(!open);
        let query = "?disbursed=1";
        const data = {
        loanapproval_id: loanapproval_id,
        query: query,
        };
        dispatch(getLoanTrancheList(data));
    };

    const getRepaymentDet = (loanapproval_id) => {
        setOpen(!open);
        let query = "?disbursed=1";
        const data = {
        loanapproval_id: loanapproval_id,
        query: query,
        };
    };

    let serialNo = PAGE_SIZE * slNo;

    //Repayment Details Modal Content Below
    const [openRepay, setOpenRepay] = useState(false)

    const handleRepayOpen = (clientId) => {
        setOpenRepay(true)
        dispatch(getRepaymentDetails(clientId))
    }

    const handleRepayClose = () => {
        setOpenRepay(false)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {  
        searchQry: search,
        pageNum: pageNo,
        fromDate,
        toDate,
        totalCount
        };
        dispatch(getLoanDisbursedExcel(data))
        .then(res=>{
        if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "DT/RT Code" : item.customerid,
                    "Loan A/C No" : item.loanaccountNumber,
                    "Name" : item.name,
                    "Loan Product" : item.loanName,
                    "Tenure" : item.tenure,
                    "Amount" : item.amount_sanctioned,
                    "Interest Rate" : item.interest_rate
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'LoanDisbursedSheet', 'LoanDisbursedList.xlsx')
        }
        })
        .catch(err=>{
        console.error(err);
        })
    }
    return (
        <>
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_LOAN_DISBURSED}
                subheader={`${TXT_HOME}/${TXT_LOAN_DISBURSED}`}
            />
            <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                <TextField
                    size="small"
                    className={sharedClasses.lpsearchTextBar}
                    //value={search}
                    onChange={debouncedResults}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Name"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                    endAdornment: (
                        <IconButton>
                        <SearchOutlined
                            className={sharedClasses.searchIconColour}
                        />
                        </IconButton>
                    ),
                    }}
                />
                </div>

                <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                <RangePicker
                    placeholder={["From Date", "To Date"]}
                    format={dateFormat}
                    className={sharedClasses.datePicker}
                    onChange={onDateChange}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.download}
                    onClick={downloadExcelsheet }
                    endIcon={<DownloadIcon />}
                >
                    Download Ledger
                </Button>
                </div>
            </article>
            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {isLoading ? (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={10} align='center'>
                        <ProgressSpinner />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                    {
                        (disburseList.length !== 0) ? (
                        disburseList &&
                        disburseList.map((item, key) => {
                            serialNo++;
                            return (
                            <TableRow>
                                <TableCell className={sharedClasses.tableCell}>
                                {serialNo}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.customerid}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.loanaccountNumber}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.name}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.loanName}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.tenure}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.amount_sanctioned}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                {item.interest_rate}
                                </TableCell>
                                <TableCell
                                onClick={() => getTrancheDe(item.loanapproval_id)}
                                className={sharedClasses.tableCell}
                                >
                                <Button variant="outlined" color="primary">
                                    Show
                                </Button>
                                </TableCell>
                                <TableCell
                                // onClick={() => getRepaymentDet(item.loanapproval_id)}
                                onClick={() => handleRepayOpen(item.clientId)}
                                className={sharedClasses.tableCell}
                                >
                                <Button variant="outlined" color="primary">
                                    Show
                                </Button>
                                </TableCell>
                            </TableRow>
                            );
                        })
                        ) : (
                        <TableRow>
                            <TableCell colSpan={10} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                        </TableRow>
                        )
                    }
                    </TableBody>
                )}
                </TblContainer>
                {showPageNtn ? <TblPagination /> : ""}
            </section>

            {open ? (
                <TrancheModal
                open={open}
                onClose={handleClose}
                tranche={HeadCells.tranche_list_client}
                trancheData={loanTrancheList}
                loading={setLoading}
                />
            ) : null}

            {
                openRepay ? <RepaymentModal
                open={openRepay}
                handleClose={handleRepayClose}
                headCells={HeadCells.repayment_details_modal}
                data={repDetails}
                loading={repLoading}
                /> : null
            }

            </div>
        </div>
        </>
    );
};

export default LoanAvailed;
