import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import { Box, FormControl, InputAdornment, TextField } from '@material-ui/core';
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { useDispatch } from 'react-redux';
import { useForm } from '../Sharedfeatures/useForm';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LoadingButton from '@mui/lab/LoadingButton';
import { reverseAmount } from '../../slice/virtualAccountSlice';
import CloseIcon from './CloseIcon';
import SuccessIcon from './SuccessIcon';
import FailedIcon from './FailedIcon';

const ReverseModal = ({reverseAmtId,setReverseAmtId,reverseAmt}) => {
    const [reverseCompleted,setReverseCompleted] = useState('')
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const style = {
        position: "absolute",
        top: "50%",
        left: "57%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        maxHeight: "70%",
        overflow: "auto",
        bgcolor: "background.paper",
        borderRadius: "15px",
        boxShadow: 24,
        padding:'22px'
    };
    const sharedClasses = useSharedClasses();
    const NUMERIC_DASH_REGEX = /^[e+-.]+$/;
    const initialFValues = {
        amount: reverseAmt,
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("amount" in fieldValues)
            temp.amount = (fieldValues.amount > 0) ? (Number(fieldValues.amount) > reverseAmt) ? "Amount must be less than actual amount" : '' :  "Amount must be greater than zero"
        setErrors({ ...temp });
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    }
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, true, validate)

    const reverseAmountSubmit = (e)=>{
        e.preventDefault();
        if (validate()) {
            setLoading(true)
            dispatch(reverseAmount({borrower_code :reverseAmtId,amount :values.amount}))
            .then(res=>{
                if(res?.payload !== undefined && res?.payload?.success === true){
                    setReverseCompleted('success')
                }else{
                    setReverseCompleted('failed')
                }
            })
            .catch(err=>{
                console.error();
            })
            console.log('valid fields => ',values);
        }else{
            console.log(' field not valid');
        }
    }
    return (
        <div>
            <Modal open={reverseAmtId ? true : false} onClose={()=>setReverseAmtId('')}>
                <Box sx={style}>
                    {
                        !reverseCompleted ?
                        <div className={sharedClasses.reverseModalWrap}>
                            <div className={sharedClasses.reverseModalTitle}>
                                <span>Balance Amount Reversal</span>
                                <div className={sharedClasses.closeIconStyle} onClick={()=>setReverseAmtId('')}>
                                    <CloseIcon/>                               
                                </div>
                            </div>
                            <form onSubmit={(e)=>reverseAmountSubmit(e)} autoComplete="off" className={sharedClasses.reverseform}>
                                <FormControl className={sharedClasses.formcontrol}>
                                    <p style={{margin:0}}>Amount to be Reversed</p>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <CurrencyRupeeIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                                        name="amount"
                                        value={values.amount}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        type="number"
                                        style={{ width: '250px' }}
                                        {...(errors.amount && { error: true, helperText: errors.amount })}
                                        onKeyDown={(event) => {
                                            if (NUMERIC_DASH_REGEX.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}

                                    />
                                </FormControl>
                                <p>Are you sure you want to reverse this amount ?</p>
                                <div className={sharedClasses.actionBtnsWrap}>
                                    <button type='button' onClick={()=>setReverseAmtId('')} className={sharedClasses.reveseBack}>NO</button>
                                    <LoadingButton
                                        className={sharedClasses.reveseYes}
                                        loading={loading}
                                        loadingPosition="center"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {
                                            !loading ? 'YES' : ''
                                        }
                                    </LoadingButton>
                                </div>
                            </form>
                        </div>
                        :
                        reverseCompleted === 'success' ?
                            <div className={sharedClasses.reverseModalDoneWrap}>
                                <div className={sharedClasses.reverseModalTitle}>
                                    <span>Successfully Reversed</span>
                                    <div className={sharedClasses.closeIconStyle} onClick={()=>setReverseAmtId('')}>
                                        <CloseIcon/>                               
                                    </div>
                                </div>
                                <div className={sharedClasses.reversIcon}>
                                    <SuccessIcon/>
                                </div>
                                <div className={sharedClasses.actionBtnDoneWrap}>
                                    <button type='button' onClick={()=>setReverseAmtId('')} className={sharedClasses.reveseYes}>DONE</button>
                                </div>
                            </div>
                        :
                        reverseCompleted === 'failed' &&
                            <div className={sharedClasses.reverseModalDoneWrap}>
                                <div className={sharedClasses.reverseModalTitle}>
                                    <span>Failed! Sorry Try Again Later</span>
                                    <div className={sharedClasses.closeIconStyle} onClick={()=>setReverseAmtId('')}>
                                    <CloseIcon/>
                                    </div>
                                </div>
                                <div className={sharedClasses.reversIcon}>
                                    <FailedIcon/>
                                </div>
                                <div className={sharedClasses.actionBtnDoneWrap}>
                                    <button type='button' onClick={()=>setReverseAmtId('')} className={sharedClasses.reveseYes}>DONE</button>
                                </div>
                            </div>
                    }
                </Box>
            </Modal>
        </div>
    )
}

export default ReverseModal