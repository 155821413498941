import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    parentContainer: {
        width: '100vw', 
        height: '100vh', 
        backgroundColor: '#1A0045', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    passwordContainer: {
        width: '400px', 
        height: '450px', 
        backgroundColor: '#fff', 
        borderRadius: '10px', 
        padding: '20px 30px'
    },
    overlayContainer: {
        width: '400px', 
        height: '450px', 
        backgroundColor: '#fff', 
        borderRadius: '10px', 
        padding: '20px 30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerPasswordContainer: {
        width: '100%', 
        height: '100%'
    },
    headerParent: {
        width: '100%', 
        // height: '25%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    header: {
        fontFamily: 'Red Rose', 
        fontStyle: 'normal', 
        fontWeight: '700', 
        fontSize: '40px', 
        // lineHeight: '50px', 
        color: '#963460'
    },
    formContainer: {
        width: '100%', 
        height: '75%' 
    },
    formInfoParent: {
        width: '100%', 
        backgroundColor: 'rgb(78 65 217 / 48%)', 
        color: '#fff', 
        padding: '10px 10px', 
        border: '2px solid #4E41D9', 
        borderRadius: '5px'
    },
    textField: {
        width: '100%', 
        margin: '20px 0 !important'
    },
    submitButton: {
        backgroundColor: '#4E41D9 !important', 
        height: '48px', 
        borderRadius: '8px', 
        margin: '20px 0 !important'
    }
})

export default useStyles