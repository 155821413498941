import React, { useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import './FinalDashboard.css'
import GraphHeader from './GraphHeader';
import { GRAPH_THREE_HEADER, GRAPH_THREE_SUBHEADER } from '../../utils/graphHeadings';
import { useDispatch, useSelector } from 'react-redux';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import { getDisburseChart } from "../../slice/finaldashboard";
import useCustomTooltip from "../Sharedfeatures/useCustomTooltip";
import ProgressSpinner from "../common/ProgressSpinner";

const DisbursedChart = ({year}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDisburseChart(year));
  }, [year, dispatch]);

  const { dataFormater } = useYAxisFormatter()
  const { CustomTooltip } = useCustomTooltip();
  const { loanDisbursed, loanDisbursed_totalVolume, disburseLoader } = useSelector((store) => store.finaldashboard)
  const total_Volume = loanDisbursed_totalVolume;

  let data = [];
  loanDisbursed.map((obj) => {
    let cdata = {
      name: obj.month.substring(0, 3),
      value: Number(obj.amount),
      month: obj.month + "" + obj.value,
    };
    data.push(cdata);
  });

  return (
    // <div className='second-row-card'>

    //   <GraphHeader header={GRAPH_THREE_HEADER} subheader={Number(Number(total_Volume).toFixed(0)).toLocaleString()} />
    //   {disburseLoader ? (
    //     <div className="dash_spinner_parent">
    //       <ProgressSpinner />
    //     </div>
    //   ) : (
    //   <ResponsiveContainer width="90%" height="65%">
    //     <BarChart
    //       width={680}
    //       height={180}
    //       data={data}
    //     >
    //       <XAxis dataKey="name" axisLine={true} barSize={10} tick={{ fontSize: 12 }}/>
    //       <YAxis axisLine={false} tickFormatter={dataFormater} />
    //       <Tooltip cursor={false} content={<CustomTooltip />} />
    //       <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
    //       <Bar dataKey="value" fill="#00A4D8" radius={2} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
    //     </BarChart>
    //   </ResponsiveContainer>)}
    // </div>

    <div className='second-row-card'>
      <GraphHeader header={GRAPH_THREE_HEADER} subheader={Number(Number(total_Volume).toFixed(0)).toLocaleString()} />
      {disburseLoader && (
        <div className="dash_spinner_parent">
          <ProgressSpinner />
        </div>
      )}
      <ResponsiveContainer width="90%" height="65%">
        <BarChart
          width={680}
          height={180}
          data={data}
        >
          <XAxis dataKey="name" axisLine={true} barSize={10} tick={{ fontSize: 12 }} />
          <YAxis axisLine={false} tickFormatter={dataFormater} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          {
            !disburseLoader && <Bar dataKey="value" fill="#00A4D8" radius={2} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
          }
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default DisbursedChart