import React, { useEffect } from "react";
import {
  Typography,
  Container,
} from "@material-ui/core";
import useSharedClass from "../../Sharedfeatures/SharedClasses";
import makeStyles from "../LoanStatementStyle";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLoanStatement } from "../../../slice/loanSanctioned";
import { TXT_LOAN_STATEMENT, TXT_HOME, BACK_BUTTON } from "../../../utils/header";
import { LOAN_SANCTIONED } from "../../../utils/url";
import PageHeader from "../../Sharedfeatures/PageHeader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1A0045",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCells = [
  { id: "disbprincipal", label: "Disb. Princi." },
  { id: "paidPrincipal", label: "Paid Princi." },
  { id: "outstanprinci", label: "Outstanding Princi." },
  { id: "overdueprinci", label: "Overdue Princi." },
  { id: "intcharged", label: "Int Charged" },
  { id: "intpaid", label: "Int Paid" },
  { id: "intwaived", label: "Int. Waived" },
  { id: "intoutstanding", label: "Int. Outstanding" },
  { id: "intoverdue", label: "Int Overdue" },
  { id: "torrepay", label: "Total Expected Repayment" },
  { id: "expcost", label: "Total Expected Cost" },
];

const StyledTableCellTwo = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1A0045",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowTwo = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCellsTwo = [
  { id: "officename", label: "Office Name" },
  { id: "amount", label: "Amount" },
  { id: "paymenttype", label: "Payment Type" },
  { id: "netdisbursalamount", label: "Net Disb. Amount" },
  { id: "outstandingloanbalance", label: "O/s Loan Balance" },
  { id: "submissiondate", label: "Submission D/t" },
  { id: "noofrepayments", label: "No: of Repayments" },
];

const headCellsThree = [
  { id: "id", label: "ID" },
  { id: "actualDisbursementDate", label: "Actual Disbursement D/t" },
  { id: "expectedDisbursementDate", label: "Expected Disbursement D/t" },
  { id: "principal", label: "Principal" },
  { id: "netDisbursalAmount", label: "Net Disb. Amount" },
];

const LoanStatement = () => {
  const sharedClasses = useSharedClass();
  const classes = makeStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loanStatement } = useSelector((state) => state.loanSanction);
  useEffect(() => {
    dispatch(getLoanStatement(id));
  }, [dispatch, id]);

  return (
    <>
      <Paper className={sharedClasses.pageContent}>
        <PageHeader
          header={TXT_LOAN_STATEMENT}
          subheader={`${TXT_HOME}/${TXT_LOAN_STATEMENT}`}
          icon={BACK_BUTTON}
          link={LOAN_SANCTIONED}
        />

        <section>
          <Container className={sharedClasses.parentContainer}>
            <Container className={classes.container}>
              <TableContainer className={classes.tableLeft}>
                <TableBody>
                  <TableRow>
                    <TableCell>Name:</TableCell>
                    <TableCell>{loanStatement.clientName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Client Office Id:</TableCell>
                    <TableCell>{loanStatement.clientOfficeId}</TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>

              <TableContainer className={classes.tableRight}>
                <TableBody>
                  <TableRow>
                    <TableCell>Account Number:</TableCell>
                    <TableCell>{loanStatement.clientAccountNo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tranche No:</TableCell>
                    <TableCell>
                      {loanStatement && loanStatement.externalId}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Loan Product Name</TableCell>
                    <TableCell>{loanStatement.loanProductName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Loan Type</TableCell>
                    <TableCell>
                      {loanStatement &&
                        loanStatement.loanType &&
                        loanStatement.loanType.value}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Container>

            <Container>
              <div>

                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.typography}
                >
                  Basic Details
                </Typography>
                <div className={classes.principalHeading}>
                  <div className={classes.princLeft}>
                    <TableContainer>
                      <TableBody>
                        <TableRow>
                          <TableCell> Principal:</TableCell>
                          <TableCell>{loanStatement.principal}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell> Approved Principal:</TableCell>
                          <TableCell>
                            {loanStatement && loanStatement.approvedPrincipal}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Proposed Principal:</TableCell>
                          <TableCell>
                            {loanStatement.proposedPrincipal}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Net Disbursal Amt:</TableCell>
                          <TableCell>
                            {loanStatement.netDisbursalAmount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </div>
                  <div className={classes.princRight}>
                    <TableContainer>
                      <TableBody>
                        <TableRow>
                          <TableCell> Repayment Freq:</TableCell>
                          <TableCell>
                            {" "}
                            {loanStatement.repaymentEvery}{" "}
                            {loanStatement &&
                              loanStatement.repaymentFrequencyType &&
                              loanStatement.repaymentFrequencyType.value}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Interest Rate:</TableCell>
                          <TableCell>
                            {loanStatement.interestRatePerPeriod}%{" "}
                            {loanStatement &&
                              loanStatement.interestRateFrequencyType &&
                              loanStatement.interestRateFrequencyType.value}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Amortization Type:</TableCell>
                          <TableCell>
                            {loanStatement && loanStatement.amortizationType && loanStatement.amortizationType.value}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Interest Type:</TableCell>
                          <TableCell>
                            {loanStatement.isFloatingInterestRate === false ? "Nominal Interest Rate" : "Floating Interest Rate"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </div>
                </div>
                {/* <div className={classes.principalHeading}>
                  <div className={classes.basicleft}>
                    <Typography className={classes.elements}>
                      Principal:
                    </Typography>
                    <Typography className={classes.elements}>
                      Approved Principal:
                    </Typography>
                    <Typography className={classes.elements}>
                      Proposed Principal:
                    </Typography>
                  </div>
                  <div className={classes.basicRight}>
                    <Typography className={classes.elements}>
                      {loanStatement.principal}
                    </Typography>
                    <Typography className={classes.elements}>
                      {loanStatement.approvedPrincipal}
                    </Typography>
                    <Typography className={classes.elements}>
                      {loanStatement.proposedPrincipal}
                    </Typography>
                  </div>
                  <div className={classes.basicleft}>
                    <Typography className={classes.elements}>
                      Net Disbursal Amt:
                    </Typography>
                    <Typography className={classes.elements}>
                      Repayment Freq:
                    </Typography>
                    <Typography className={classes.elements}>
                      Interest Rate:
                    </Typography>
                  </div>
                  <div className={classes.basicRight}>
                    <Typography className={classes.elements}>
                      {loanStatement.netDisbursalAmount}
                    </Typography>
                    <Typography className={classes.elements}>
                      {loanStatement.repaymentEvery}{" "}
                      {loanStatement &&
                        loanStatement.repaymentFrequencyType &&
                        loanStatement.repaymentFrequencyType.value}
                    </Typography>
                    <Typography className={classes.elements}>
                      {loanStatement.interestRatePerPeriod}%{" "}
                      {loanStatement &&
                        loanStatement.interestRateFrequencyType &&
                        loanStatement.interestRateFrequencyType.value}
                    </Typography>
                  </div>
                </div> */}
              </div>
            </Container>

            <Container>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.typography}
              >
                Summary
              </Typography>
            </Container>
            <TableContainer component={Paper} className={classes.table}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {headCells &&
                      headCells.map((item, key) => {
                        return (
                          <StyledTableCell key={key} align="center">
                            {item.label}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.principalDisbursed}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.principalPaid}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.principalOutstanding}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.principalOverdue}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.interestCharged}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.interestPaid}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.interestWaived}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.interestOutstanding}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.interestOverdue}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.totalExpectedRepayment}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {loanStatement &&
                        loanStatement.summary &&
                        loanStatement.summary.totalExpectedCostOfLoan}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Container>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.typography}
              >
                Transactions
              </Typography>
            </Container>
            <TableContainer component={Paper} className={classes.table}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {headCellsTwo &&
                      headCellsTwo.map((item, key) => {
                        return (
                          <StyledTableCellTwo key={key} align="center">
                            {item.label}
                          </StyledTableCellTwo>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loanStatement.transactions &&
                    loanStatement.transactions.map((item, key) => {
                      return (
                        <StyledTableRowTwo key={key}>
                          <StyledTableCellTwo align="center">
                            {item.officeName}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.amount}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item && item.type && item.type.value}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.netDisbursalAmount}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.outstandingLoanBalance}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {moment(item.submittedOnDate).format(
                              "DD MMMM YYYY"
                            )}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.numberOfRepayments}
                          </StyledTableCellTwo>
                        </StyledTableRowTwo>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <Container>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.typography}
              >
                Disbursement Details
              </Typography>
            </Container>
            <TableContainer component={Paper} className={classes.table}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {headCellsThree &&
                      headCellsThree.map((item, key) => {
                        return (
                          <StyledTableCellTwo key={key} align="center">
                            {item.label}
                          </StyledTableCellTwo>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loanStatement.disbursementDetails &&
                    loanStatement.disbursementDetails.map((item, key) => {
                      return (
                        <StyledTableRowTwo key={key}>
                          <StyledTableCellTwo align="center">
                            {item.id}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {moment(item.actualDisbursementDate).format(
                              "DD MMMM YYYY"
                            )}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {moment(item.expectedDisbursementDate).format(
                              "DD MMMM YYYY"
                            )}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.principal}
                          </StyledTableCellTwo>
                          <StyledTableCellTwo align="center">
                            {item.netDisbursalAmount}
                          </StyledTableCellTwo>
                        </StyledTableRowTwo>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Container>
        </section>
      </Paper>
    </>
  );
};

export default LoanStatement;
