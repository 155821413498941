import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";
// import { OfflineShareRounded } from "@mui/icons-material";

export const getPreApprovedLoan = createAsyncThunk(
  "preApprovedList",
  async (data) => {
    const { pageNo, searchQuery } = data
    const url = `/loans/pre-approved-loanoffers?page=${pageNo}&limit=10&searchqry=${searchQuery}`
    const res = await DataService.get(url);
    return res.data;
  }
);

//download preApprovedExcel 
export const getPreApprovedExcel = createAsyncThunk(
  "getpreapprovedexcel",
  async (data) => {
    const { pageNo, searchQuery, totalCount } = data
    const url = searchQuery ? `/loans/pre-approved-loanoffers?page=${pageNo}&limit=10&searchqry=${searchQuery}` : `/loans/pre-approved-loanoffers?page=1&limit=${totalCount}`
    const res = await DataService.get(url);
    return res.data;
  }
);

export const acceptEditedOffer = createAsyncThunk(
  `acceptEditedOffer/id`,
  async (value, { rejectWithValue }) => {
    try {
      const { ...data } = value;
      const url = '/loans/accept-edited-offer'
      const res = await DataService.post(url, data);
      if (res && res.data && res.data.success === true) {
        data.statusId = 5
        data.message = "Edited Offer Accepted";
        data.success = res.data.success;
      }
      return data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const rejectEditedOffer = createAsyncThunk(
  `rejectEditedOffer/id`,
  async (value, { rejectWithValue }) => {
    try {
      const { ...data } = value
      const url = '/loans/reject-edited-offer'
      const res = await DataService.post(url, data);
      if (res && res.data && res.data.success === true) {
        data.statusId = 5
        data.message = "Edited Offer Rejected";
        data.success = res.data.success;
      }
      return data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const editOfferAmount = createAsyncThunk(
  "editAmount",
  async (data, thunkAPI) => {
    try {
      const { loanAppId } = data
      const pass_data = { ...data }
      delete pass_data.loanAppId
      const url = `/loans/edit-loanamount/${loanAppId}`
      const res = await DataService.post(url, pass_data)
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)

export const reset_message = createAsyncThunk(
  'resetMessage',
  async () => {
    return;
  }
)
const initialState = {
  preApprovedList: [],
  preApprovedExcel: [],
  acceptEditedOfferList: [],
  message: "",
  totalCount: "",
  analytics: '',
  isLoading: true,
  edtdAmt: 0,
  edtdLoanAppId: "",
  edtdStatus: false,
  edtdMsg: "",
  success: '',
  errorMessage: '',
};

const preApprovedLoanSlice = createSlice({
  name: "preApprovedList",
  initialState,
  extraReducers: {
    [getPreApprovedLoan.pending]: (state) => {
      state.isLoading = true;
    },
    [getPreApprovedLoan.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.analytics = action.payload.analytics;
      state.message = "";
      state.success = "";
      state.totalCount = action.payload.totalItmes;
      state.preApprovedList = action.payload.data;
    },
    [getPreApprovedLoan.rejected]: (state) => {
      state.isLoading = false;
    },
    [getPreApprovedExcel.fulfilled]: (state, action) => {
      state.preApprovedExcel = action.payload.data;
    },
    [acceptEditedOffer.fulfilled]: (state, action) => {
      state.message = action.payload.message
      const list = state.preApprovedList;
      const index = list.findIndex(item =>
        item.loanapproval_id === action.payload.loanapproval_id
      )
      state.preApprovedList[index].statusId = 5;
    },
    [acceptEditedOffer.rejected]: (state, action) => {
    },
    [rejectEditedOffer.fulfilled]: (state, action) => {
      state.message = action.payload.message
      const list = state.preApprovedList;
      const index = list.findIndex(item =>
        item.loanapproval_id === action.payload.loanapproval_id
      )
      state.preApprovedList[index].statusId = 6;
    },
    [editOfferAmount.fulfilled]: (state, action) => {
      state.edtdStatus = action.payload.success
      state.edtdMsg = action.payload.message
      state.edtdAmt = action.payload.amount
      state.edtdLoanAppId = action.payload.loanapproval_id
      const list = state.preApprovedList;
      const index = list.findIndex(item =>
        item.loanapproval_id === action.payload.loanapproval_id
      )
      state.preApprovedList[index].amount = state.edtdAmt
    },

    [editOfferAmount.rejected]: (state, error) => {
      state.edtdStatus = error.payload.success;
      state.errorMessage = error.payload.message;
    },
    [reset_message.fulfilled]: (state, action) => {
      state.message = '';
      state.preApprovedList = [];
    }
  },
  reducers: {
    resetEdtdOfferStates: (state) => {
      state.edtdStatus = false
      state.edtdMsg = ""
      state.success = ""
      state.message = ""
      state.errorMessage = ""
    }
  }

});

const { reducer } = preApprovedLoanSlice;
export const { resetEdtdOfferStates } = preApprovedLoanSlice.actions

export default reducer;
