import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => createStyles({
    employeeItems: {
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '21px',
        color: '#444445',
        letterSpacing: '0.08em',
        width: '100px',
    },
    listHeading: {
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#444445',
        letterSpacing: '0.08em',
        justifyContent: 'space-between',

    },
    listValues: {
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#444445',
        letterSpacing: '0.08em',
    },
    viewHeading: {
        marginLeft: "25px",
    }
    

}
)
)