import React, { useContext } from 'react'
import {
    Grid,
    FormLabel,
} from '@mui/material';
import { CustomerData } from '../LoanApplication'
import { useSelector } from "react-redux";
import moment from 'moment';

function Official() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={2} style={{ padding: '40px 100px' }}>
                <Grid item xs={4}>
                    <span className='stepper-label'>Company Name:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo && customerData.companyInfo.companyName}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>City:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo && customerData.companyInfo.city}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>PAN:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo && customerData.companyInfo.pan}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Location:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo && customerData.companyInfo.location}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Operating Since: </span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo &&  moment(customerData.companyInfo.operatingSince).format("DD-MM-YYYY") }</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Operating As:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData.companyInfo && customerData.companyInfo.operatingAs}</span>
                </Grid>
            </Grid>
        </>
    )
}

export default Official