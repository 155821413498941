import React from 'react'
import makeStyles from '../../Sharedfeatures/SharedClasses'


const BRModalHeader = () => {
    const classes = makeStyles()
    return (
        <>
            <div className={classes.paymentHistoryHeadbar}>
                <div className={classes.paymentHistoryHeadbarCellOne}>
                    <h3 className={classes.cellOneSubHeader}>
                        Excellent History!
                    </h3>
                    <button className={classes.cellThreeButton}>Medium</button>
                </div>
            </div>
        </>
    )
}

export default BRModalHeader