import React from "react";
import { Typography, Grid, ListItemText } from "@material-ui/core";
import styles from "./loanviewstyles";
import { useSelector } from "react-redux";

const CurrencyView = () => {
  const myStyles = styles();
  const { loanDetails } = useSelector((state) => state.loan);
  return (
    <>
    <Grid className={myStyles.viewHeading}>
    <h1>Currency</h1>
    <hr/>
    </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Currency
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails && loanDetails.currency && loanDetails.currency.name}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={8}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                   Decimal Places:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails && loanDetails.currency && loanDetails.currency.decimalPlaces}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listHeading}>
                    Installment in multiples of:
                  </Typography>
                }
              />
              <ListItemText
                className={styles.employeeItems}
                primary={
                  <Typography className={myStyles.listValues}>
                    {loanDetails && loanDetails.currency && 
                      loanDetails.currency.inMultiplesOf }
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "25px" }}></Grid>
      </Grid>
    </>
  );
};

export default CurrencyView;
