import React from 'react'
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { TXT_LOAN_APPLICATION, TXT_HOME } from "../../utils/header";
import PageHeader from "../Sharedfeatures/PageHeader";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import RejectedApplications from './RejectedApplications';
import LoanApplication from './LoanApplication';
import IncompleteApplications from './IncompleteApplications';

const LoanAppParentTab = () => {

    const sharedClasses = useSharedClasses();

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_LOAN_APPLICATION}
                        subheader={`${TXT_HOME}/${TXT_LOAN_APPLICATION}`}
                    />

                    <section className={sharedClasses.tabMainSectionTwo}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="Loan Applications" value="1" sx={{padding : '12px 16px !important'}}/>
                                <Tab label="Incomplete Applications" value="2" sx={{padding : '12px 16px !important'}}/>
                            </TabList>
                            </Box>
                            <TabPanel value="1" style={{padding:'24px 0', display:'flex', flexDirection:'column', alignItems:"flex-start", gap:'20px'}}>
                                <LoanApplication />
                            </TabPanel>
                            <TabPanel value="2" style={{padding:'24px 0', display:'flex', flexDirection:'column', alignItems:"flex-start", gap:'20px'}}>
                                <IncompleteApplications />
                            </TabPanel>
                        </TabContext>
                    </Box>
                    </section>
                </div>
            </div>
        </>
    )
}

export default LoanAppParentTab