import React from 'react'

const FailedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
            <path d="M137.327 68.871C137.327 106.402 106.902 136.826 69.3715 136.826C31.841 136.826 1.4165 106.402 1.4165 68.871C1.4165 31.3405 31.841 0.916016 69.3715 0.916016C106.902 0.916016 137.327 31.3405 137.327 68.871Z" fill="white" stroke="#EB5757"/>
            <path d="M77.0919 69.0354L92.917 53.2103C93.5023 52.709 93.9778 52.0921 94.3134 51.3983C94.6491 50.7046 94.8377 49.9489 94.8674 49.1788C94.8972 48.4087 94.7674 47.6408 94.4863 46.9232C94.2051 46.2056 93.7787 45.5539 93.2337 45.0089C92.6888 44.464 92.0371 44.0375 91.3195 43.7564C90.6019 43.4753 89.834 43.3455 89.0639 43.3753C88.2937 43.405 87.5381 43.5936 86.8443 43.9293C86.1506 44.2649 85.5337 44.7403 85.0324 45.3257L69.2073 61.1508L53.3822 45.3257C52.3125 44.4096 50.9365 43.9309 49.5291 43.9853C48.1218 44.0396 46.7868 44.623 45.7909 45.6189C44.795 46.6148 44.2116 47.9498 44.1572 49.3572C44.1029 50.7645 44.5816 52.1405 45.4977 53.2103L61.3227 69.0354L45.4977 84.8604C44.4562 85.9081 43.8716 87.3254 43.8716 88.8027C43.8716 90.28 44.4562 91.6973 45.4977 92.745C46.5454 93.7865 47.9627 94.3711 49.44 94.3711C50.9173 94.3711 52.3345 93.7865 53.3822 92.745L69.2073 76.9199L85.0324 92.745C86.0801 93.7865 87.4974 94.3711 88.9747 94.3711C90.452 94.3711 91.8693 93.7865 92.917 92.745C93.9585 91.6973 94.543 90.28 94.543 88.8027C94.543 87.3254 93.9585 85.9081 92.917 84.8604L77.0919 69.0354Z" fill="#EB5757"/>
        </svg>
    )
}

export default FailedIcon