import { Paper } from "@mui/material";
import makeStyles from "./styles"
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoanDetails } from "../../slice/loan";
import { retrievePartnersList } from "../../slice/partners";
import { useForm } from "../Sharedfeatures/useForm";
import NewLoanStepper from "./NewLoanStepper";
import { LOAN_LIST } from "../../utils/url";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { TXT_ADD_LOAN, TXT_HOME, BACK_BUTTON } from "../../utils/header";
import PageHeader from "../Sharedfeatures/PageHeader";


export default function AddLoan() {
  const sharedClasses = useSharedClasses();
  const classes = makeStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loanDetails, success } = useSelector((state) => state.loan);
  const { loanNameError } = useSelector((state, message) => state.loan);
  const { partnerList } = useSelector((state) => state.partner);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm();

  useEffect(() => {
    if (id) {
      dispatch(getLoanDetails(id));
    }
  }, []);

  // useEffect(() => {
  //   dispatch(retrievePartnersList());
  // }, []);  // dont delete it, in future it is required for getting partner details

  useEffect(() => {
    if (id) {
      setValues({
        ...values,
        id: id,
        locale: "en_GB",
        partnerId: process.env.REACT_APP_PARTNER_ID,
        name: loanDetails && loanDetails.name,
        shortName: loanDetails && loanDetails.shortName,
        maxPrincipal: loanDetails && loanDetails.maxPrincipal,
        minPrincipal: loanDetails && loanDetails.minPrincipal,
        principal: loanDetails && loanDetails.principal,
        numberOfRepayments: loanDetails && loanDetails.numberOfRepayments,
        interestRatePerPeriod: loanDetails && loanDetails.interestRatePerPeriod,
        repaymentEvery: loanDetails && loanDetails.repaymentEvery,
        repaymentFrequencyType:
          loanDetails &&
          loanDetails.repaymentFrequencyType &&
          loanDetails.repaymentFrequencyType.id,
        interestRateFrequencyType:
          loanDetails &&
          loanDetails.interestRateFrequencyType &&
          loanDetails.interestRateFrequencyType.id,
        interestType:
          loanDetails &&
          loanDetails.interestType &&
          loanDetails.interestType.id,
        amortizationType:
          loanDetails &&
          loanDetails.amortizationType &&
          loanDetails.amortizationType.id,
        interestCalculationPeriodType:
          loanDetails &&
          loanDetails.interestCalculationPeriodType &&
          loanDetails.interestCalculationPeriodType.id,
        numberOfRepayments: loanDetails && loanDetails.numberOfRepayments,
      });
    }
  }, [loanDetails]);
  
  return (
    <div className={sharedClasses.mainDiv}>
      <div className={sharedClasses.pageContent}>
        <PageHeader
          header={TXT_ADD_LOAN}
          subheader={`${TXT_HOME}/${TXT_ADD_LOAN}`}
          icon={BACK_BUTTON}
          link={LOAN_LIST}
        />
        <section className={sharedClasses.mainSection}>
          <Paper className={classes.addLoanPaper} style={{marginLeft: 'auto', marginRight: 'auto'}}>
            <NewLoanStepper />
          </Paper>
        </section>
      </div>
    </div>
    // </div>
  );
}
