import React, { useEffect } from "react";
import {
    Paper,
    Grid,
    Card,
    Avatar,
} from '@mui/material';
import { Link } from 'react-router-dom'
import Logo from "../../nbfclogo.svg";
import icon from '../../images/Rectangle 212.png'
import useSharedClasses from '../Sharedfeatures/SharedClasses';
import { useDispatch, useSelector } from "react-redux";
import { retrievePartnersList } from "../../slice/partners";
import './partnersstyle.css';

const PartnersList = () => {
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch();
    const { partnerList } = useSelector((state) => state.partner);
    const { message, success } = useSelector((state) => state.partner);

    useEffect(() => {
        dispatch(retrievePartnersList())
    }, [dispatch])

    return (
        <>
            <div id="partnerSection">
                <div id="sidebar">
                    <Avatar id="avatar" sx={{   
                        width: '100px !important',
                        height: '110px !important', bgcolor: '#FFFFFF'
                    }}>
                        <div >
                            <img
                                id="img"
                                src={Logo}
                                alt="logo"
                            />
                        </div>
                    </Avatar>
                    <p id="atlas">
                        ATLAS
                    </p>
                    <p id="adminLabel">ADMIN</p>
                    <p id="partnerLabel">
                        <div id="partnerLabelWave">
                        PARTNERS
                        </div>
                        </p>
                </div>
                <Card id="card">
                    <Grid container spacing={6} id="grid">
                        {partnerList && partnerList.map((item, key) => (
                            <Grid item xs={6} sm={4} md={4} key={key}>
                                <Link to="/admin/dashboard" className={sharedClasses.partnerButton}>
                                    <img
                                        src={icon}
                                        alt="icon"
                                    />
                                    {item.name}</Link>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </div>
        </>
    )
}

export default PartnersList

