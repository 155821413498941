import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import GraphHeader from './GraphHeader'
import { GRAPH_SEVEN_HEADER } from '../../utils/graphHeadings';
import './FinalDashboard.css'
import ProgressSpinner from '../common/ProgressSpinner';
import { getTrancheChartData } from '../../slice/finaldashboard';

const TrancheChart = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTrancheChartData())
    }, [])

    const { tranche_request_count, tranche_request_volume, tranche_req_loader } = useSelector((store) => store.finaldashboard)

    return (
        <div className='second-row-card'>
            <GraphHeader header={GRAPH_SEVEN_HEADER} 
            subheader={tranche_request_volume} />
            {
                tranche_req_loader ? (
                    <div className="dash_spinner_parent">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <>
                        <article className="tranche-requests-day">
                            <h3>Today</h3>
                        </article >
                        <div className='circle'>
                            <div className="circle-text">{tranche_request_count} Request</div>
                        </div>
                    </>
                )
            }
        </div >
    )
}

export default TrancheChart