import dayjs from "dayjs";

const disableUpcomingDates = (current)=>{
    return current && current > dayjs().endOf('day');
}
const disableDates = (current)=>{
    return current && current <= dayjs().endOf('day').subtract(1, 'day');
}

export {
    disableDates,
    disableUpcomingDates
}