import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import './FinalDashboard.css'
import MonthWise from './MonthWise';
import Today from './Today';
import YearWise from './YearWise';
import { getLoanDisbursedRegionData, getRegionsList } from '../../slice/finaldashboard';
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '../Sharedfeatures/useForm'

const RegionGraphs = ({ year }) => {

    const dispatch = useDispatch()

    const initialValues = {
        region: ''
    }
    const { values, setValues, handleRegionChange } = useForm(initialValues, true);

    const { loan_disbursed_monthly, loan_disbursed_yearly, loan_disbursed_today, regionsList, disb_region_loader } = useSelector((store) => store.finaldashboard);

    // const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        dispatch(getRegionsList())
            .then((resp) => {
                let sel_reg_id = resp.payload.data[0]._id
                setValues({ ...values, region: sel_reg_id })
            })
            .then(() => {
                if (values.region && year) {
                    const data = {
                        region: values.region,
                        year
                    }
                    dispatch(getLoanDisbursedRegionData(data))
                }
            })
    }, [])

    useEffect(() => {
        if (values.region && year) {
            const data = {
                region: values.region,
                year
            }
            dispatch(getLoanDisbursedRegionData(data))
        }
    }, [dispatch, year, values])



    // const handleYearChange = (e) => {
    //     setYear(e.target.value)
    // }

    let curYear = new Date().getFullYear()
    let years = []
    for (let i = curYear; i >= 2022; i--) {
        years.push(i)
    }

    return (
        <div className='region-graphs-card'>

            <div style={{ width: '100%', textAlign: 'center' }} className='g-one-header'>
                <h2>
                    Loan Disbursed - Region Wise
                </h2>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '33.33%', display: 'flex', justifyContent: 'center' }}>
                    <select id='year' onChange={handleRegionChange} name="region" value={values.region}>
                        {
                            regionsList && regionsList.map((item) => {
                                return <option key={item._id} value={item._id}>{item.name}</option>
                            })
                        }
                    </select>
                    {/* <select id='year' onChange={handleYearChange} value={year}>
                        {
                            years.map(item => <option key={item} value={item}>{item}</option>)
                        }
                    </select> */}
                </div>
            </div>

            <Grid container className='grid-gap-two'>
                <Grid item sm={12} md={6} lg={3}>
                    <YearWise yearlyData={loan_disbursed_yearly} year={year} loader={disb_region_loader} />
                </Grid>

                <Grid item sm={12} md={6} lg={6}>
                    <MonthWise monthlyData={loan_disbursed_monthly} loader={disb_region_loader} />
                </Grid>

                <Grid item sm={12} md={6} lg={3}>
                    <Today todayData={loan_disbursed_today} loader={disb_region_loader} />
                </Grid>
            </Grid>
        </div>
    )
}

export default RegionGraphs