import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getbusinesstrend = createAsyncThunk('getbusinesstrend', async (data)=>{
    try{
        const url = `/console/business-trend/${data}`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    isLoading : true,
    businessTrendData : '' 
}

const welcomeSlice = createSlice({
    name : 'welcomeslice',
    initialState,
    extraReducers:{
        [getbusinesstrend.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getbusinesstrend.fulfilled]:(state,{payload})=>{
            return {...state, businessTrendData:payload?.data?.data, isLoading:false}
        },
    }
})
export const getAllDashboardData = (state) => state.dashboardwelcomdata;
export default welcomeSlice.reducer