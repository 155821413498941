import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanClosedList = createAsyncThunk(
  "loanClosedList",
  async ({ pageNum, searchQry, fromDate, toDate }) => {
    const url = `/loans/closed-loans-list?page=${pageNum}&limit=10&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

// Download Loan closed Excel
export const getLoanClosedExcel = createAsyncThunk(
  "closedExcel",
  async ({ searchQry, fromDate, toDate, totalCount }) => {
    const url = `/loans/closed-loans-list?page=1&limit=${totalCount}&searchqry=${searchQry}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

// export const generateExcel = createAsyncThunk(
//   "generateExcel",
//   async ({ fromDate, toDate }) => {
//     const url = `excel/closedloanslist?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data.data;
//   }
// );

const initialState = {
  loanClosedList: [],
  loanClosedExcel:[],
  setLoading: true,
  totalCount: 0,
  excelDataSheet: []
};

const loanClosedSlice = createSlice({
  name: "loanClosed",
  initialState,
  extraReducers: {
    [getLoanClosedList.pending]: (state) => {
      state.setLoading = true;
    },
    [getLoanClosedList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.totalItmes;
      state.loanClosedList = action.payload.data;
      state.setLoading = false;
    },
    [getLoanClosedList.rejected]: (state) => {
      state.setLoading = false;
    },
    [getLoanClosedExcel.fulfilled]: (state, action) => {
      state.loanClosedExcel = action.payload.data;
    },
    // [generateExcel.fulfilled]: (state, action) => {
    //   state.excelDataSheet = action.payload.data
    // },
  },
});

const { reducer } = loanClosedSlice;
export default reducer;