import { Pie, measureTextWidth } from '@ant-design/charts';
import React, { useEffect, useState } from 'react'
import emptypiechart from '../../../assets/images/empty-pie-chart.svg'
const ApplicationStatusChart = ({chartCardData,chartData}) => {
    const [nullData,setNullData] = useState([])
    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
        let scale = 1;
        if (containerWidth < textWidth) {
          scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
        const textStyleStr = `width:100%; padding:10px`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }
    const config = {
        appendPadding: 10,
        data: chartData,
        angleField: 'value',
        colorField: 'title',
        color: ['#CADEFC','#CCA8E9', '#9DDCDC'],
        legend: false,
        radius: 1,
        innerRadius: 0.64,
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
            textAlign: 'center',
            fill:'#000'
            },
            autoRotate: false,
            content: '{value}%',
        },
        statistic: {
            title: {
            offsetY: -4,
            style: {
                fontSize: '13px',
                textAlign:'center',
            },
            customHtml: (container, view, datum) => {
                const { width, height } = container.getBoundingClientRect();
                const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                const text = datum ? datum.title : 'Total';
                return renderStatistic(d, text, {
                fontSize: 10,
                });
            },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '30px',
                },
                customHtml: (container, view, datum, data) => {
                    const { width } = container.getBoundingClientRect();
                    const text = datum ? `${datum.value}%` : `${data.reduce((r, d) => r + d.value, 0)}%`;
                    return renderStatistic(width, text, {
                    fontSize: 10,
                    });
                },
            },
        },
        onReady: (plot) => {
            plot.chart.removeInteraction('legend-filter');
        },
    };
    useEffect(()=>{
        let result = chartData.map(a => a.value);
        setNullData(result)
    },[chartData])
    return (
        <div className="displayflex application-chart-wrap">
            <div className="card-container">
                <div className="card approved displayflexcol">
                    <div className="card-title">Total Applications Approved</div>
                    <div className="card-value">{chartCardData?.application_approved}</div>
                </div>
                <div className="card rejected displayflexcol">
                    <div className="card-title">Total Applications Rejected</div>
                    <div className="card-value">{chartCardData?.application_rejected}</div>
                </div>
                <div className="card pending displayflexcol">
                    <div className="card-title">Total Applications Pending</div>
                    <div className="card-value">{chartCardData?.application_pending}</div>
                </div>
            </div>
            <div className="application-chart">
                {
                    (nullData[0] === 0 && nullData[1] === 0 && nullData[2] === 0) ?
                        <div className="empty-pie">
                            <img src={emptypiechart} alt="Empty Pie" />
                            <div className="statistic-wrap">
                                <p className="headtitle">Total</p>
                                <p className="st-value">0%</p>
                            </div>
                        </div>
                    :
                        <Pie {...config} />
                }
                <div className="legend-wraper displayflexcol">
                    <div className="legends leg-approved">
                        <div className="leg-square"></div>
                        <div className="leg-label">Total Applications Approved</div>
                    </div>
                    <div className="legends leg-rejected">
                        <div className="leg-square"></div>
                        <div className="leg-label">Total Applications Rejected</div>
                    </div>
                    <div className="legends leg-pending">
                        <div className="leg-square"></div>
                        <div className="leg-label">Total Applications Pending</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationStatusChart