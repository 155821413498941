import React from 'react'
import './applicationstatus.css'
import ApplicationStatusCards from './ApplicationStatusCards'
import ApplicationStatusChart from './ApplicationStatusChart'
import { getApplicationStatus, getApplicationStatusData } from '../../../slice/DashboardSlice/applicationStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Skeleton } from 'antd'
import moment from 'moment';

const ApplicationStatus = () => {
    const {isLoading, applicationStatusData} = useSelector(getApplicationStatusData)
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!applicationStatusData){
            dispatch(getApplicationStatus())
        }
    },[applicationStatusData])
    return (
        <div className="displayflexcol application-status-section">
            <div className="displayflex">
                <h2 className="section-title">Today's Application Status</h2>
                <h2 className="section-title">{moment(new Date()).format("DD-MM-YYYY")}</h2>
            </div>
            <div className="card-wrap application-status-card">
                {
                    isLoading || !applicationStatusData ? 
                    <Skeleton active/>
                    :
                    <ApplicationStatusCards cardData={applicationStatusData?.card_data}/>
                }
            </div>
            <div className="card-wrap application-status-chart">
                {
                    isLoading || !applicationStatusData ? 
                    <Skeleton active/>
                    :
                    <ApplicationStatusChart chartCardData={applicationStatusData?.chart_card} chartData
                    ={applicationStatusData?.chart_data_web}/>
                }
            </div>
        </div>
    )
}

export default ApplicationStatus