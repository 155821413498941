import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { CustomerData } from './LoanSanctioned'
import moment from 'moment';

const Official = () => {

  const loanSanctionedView = useContext(CustomerData)

  // const jsonString = applicationDetails.companyInfo.location;
  // const jsonObject = JSON.parse(jsonString);
  // console.log(jsonObject);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Company Name:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.companyName}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            City:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.city}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Location:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.location}
            {/* {jsonObject.location_name} */}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            PAN:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.pan}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Operating Since:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {moment(loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.operatingSince).format('DD MMMM YYYY')}
            {/* {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.operatingSince} */}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Operating As:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.companyInfo && loanSanctionedView.companyInfo.operatingAs}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Official