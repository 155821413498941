import React from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import SanctionDetailsStepper from './SanctionDetailsStepper';
import useStyles from './LoanAppStyles'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  p: 3
};

const LoanSanctionModal = ({ open, handleClose }) => {
  
  const classes = useStyles()

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>

          <Box className={classes.loanAppDetailParent}>
            <IconButton onClick={handleClose}>
              <AiOutlineClose />
            </IconButton>
          </Box>

          <Typography
            variant="h5"
            color={'#963460'}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            Loan Sanctioned Details
          </Typography>

          <SanctionDetailsStepper handleClose={handleClose} />

        </Box>
      </Modal>
    </>
  )
}

export default LoanSanctionModal