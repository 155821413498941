import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Grid,
  Modal,
  Box,
} from "@material-ui/core";
import Typography from "../../controls/Typography";
import Personal from "./Personal";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  nbfcApprove,
  createLoan,
  nbfcReject,
} from "../../../slice/loanapplication";
import { getLoanDetails } from "../../../slice/loan";
import CloseIcon from "@mui/icons-material/Close";
import { CustomerData } from "../LoanApplication";
import Official from "./Official";
import Bank from "./Bank";
import Documents from "./Documents";
import CreditModal from "./CreditModal"
import NbfcConfDialog from "../../common/NbfcConfDialog";
import LoadingModal from "../../Loans/LoadingModal";
import ErrorIcon from '@mui/icons-material/Error';
import { red } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  pendingmodal:{
    padding: '30px'
  },
  pendingtitle:{
    display:'flex',
    alignItems : 'center',
    gap:'10px',
    color:'#ff0000'
  },
  btnWrap:{
    width:'100%',
    display:'flex',
    justifyContent:'end'
  }
}));

const ReviewLoanAppli = (props) => {
  const classes = useStyles();
  const customerData = useContext(CustomerData);
  const { applicDetails } = useSelector((state) => state.loanApplication);

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [resourceId, setResourceId] = useState();
  const [verificationPendingDoc, setVerificationPendingDoc] = useState(false);
  const steps = getSteps();

  useEffect(() => {
    setResourceId(
      applicDetails &&
      applicDetails.loanapproval &&
      applicDetails.loanapproval.resourceId
    );
  }, [dispatch, applicDetails]);

  function getSteps() {
    return ["Personal", "Official", "Bank", "Credit Reports", "Documents"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Personal />;
      case 1:
        return <Official />;
      case 2:
        return <Bank />;
      case 3:
        return <CreditModal />;
      case 4:
        return <Documents verificationPendingDoc={verificationPendingDoc}/>;
      default:
        return "unknown step";
    }
  }

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    setVerificationPendingDoc(false)
    setActiveStep(activeStep - 1);
  };

  // handleFinish approves the loan apllication and goes to the loan creation
  const handleFinish = () => {
    if (customerData.id) {
      const data = {
        loanapproval_id:
          customerData &&
          customerData.loanapproval &&
          customerData.loanapproval._id,
      };
      dispatch(nbfcApprove(data))
      props.data.handleClose();
    }
  };
  const handleReject = () => {
    const data = {
      loanapproval_id:
        customerData &&
        customerData.loanapproval &&
        customerData.loanapproval._id,
    };
    dispatch(nbfcReject(data));
    props.data.handleClose();
  };

  const verificationPending = () => {
    console.log('modal open');
    setVerificationPendingDoc(true)
  };

  //nbfc reject modal contents below
  const [openRejModal, setOpenRejModal] = useState(false)

  const nbfcRejMsg = 'You are about to reject this entire loan application. If you reject this, the entire application would be deleted. This action cannot be undone. Are you sure you want to reject this application?';

  const handleNbfcRejOpen = () => {
    setOpenRejModal(true)
  }

  const handleNbfcRejClose = () => {
    setOpenRejModal(false)
  }

  //nbfc approve modal contents below
  const [openAppModal, setOpenAppModal] = useState(false)

  const nbfcAppMsg = 'Are you sure you want to approve this application?'

  const handleNbfcAppOpen = () => {
    setVerificationPendingDoc(false)
    setOpenAppModal(true)
  }

  const handleNbfcAppClose = () => {
    setOpenAppModal(false)
  }

  const handleNbfcApprove = () => {
    handleFinish()
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '20px'
  };
  return (
    <>
      <IconButton style={{ marginLeft: "920px" }} onClick={props.data.handleClose}>
        <CloseIcon />
      </IconButton>

      <Typography
        text={"Review Loan Application"}
        className="stepperHeading"
      ></Typography>
      {/* <Typography text={"Employee ID"} className="stepSubHeading"></Typography> */}
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <form>{getStepContent(activeStep)}</form>
          <Grid container spacing={2} style={{ marginLeft: "94px", paddingTop: "auto" }}>
            <Grid item xs={8}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                back
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={
                  activeStep === steps.length - 1
                    ? applicDetails &&
                      applicDetails.documets &&
                      applicDetails.documets.all_docs_verified === false
                      ? verificationPending
                      : handleNbfcAppOpen
                    : handleNext
                }
              >
                {activeStep === steps.length - 1
                  ? applicDetails &&
                    applicDetails.documets &&
                    applicDetails.documets.all_docs_verified === false
                    ? "NBFC Approve"
                    : "NBFC Approve"
                  : "Next"}
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNbfcRejOpen}
                >
                  NBFC Reject
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {
            verificationPendingDoc &&
            <Modal open={verificationPendingDoc}>
              <Box sx={style}>
                <div className={classes.pendingmodal}>
                  <p className={classes.pendingtitle}>
                    <ErrorIcon sx={{ color: red[500] }}/><span>Verification Pending</span>
                  </p>
                  <div className={classes.btnWrap}>
                    <Button color="primary" variant="contained" onClick={()=>setVerificationPendingDoc(false)}>OK</Button>
                  </div>
                </div>
              </Box>
            </Modal>
          }
        </>
      )}
      {
        openRejModal ? <NbfcConfDialog open={openRejModal} handleClose={handleNbfcRejClose} handleAction={handleReject} msg={nbfcRejMsg} /> : null
      }
      {
        openAppModal ? <NbfcConfDialog open={openAppModal} handleClose={handleNbfcAppClose} handleAction={handleNbfcApprove} msg={nbfcAppMsg} /> : null
      }
    </>
  );
};

export default ReviewLoanAppli;
