import { IoIosArrowBack } from 'react-icons/io'

export const TXT_LOAN_PRODUCTS = 'Loan Products';
export const TXT_LOAN_PRODUCT = 'Loan Product';
export const TXT_ADD_LOAN = 'Add New Loan';
export const TXT_CHARGES = 'Overdue Charges';
export const TXT_EDIT_CHARGES = 'Edit Charge';
export const TXT_ADD_CHARGES = 'Create Charge';
export const TXT_CHARGE = 'Charges';
export const TXT_CHARGE_DETAILS = 'Charge Details';
export const TXT_PRE_APPROVED = 'Pre Approved Loan Offers';
export const TXT_LOAN_APPLICATION = 'Loan Applications';
export const TXT_VIEW_LOAN = 'Loan Details';
export const TXT_LOAN_SANCTIONED = 'Loans Sanctioned';
export const TXT_LOAN_ACCOUNTDET = 'Loan Account Details';
export const TXT_LOAN_CREDITREPORTS = 'Credit Reports';
export const TXT_LOAN_DISBURSED = 'Loans Disbursed';
export const TXT_LOAN_STATEMENT = 'Loan Statement';
export const TXT_TRANCHE_DISBURSED = 'Tranche Disbursed';
export const TXT_HOME = 'Home';
export const TXT_REPAYMENT = 'Latest Repayments';
export const TXT_LOAN_GENERATED = 'Loans Generated';
export const TXT_BORROWER = 'Borrowers';
export const TXT_VIRTUAL_ACCOUNT = 'Virtual Acc. Balance';
export const DASHBOARD = 'Dashboard'
export const TRANCHE_REQUEST = 'Tranche Requests';
export const CREDIT_REPORT = 'Credit Bureau Report';
export const ECAPS_REPORT = 'ECAPS Business Report';
export const TXT_LOAN_CLOSED = 'Loan Closed';
export const TXT_LOAN_DEFAULTED = 'Loan Defaulted';
export const TXT_UPCOMING_REPAYMENTS = 'Upcoming Repayments';
export const TRANCHES = 'Tranches Created';
export const TXT_INPROGRESS_REPAYMENTS = 'In Progress Repayments';
export const BACK_BUTTON = <IoIosArrowBack color='#963460' />