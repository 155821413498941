import React from 'react'
import './loanclosures.css'
import LoanClosuresDataCard from './LoanClosuresDataCard'
import LoanClosuresChart from './LoanClosuresChart'
import { getloanClosures, getloanClosuresData } from '../../../../slice/DashboardSlice/loanClosuresSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getcommonapidata } from '../../../../slice/commonslice'
import { useEffect } from 'react'
import { Skeleton } from 'antd'
import { useState } from 'react'
const month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
const date = new Date()

const LoanClosures = () => {
    const [value, setValue] = useState(month[date.getMonth()]);

    const {isLoading, loanClosuresData} = useSelector(getloanClosuresData)
    const {commonFilterStates} = useSelector(getcommonapidata)
    const dispatch = useDispatch();
    const postValue = {
        state:commonFilterStates,
        month: `${value === 'Select Month' ? '' : value}`
    }
    useEffect(()=>{
        if(!loanClosuresData){
            dispatch(getloanClosures(postValue))
        }
    },[loanClosuresData])
    useEffect(()=>{
        dispatch(getloanClosures(postValue))
    },[commonFilterStates,value])
    return (
        <div className="card-wrap displayflexcol loanclosures-card">
            <h3 className="chart-card-title">Loan Closures</h3>
            {
                isLoading || !loanClosuresData ?
                <Skeleton active/>
                :
                <LoanClosuresDataCard cardData={loanClosuresData?.cardData} value={value} setValue={setValue}/>
            }
            {
                isLoading || !loanClosuresData ?
                <Skeleton active/>
                :
                <LoanClosuresChart chartData={loanClosuresData?.data}/>
            }
        </div>
    )
}

export default LoanClosures