import React from 'react'
import { BiNotepad } from 'react-icons/bi'
import { BsArrowRightCircle } from 'react-icons/bs'

const BusinessStats = ({ title, value, impact, impactStatus, onClick, startIcon }) => {
    return (
        <>
            <div className="eq-parent-container">
                <div className="eq-left-cell">{startIcon}</div>
                <div className="eq-middle-cell">
                    <div className="eq-middle-cell-left-container">
                        <h3>{title}</h3>
                        <h4>{impact}</h4>
                    </div>
                    <div className="eq-middle-cell-right-container">
                        <h3>{value}</h3>
                        <h4>{typeof(impactStatus)  === 'number' ? impactStatus.toFixed(2) : impactStatus }</h4>
                    </div>
                </div>
                <div className="eq-right-cell"><BsArrowRightCircle className="cell-start-end-icon" onClick={onClick} /></div>
            </div>
            <hr style={{ color: '#DEDEDE', border: '0.5px solid #DEDEDE' }} />
        </>
    )
}

export default BusinessStats