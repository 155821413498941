import React from 'react'
import makeStyles from '../../../../Sharedfeatures/SharedClasses'
import { AiFillCaretRight } from 'react-icons/ai';

const PaymentHistoryHeader = () => {
    const classes = makeStyles()
    return (
        <>
            <div className={classes.paymentHistoryHeadbar}>

                <div className={classes.paymentHistoryHeadbarCellOne}>
                    <h4 className={classes.cellOneHeader}>
                        Excellent Payment History !
                    </h4>
                    <h3 className={classes.cellOneSubHeader}>
                        100% Payments On Time
                    </h3>
                </div>

                <div className={classes.paymentHistoryHeadbarCellTwo}>
                    <h3 className={classes.cellOneSubHeader}>0 Late Payments</h3>
                </div>

                <div className={classes.paymentHistoryHeadbarCellThree}>
                    <button className={classes.cellThreeButton}>High Impact</button>
                    <h5 className={classes.cellThreeHeader}>Know more about payment history &nbsp;<AiFillCaretRight /></h5>
                </div>

            </div>
        </>
    )
}

export default PaymentHistoryHeader